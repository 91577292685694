import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';

import { RecaptchaVerifier,signInWithPhoneNumber,signInWithRedirect,GoogleAuthProvider,getRedirectResult    } from "firebase/auth";
import { inject } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-logon-home',
  templateUrl: './logon-home.component.html',
  styleUrls: ['./logon-home.component.css']
})
export class LogonHomeComponent implements OnInit {


  constructor(private authService :AuthService, private router :Router) { }

 captcha :any
  ngOnInit(): void {
    
   
    
     

    
  }
  logon() {
    let phoneNumber = $("#phone-number").val()?.toString()
    //this.authService.logonThroughPhoneNumber(phoneNumber)
 
  
    }
    signUpWithGoogle(){
    
    }
    GoogleResponse(){
      console.log("authenticated")
    }

}
