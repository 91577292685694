import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer, UrlTree } from '@angular/router';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig } from 'ngx-scanner-qrcode';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-camera-scan',
  templateUrl: './camera-scan.component.html',
  styleUrls: ['./camera-scan.component.css']
})

export class CameraScanComponent implements OnInit, AfterViewInit, OnDestroy {
  siteBaseUrl:string
  message:string
  flashLishtOn:boolean
  @ViewChild('action',{static:true}) action:NgxScannerQrcodeComponent
  constructor(private route:ActivatedRoute, private router:Router){
    this.siteBaseUrl=location.origin 

  }
  ngOnDestroy(): void {

    this.action.stop()
  }
  ngAfterViewInit(): void {

    this.action.isReady.subscribe((res: any) => {
       this.handle(this.action, 'start');
    });
        
  }
  ngOnInit(): void {
 
  }
  onScan(event:any){
    this.message='scanning! Please wait'
    this.action.pause()
    if(event){
       var data:string = event[0].value
      if(data.startsWith(this.siteBaseUrl)){
          window.location.href=data
          return
      }
      
    }
    this.action.play()
    this.message='Invalid code, try again'
  }
  device(event:any){
    console.log(event)
  }
  public handle(action: any, fn: string): void {
    // Fix issue #27, #29
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
      ;
      ;
    }
  }
}
