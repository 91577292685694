import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar'
import { SnackbarService } from "./snackbar.service";
import { AuthService } from "./Services/auth.service";
import { UserStateService } from "./_common/services/user-state.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
private snackBar : MatSnackBar,
private zone : NgZone,
private notificationService : SnackbarService,
private userState:UserStateService
  ) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
//     if(error.status==401){
// this.userState.SignOut()
// return;
//     }
    console.log(error)
    if ((error instanceof HttpErrorResponse)) {
      error = error.error; // get the error object
    }
    else if(error.rejection !=undefined){
      error=error.rejection
    }
    if(typeof error !=='object' ){
    this.zone.run(()=>this.notificationService.CreateNotification(error))
    }
    else{
      this.zone.run(()=>this.notificationService.CreateNotification('Something went wrong.'))
    }
  //   this.zone.run(()=>this.snackBar.open(error,"X",{duration:2000}).onAction().subscribe(()=>{
  //     this.snackBar.dismiss()
  // }))  
  }  
}