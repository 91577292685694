import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CatalogService } from 'src/app/Services/catalog.service';

@Component({
  selector: 'app-create-catalog',
  templateUrl: './create-catalog.component.html',
  styleUrl: './create-catalog.component.css'
})
export class CreateCatalogComponent implements OnInit{
  fgCatalog:FormGroup
  constructor(private formBuilder:FormBuilder, private catalogService:CatalogService, private ref :DynamicDialogRef){
    
  }
  ngOnInit(): void {
    this.fgCatalog=this.formBuilder.group({
      name:['',Validators.required],
      genre:[''],
      tags:[[]]
    })
  }
  createCatalog(){
    if(this.fgCatalog.valid){
      let data = this.fgCatalog.value;
      this.catalogService.CreateCatalog(data).subscribe((response:any)=>{
          this.ref.close(response)
      })
    }
  }
  onUpload(event:any){

  }

}
