import { Injectable, NgZone } from '@angular/core';
import { ZERO_LENGTH } from 'html2canvas/dist/types/css/types/length-percentage';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loading: boolean = false;
  constructor(private zone:NgZone) { }
  setLoading(loading: boolean) {
    this.zone.run(()=>{
      this.loading = loading;
    })

  }

   public get getLoading(): boolean {
    
    return this.loading;
  }
}
