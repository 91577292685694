import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class QrService {
  qrDetails : any
  qrBitMap :any
  constructor(private http : HttpService, private domSanitizer : DomSanitizer) { }
  CreateQRCode(uniqueId:string){
    var data= {uniqueId : uniqueId}
    uniqueId = JSON.stringify(uniqueId)
    let headers = new HttpHeaders();
    headers= headers.append('content-type', 'application/json');
    return new Promise((resolve,reject)=>{
      this.http.Post('@api-dev/QR/CreateQR/',uniqueId,headers,true).subscribe((result)=>{
        this.updateQrDetails(result)
        resolve(result)
      },(error)=>{
        reject(error)
      });
    })
    
  }
  getQRCode(){
    return new Promise((resolve,reject)=>{
       this.http.Get('@api-dev/QR/',true).subscribe((data: any)=>{
        if(data!=null){
        this.updateQrDetails(data)
        }
        resolve(data)
  
      },(error)=>{
        reject(error)
      })
    })
    
  }
  private updateQrDetails(data : any){
    this.qrDetails= data;
    let objectURL = 'data:image/jpeg;base64,' + data.qrCode;
    this.qrBitMap = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
    $("#uniqueId").val(this.qrDetails.performerName)
    
  }
  getPerformerNameForRedirection(id:string|null,mode:string|null){
    return this.http.Get(`@api-dev/QR/GetNameFromId/${id}?mode=${mode}`,false)
  }
  }


