<div class=" jukrContainer">


 
    <div class="m-auto p-3">
             <div class="card  jukr-card logon-card m-auto">
                 <div class="d-flex flex-column w-100 p-4 my-5" >
                    <div>
                        <h1 class="d-flex justify-content-center mb-3">You are not a Performer</h1>
                        
                        <p class="d-flex justify-content-center fw-r">You are not a performer, want to change your account to performer?</p>
                    </div>
                    <div class="my-3">
                        <button mat-ripple class="btn primary-btn" (click)="switchAccount()"><span>Switch me to Performer account</span></button>
                    </div>
                    <a class="fw-r mx-auto" style="color: var(--secondary-color);" [routerLink]="['/user/dashboard']">no thanks, navigate to user dashboard</a>
                    </div>
                    
                    </div>
                    </div>
