<div class="w-100 p-3" style="background-color: var(--frame-color);">
    <form [formGroup]="eventDetails" >
    <div class="form-group mb-4 col-12 col-md-4" >
        <p class="form-label">Title*</p>
        <div class="w-100 justify-content-center mw-25">
           <input pInputText type="text" [disabled]="editEvent!=null" class="form-input" placeholder="Enter your Full Name" value="" formControlName="Name"/>
        </div>
    </div>
    <div class="d-flex flex-wrap gap-md-5 gap-0">
    <div class="form-group mb-4 col-12 col-md-4" >
        <p class="form-label">Event Start Date*</p>
        <div class="w-100 justify-content-center">
           <!-- <input type="datetime-local" class="form-input" placeholder="Enter Start Date" value="" formControlName="StartDate"/> -->
           <p-calendar styleClass="w-100"  hourFormat="12" [touchUI]="true"  formControlName="StartDate" [showTime]="true" [stepMinute]="15" [showIcon]="true"></p-calendar>
        </div>
    </div>
    <div class="form-group mb-4 col-12 col-md-4" >
        <p class="form-label">Event End Date*</p>
        <div class="w-100 justify-content-center">
           <!-- <input type="datetime-local" class="form-input" placeholder="Enter End Date" value="" formControlName="EndDate"/> -->
           <p-calendar styleClass="w-100"  hourFormat="12" [touchUI]="true" formControlName="EndDate" [autoZIndex] = 'true' [showTime]="true" [stepMinute]="15" [showIcon]="true"></p-calendar>

        </div> 
    </div>
    </div>
    <div class="form-group mb-4 col-12 col-md-4" >
        <p class="form-label">Venue*</p>
        <div class="w-100 justify-content-center">
           <input  pInputText type="text" [autofocus]="true" class="form-input" placeholder="Enter Venue" value="" formControlName="Venue"/>
        </div>
    </div>
    
        <p class="form-label">Bids</p>
        <p-checkbox class="mb-4" label="Make it free" name="DisableBid" [binary]="true" formControlName="IsFree"></p-checkbox>
        <div class=" d-flex ">
            
            
    <div class="form-group mb-4 col-12 col-md-4"  *ngIf="!eventDetails.controls.IsFree.value">
        
        <p class="form-label">Minimum Bid*</p>
        <div class="w-100 justify-content-center">
           <input pInputText pKeyFilter="int" class="form-input" placeholder="Eg. 50" value="" formControlName="MinBid" pattern="[0-9]+"  (keypress)="onlyNumberKey($event)"/>
        </div>
    </div>
    <!-- <div class="form-group mb-4" style="max-width: max-content;">
        <p class="form-label">Priority Bid*</p>
        <div class="w-100 justify-content-center">
           <input type="text" class="form-input" placeholder="Eg. 100" value="" formControlName="PriorityBid" pattern="[0-9]+" (keypress)="onlyNumberKey($event)" />
        </div>
    </div> -->
</div>
<div class="d-flex">
    <div class="form-group mb-4 w-100">
        <p class="form-label">Search Catalog</p>
        <div class="w-100  field-checkbox d-flex flex-row gap-2 mb-3">
            <div *ngFor="let catalog of CatalogType;" class="">
                <p-radioButton [inputId]="catalog.name" [value]="catalog.value" formControlName="catalogType" (onClick)="SearchCatalogs($event)"
                ></p-radioButton>
                <label [for]="catalog.name" class="mx-2">{{ catalog.name }}</label>
            </div>              
        </div>
        <div *ngIf="this.eventDetails.value.catalogType=='custom'" class="d-flex flex-row flex-wrap gap-2">
            <div class="order-md-2 order-1">
                <p-button label="Create Catalog" size="small" [style]="{fontSize: '0.75rem'}" severity="primary" (onClick)="CreateCatalogClicked()"></p-button>
                <!-- <a class="fw-r " (click)="CreateCatalogClicked()" style="font-size: 0.75rem;">Create Catalog</a> -->
            </div>
        <div  class="form-group mb-4 col-12 col-md-4 order-md-1 order-2" >
            
            <div class="w-100">
                <p-multiSelect [options]="catalogList" formControlName="customCatalogs" optionLabel="Name"
                placeholder="Select Catalog" [styleClass]="'w-100'" [panelStyleClass]='"w-100"' 
                filterPlaceHolder="Name,Tag" [filter]="true"
                >
                <ng-template let-catalog pTemplate="item">
                    <div class="d-flex flex-row align-items-center gap-2">
                        <div>{{ catalog.Name }}</div>
                        <div class="mx-1 d-inline-block"  *ngFor="let tag of catalog.Tags">
                        <p-tag [value]="tag" [rounded]="true" class="d-inline-block" [style]="{ 'background': 'linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)', color:'white'}"></p-tag>
                    </div>
                    </div>
                </ng-template></p-multiSelect>       
            </div>   
        </div>
    </div>
    </div>
 
</div>
<div class="d-flex fex-row justify-content-between gap-3 flex-wrap">
    <p-button label="Cancel" severity="danger" icon="pi pi-times" [outlined]="true" [rounded]="true" (onClick)="Cancel()"></p-button>
    <!-- <div class="secondary-btn" style="width: fit-content; margin-right: 20px;"><button  class="px-4" >Cancel</button></div> -->
    <div class="d-flex flex-row" style="column-gap: 20px;" *ngIf="editEvent==null">
   <!-- <div> <button (click)="Save()"  class="btn primary-btn px-5" style="color: white;">Submit</button></div> -->
   <p-button label="Submit" icon="pi pi-check" [rounded]="true" (onClick)="SubmitAndActivate()"></p-button>
   <!-- <div> <button (click)="SubmitAndActivate()" class="btn primary-btn px-5" style="color: white;">Submit</button></div> -->
</div>
<div class="d-flex flex-row" style="column-gap: 20px;" *ngIf="editEvent!=null">
    <!-- <div> <button (click)="Update()"  class="btn primary-btn px-5" style="color: white;">Update</button></div> -->
    <p-button label="Update" icon="pi pi-check" [rounded]="true" (onClick)="Update()"></p-button>
    
 </div>
</div>
</form>
</div>
