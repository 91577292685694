import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
_storage=new Map<string,any>()
  constructor() { }
  SetSession(key:string, data:any){
    this._storage.set(key,JSON.stringify(data));
  }
  GetSession(key :string){
    var output =  this._storage.get(key);
    if(output==undefined){
      return undefined
    }

    return JSON.parse(output);
    
  }
  SetItem(key:string, data:any){
    localStorage.setItem(key,JSON.stringify(data));
  }
  DestroyAllSessions(){
    this._storage.clear()
  }
  DestroyAlItems(){
    localStorage.clear()
  }
  GetItem(key :string){
    var output =  localStorage.getItem(key);
    if(output==null){
      return undefined
    }
    return JSON.parse(output);

    
  }
  RemoveItem(key:string){
    localStorage.removeItem(key)
  }
}
