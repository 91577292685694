<footer class="py-4" style="max-width: none;">
  <div class="mx-5">
    
    <div class="d-flex flex-column flex-wrap justify-content-center footer-group">
        <h5>Quick Links</h5>
<a class="fw-r " [routerLink]="['privacy-policy']">Privacy Policy</a>
<a class="fw-r"  [routerLink]="['terms-and-conditions']">Terms and Conditions</a>
<a class="fw-r"  [routerLink]="['about-us']">About Us</a>
<a class="fw-r "  [routerLink]="['pricing']">Pricing</a>
<a class="fw-r "  [routerLink]="['refund-policy']">Refund Policy</a>

    </div>
</div>
    <hr>
    <span class="fw-r m-auto w-100 text-center d-block white">Powered by Tvox Studio</span>
  
</footer>