import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { PaymentsService } from 'src/app/Services/payments.service';

@Component({
  selector: 'app-subscription-return',
  templateUrl: './subscription-return.component.html',
  styleUrls: ['./subscription-return.component.css']
})
export class SubscriptionReturnComponent implements OnInit {

  constructor(private authService : AuthService, private paymentService : PaymentsService, private router : Router) { }

  ngOnInit(): void {
    var subId = localStorage.getItem('subId');
    if(!subId){this.router.navigate(['performer/dashboard']) }
   if(this.authService.userData==undefined){
    var unsubscribe =  this.authService.UserDetailsSubject.subscribe(()=>{
      this.CheckStatus();

        
    })
  }
  else{
    this.CheckStatus()
  }
  }
  CheckStatus(){
    this.paymentService.VerifySubscription().subscribe((result:any)=>{
      if(result.isSuccessStatus){
      this.router.navigate(['performer/dashboard'])
      localStorage.removeItem('subId')
     
      }
      if(result.TimeElapsed){
        this.router.navigate(['performer/dashboard'])
        throw 'Time Elapsed'
      }
          },(error)=>{
            localStorage.removeItem('subId')
      throw error;
          })
  }

}
