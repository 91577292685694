import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UserStateService } from '../../services/user-state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/snackbar.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
@Input() isFromBashNav:boolean=false
  userProfileFG:FormGroup
  imageUrl:any
  imageFile: any;
constructor(private router:Router,public userState:UserStateService, private zone:NgZone, private formBuilder:FormBuilder, private notificationService:SnackbarService){
  
}
items:MenuItem[] = [
  { label: 'Basic Details', badgeStyleClass:'testing123' },
  {label:'Socials'}
];

  ngOnInit(): void {
   const userProfile = this.userState.currentUser$.getValue()
    this.userProfileFG = this.formBuilder.group({
      id:[userProfile.id,Validators.required],
      name:[userProfile.name, Validators.required],
      emailId:[userProfile.emailId, [Validators.email,Validators.required]],
      phoneNumber:[userProfile.phoneNumber,[Validators.required,Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)]],
    })
    this.imageUrl=userProfile.profilePicture
  }
  changeUserProfile(){
    let input = document.createElement('input');
    input.accept ="image/*"
  input.type = 'file';
  input.onchange = this.SelectProfilePicture.bind(this)
  
  input.click();
  }
  SelectProfilePicture(uploader :any){
    
    if (uploader.target.files && uploader.target.files[0]) {
      
        this.imageFile = uploader.target.files[0];
      this.zone.run(()=>this.imageFile=this.imageFile)
      
      var reader = new FileReader();    
      reader.onload = function (e:any) {
          //set the image data as source
         
          $('#profile-picture').attr('src', e.target.result);
      }    
      reader.readAsDataURL( this.imageFile );
  }

  }
  UpdateProfile(){
    if(!this.imageFile){
    if(this.userProfileFG.invalid){
      this.notificationService.CreateNotification("Invalid input. All fields are required.")
      return
    }
    if(!this.userProfileFG.touched){
      this.notificationService.CreateNotification("Nothing to update.")
      return
    }
  }
    let userProfile = this.userProfileFG.value
    var formData = new FormData()
    formData.append("Id",userProfile.id)
    formData.append("Name",userProfile.name)
    formData.append("EmailId",userProfile.emailId)
    formData.append("PhoneNumber",userProfile.phoneNumber)
    if(this.imageFile){
    formData.append("ProfilePicture",this.imageFile)
    }

this.userState.UpdateUserProfile(formData).subscribe((response)=>{
if(response){
  this.userState.UpdateUserDetailsSubject.next(null)
this.router.navigate([this.userState.claims.role,'dashboard'])
}
})

  }
}
