import { CanActivateFn, Router } from '@angular/router';
import { UserStateService } from '../_common/services/user-state.service';
import { inject } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';

export const activePlanGuard: CanActivateFn = (route, state) => {
  var userState = inject(UserStateService)
  var router = inject(Router)
  if(userState.isLoading$.getValue()==false){
      return verifyRedirect(userState.plan,router)
  }
  return userState.isLoading$.pipe(filter(x=>x==false),
  map(x=>{
    if(x==false){
     return verifyRedirect(userState.plan,router)
    }
    return false
  }),
  take(1)
  )
};

const verifyRedirect = (plan:any,router:Router)=>{
  if(plan==null){
     router.navigate(['performer','plan'])
  }
  return true
}

