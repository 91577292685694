<div class="d-block p-4 m-auto" style="max-width: 500px;">
    <div class="d-flex flex-column">
        <h4 class=" mb-4"> Welcome to the Party <span class="white" *ngIf="performerId!=null">with &#64;{{this.performerId}}</span></h4>
        <div *ngIf="performerId==undefined">
            <span class="mb-5 white">Before we begin, Please provide performer's ID so that we recognize who you are
                requesting</span>
            <input id="performer-id" pInputText class="form-input my-3" placeholder="Performer's ID">
            <button class="btn primary-btn fit-btn"  (click)="FindandCheckInPerformer()"><span>Check In</span></button>
            <div [@cameraAnimation]="cameraAnimationState" class="camera-container" [routerLink]="['/scan-code']">
            <div  class="d-flex h-100"
            >
                <i class="pi pi-qrcode d-flex m-auto white" style="font-size: 1.5rem; line-height: normal;"></i>
            </div>
        </div>
        </div>
        <div *ngIf="performerId!=undefined">
            <p class="mb-2">Search a song <span class="white" *ngIf="!isCustomSearch">on Spotify</span></p>
            <div class="form-group gp-phone-number d-inline-flex w-100" style="height: 2.5rem;">
            <input type="text" (keyup)="searchTextChanged($event.target)" class="text-decorate-input "
                [placeholder]="!isCustomSearch?'Search on Spotify':'Search by song name, artist name'">
                <p-progressSpinner [style]="{'color':'red','height':'2rem','width':'2rem'}" [hidden]="isSearchingComplete"></p-progressSpinner>
            </div>
            <span class="fw-r white" style="font-size: 0.75rem;" *ngIf="isCustomSearch">The songs are curated by artist, All songs may not be available to be played</span>
            
            <div class="mt-3" *ngIf="selectedTrack!=null ">
                <app-selected-track [track]="selectedTrack" [isCustomTrack]="isCustomSearch"></app-selected-track>
                <div class="bid-group my-3 d-flex flex-column" *ngIf="!eventDetails.isFree">
                    <div class="mb-3 white"> Minimum price set by performer is ₹{{this.eventDetails.minBid}}</div>
                    <div class="form-group gp-phone-number d-flex flex-row mb-3">
                        <span class="country-code px-2 text-decorate-icon">₹</span>
                        <input for="bid" class="text-decorate-input form-control py-0 " h-100  maxlength="10"
                            value="{{this.finalBid}}" (keyup)="onBidChange($event.target)" />
                    </div>
                    <span style="color:#6C7072; font-size: 12px; width: 100%; margin-bottom: 15px;" class="">Your request will be placed in the bottom of the
                        queue. Playing sequence will solely depend on performer's will. </span>
                    <button (click)="onPriorityPressed()" class="btn primary-btn m-auto" *ngIf="false" style="width: fit-content;"><span>Prioritize request for ₹{{this.eventDetails.priorityBid}}</span></button>



                </div>
                <span *ngIf="eventDetails.isFree" style="color:#6C7072; font-size: 12px; width: 100%; margin-bottom: 15px;">
                    Song request is made free by performer!
                </span>
            </div>
            <div class="d-flex flex-column p-3" *ngIf="trackList!=null">
                <div *ngFor="let track of trackList" (click)="onTrackSelected(track)">
                    <app-selected-track [track]="track" [isCustomTrack]="isCustomSearch"></app-selected-track>
                </div>



            </div>
            <!-- pay button -->

        </div>
    </div>
    <div class=" fixed-bottom w-100" style=" z-index: 100;">
        <div class="m-auto px-5 py-2" style=" max-width: 500px; transform: all 0.5s; margin-bottom: 15px;"  *ngIf="selectedTrack!=null">
        <div style="font-size: 12px; color: red;" *ngIf="priceError!=null">{{this.priceError}}</div>
        <div class="secondary-btn">

            <button (click)="onPayPressed()"><span class="d-inline" *ngIf="!eventDetails.isFree">Pay ₹ {{this.finalBid}} and</span> Request</button>
        </div>
    </div>
    <!-- already requested -->
        <div class="w-100  mx-auto" style="background-color: var(--element-color); max-width: 500px;" *ngIf="pendingRequests!=null">
            <p-carousel [value]="pendingRequests" [circular]="false" [showNavigators]="false" [showIndicators]="pendingRequests.length>1">
                <ng-template let-pendingRequest pTemplate="item">
                    <div class="d-flex flex-row" style="height: 2.5rem;">
                    <div class="w-100 d-flex flex-column my-auto mx-3">
                        <p class="white m-0 w-100" style="font-size: 1rem; overflow-wrap: anywhere;
                        overflow: auto;
                        text-overflow: ellipsis;white-space: nowrap;">{{pendingRequest.name}}</p>
                        <span class="m-0 white" style="font-size: 0.75rem; font-style: italic;">{{pendingRequest.artist}}</span>

                    </div>
                    <div class="d-flex m-auto px-2">
                        <p-tag  value="{{pendingRequest.status}}"></p-tag>
                    </div>
                    <div class="d-flex m-auto ">
                       
                        <a (click)="requestDetailsClicked(pendingRequest.id)" ><i class="bi bi-chevron-right white m-3 "></i></a>
                    
                    </div>
                </div>
                </ng-template>
            </p-carousel>
            
        </div>
    </div>
               
    <p-dialog header="Please let us know who you are" (onHide)="LogOutUser()" [(visible)]="isLogonRequired" [modal]="true" (onShow)="phoneComponent?.InitializeGoogleLogon()" [draggable]="false" [resizable]="false" styleClass="ui-dialog" >
        <div *ngIf="phoneAuthStage==1">
            <app-phone-number (onPhoneNumber)="onPhoneNumberChange($event)"  (onOtpSend)="onOTPSent($event)" (onGoogleLoggedIn)="GoogleLoggedIn($event)"></app-phone-number>
            <br>
            <div class=""><button class="btn primary-btn white" (click)="phoneComponent?.OnSendOtp()">Send OTP</button></div>
        </div>
        <div *ngIf="phoneAuthStage==2" >
        <app-otp [verificationId]="this.verificationId" [phoneNumber]="this.phoneNumber" (OtpProcessed)="OtpProcessed($event)" (OtpValid)="this.Togglebutton($event)"></app-otp>
        <br>
            <div><button class="btn primary-btn white" (click)="otpComponent?.verifyOtp()">Verify</button></div>
        </div>
        <div *ngIf="phoneAuthStage==3" >
            <app-basic-customer-details (onUserOnboarded)="isUserOnboarded($event)" ></app-basic-customer-details>
           
            </div>
                       
                    </p-dialog>

</div>