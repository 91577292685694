<div class=" jukrContainer">


<div class="m-auto p-3">
    <div class="card  jukr-card logon-card m-auto">
        

        <div class="d-flex flex-column w-100 p-4 mt-5 mb-3">
            
            <div>
                <h1 class="d-flex justify-content-center">OTP Verification</h1>
            </div>
            <hr>



            <div class="form-group">
                <p class="">Enter OTP sent to +91 {{this.phoneNumber}}</p>
                <div class="w-100 ">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6, inputClass:'each_input',allowNumbersOnly:true}"
                        class=" d-flex justify-content-center label-bg"></ng-otp-input>
                        
                </div>
            </div>
           
            <div class="d-flex flex-row my-3 justify-content-end">
                <p id="timer" *ngIf="this.timerElapsed==false"></p>
                <a class="mx-3 resend" [class.disabled]="this.timerElapsed==false" (click)="ResendOtp()">resend</a>
            </div>

            <br>

            <div class="form-element">
                <button class="btn btn-primary btn-logon" id="send-otp-btn" (click)="processOtp()"><span>Verify and
                        Proceed</span></button>
            </div>
            <div id="recaptcha-container"></div>

            <a href="javascript:void(0)" class="mt-5 w-100 text-end" style="color: var(--secondary-color); justify-content: end;" (click)="this.location.back()" >back</a>

        </div>
    </div>
</div>
   
</div>