<div class=" jukrContainer">


 
   <div class="m-auto p-3">
            <div class="card  jukr-card logon-card m-auto">
                <div class="d-flex flex-column w-100 p-4 my-5" >
             
                <div>
                <h1 class="d-flex justify-content-center">Hi There!</h1>
                <p class="d-flex justify-content-center fw-r">Logon to Jumr</p>
            </div>
            <hr>
<div id="googleButton" class="d-flex justify-content-center"></div>

                <!-- <button class="d-flex justify-content-center mx-auto p-2 w-100 google-login" (click)="logonWithProvider('Google')">
                    <img width="20px" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                    <span class="google-login "> Sign in with Google</span>
                </button> -->
                <hr>
                <div class="d-flex flex-row separator-parent">
                
                <div role="separator" class="separator"></div>
                <p class="m-0 mx-3 h-auto">Or</p>
                <div role="separator"  class="separator"></div>

            </div>
            <hr>
            
            <div class="form-group">
                <label class="form-label">Enter Phone number</label>
                <div class="form-group gp-phone-number d-flex flex-row">
                    <span class="country-code px-2 border-end text-decorate-icon" id="country-code">+91</span>
                    <input for="Phone" class="form-control py-0 text-decorate-input" h-100 id="phone-number" maxlength="10"
                        placeholder="eg. 9876543210"  />
            
                </div>
                <span style="color:#6C7072; font-size: 12px;;">You will receive an SMS verification that may apply message and data rates.</span>
            </div>
            <br>

                    
                    <div class="form-element ">
                        <button class="btn btn-primary btn-logon" id="send-otp-btn" (click)="OnSendOtp()"><span>Get OTP</span></button>
                    </div>
                   
                    <a href="" class="mt-5 w-100 text-end" style="color: var(--secondary-color); justify-content: end;"  >back</a>  
                
            </div>
           
            
        </div>
    </div>

    </div>
    