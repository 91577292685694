<div>
    <span class="p-input-icon-left my-2 d-flex flex-row" style="gap: 30px;">
        <!-- <i class="pi pi-search d-block"></i>
        <p-dropdown [options]="jobs" optionLabel="name" placeholder="filter by Job name" [style]="{'width': '250px'}" [(ngModel)]="SelectedJob">
            
        </p-dropdown>
        <button class="btn primary-btn" style="width: fit-content;" matRipple>Search</button> -->
        <!-- <input type="text" placeholder="Search name, email, phone, role" class="p-inputtext-sm" pInputText [(ngModel)]="searchUserValue" (keyup)="searchUser()" /> -->
    </span>
    <app-base-table [dataObj]="jobList" [tableConfiguration]="tableConfiguration" [totalRecords]="totalRecords"></app-base-table>
</div>
