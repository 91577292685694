<div class="m-auto" style="max-width: 1200px;">
    <div class="m-3">
        <h1>Refund Policy</h1>
    <p>The requester will be paying with UPI autopay feature which allows requester to keep there money intact unless the song is played by the DJ. 
        Amount will only be deducted if the performer accepts the request. By accepting the request, performer confirms to play the song and the money will get deducted from the payee's account.
    
    </p>
    <p>
        By signing up on the platform, Performer agrees to the terms mentioned above.<br> By Paying money on this platform, Payee agrees the terms mentioned above.

        In case the performer accepts the request but does not play the song, Tvox Studio and Jumr is not reaponsible for any refund process.
        
    
    </p>
    <hr>
    
    <hr>
    <p>
        Kindly reach out to below mentioned email address for queries and questions.
    </p>
        <br>
        <p>support&#64;jumr.in</p>
    
    </div>
    
</div>