export class EventDetails {
id:string =''
name:string =''
startDate: string =''
endDate :string =''
minBid:string=''
priorityBid :string =''
venue :string =''
isActive:boolean
catalogType:number
isFree:boolean
constructor(event:any){
    this.name=event.name
    this.startDate=event.startDate
    this.endDate=event.endDate
    this.minBid=event.minBid
    this.priorityBid=event.priorityBid
    this.venue=event.venue
    this.id=event.id
    this.catalogType = event.catalogType
    this.isFree= event.isFree
}
}
export class EventHistory extends EventDetails{
isDirty:boolean

}