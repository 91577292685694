import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LoadPaymentComponent } from '../load-payment/load-payment.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PaymentsService } from 'src/app/Services/payments.service';
import { StateService } from 'src/app/Services/state.service';
import { Constants } from 'src/app/common/Contants/Contants';
import { PendingRequestsModel } from 'src/app/models/pendingRequestsModel';


@Component({
  selector: 'app-eb-process-payment',
  templateUrl: './eb-process-payment.component.html',
  styleUrls: ['./eb-process-payment.component.css']
})
export class EbProcessPaymentComponent implements OnInit, AfterViewInit  {
  @ViewChild(LoadPaymentComponent) child : LoadPaymentComponent
  status:any
  order_id:any
  constructor(private route: ActivatedRoute, private paymentService :PaymentsService, private stateService : StateService){

  }
  ngAfterViewInit (): void {
    if(this.status!=undefined){
      this.VerifyStatus(this.status)
      // this.paymentService.CheckStatus('Eb/CheckStatus',this.order_id)
       return;
      }
      if(this.order_id!=undefined){
        this.paymentService.CheckStatus('Eb/CheckStatus',this.order_id).subscribe((response:any)=>{
this.VerifyStatus(response.status);
        })
      }  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((map:ParamMap)=>{

      if(map.has('status')){
        this.status = map.get('status')
      }
      if(map.has('order_id')){
        this.order_id=map.get('order_id')
      }
      

    })
  }
  VerifyStatus(status:any){
    
     // this.paymentService.CheckStatus('eb/VerifyStatus',this.order_id).subscribe((response:any)=>{
        if(status=='success'){
          const pendingRequest = {
            id:this.order_id,
            status:'Requested',
            name:'',
            artist:''
          }
        
      this.child.SetSuccess()
     }else{
      this.child.SetFailure()
     }
  //   },(error)=>{
  //     this.child.SetFailure()
  //   })
  // }

    }
}
