import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { CatalogService } from 'src/app/Services/catalog.service';
import { SpotifyService } from 'src/app/Services/spotify.service';
import { BaseFilterQuery } from 'src/app/common/Contants/Contants';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';
import { TableHeaderItem } from 'src/app/models/TableHeaderItem';
import { SnackbarService } from 'src/app/snackbar.service';

@Component({
  selector: 'app-add-songs-to-catalog',
  templateUrl: './add-songs-to-catalog.component.html',
  styleUrl: './add-songs-to-catalog.component.css'
})
export class AddSongsToCatalogComponent implements OnInit, AfterViewInit {
  @ViewChild('action') actionTemplate:TemplateRef<any> 
  @Input() catalogName:string
  @Input() catalogId: string | null;
  isSearchingComplete:boolean
  isAddCustom:boolean
  trackList:any
  tracksInCatalogList:any[] =[]
  timeout: number | undefined;
  fgCustomTrack:FormGroup
  searchText:string|undefined
  isLoading:boolean=false
  totalRecords:any
  headers:TableHeaderItem[] =[]
  tableConfiguration:TableConfigurationModel = {
    headers:this.headers,
    id:'songList',
    lazyLoad:(event:any)=>this.loadSongsData(event),
    loading:this.isLoading
  }
  constructor(private activatedRoute:ActivatedRoute, private route:Router, private spotifyService:SpotifyService, private formbuilder:FormBuilder
    ,private snackbarService: SnackbarService, private catalogService:CatalogService, private config:DynamicDialogConfig ){
    this.fgCustomTrack = formbuilder.group({
      name:['',Validators.required],
      artist:['', Validators.required],
      url:[''],
      artwork:['']

    })
    this.catalogId = config.data.catalogId
    this.catalogName= config.data.catalogName
  }
  ngAfterViewInit(): void {
    this.headers= [
      {
        displayName:'Song Name',
        isVisible:true,
        name:'SongName',
        type:'text',
        isSortable:true
      },
      {
        displayName:'Artist Name',
        isVisible:true,
        name:'ArtistName',
        type:'text',
        isSortable:true
      },{
        displayName:'',
        isVisible:true,
        name:'action',
        type:'action',
        isSortable:false,
        customTemplate:this.actionTemplate,
        isCustomTemplate:true
      }
        ]
        this.tableConfiguration = {
          headers:this.headers,
          id:'songList',
          lazyLoad:(event:any)=>this.loadSongsData(event),
          loading:this.isLoading
        }
  }
  
  ngOnInit(): void {
    // this.fgCustomTrack.disable()
    // this.activatedRoute.params.subscribe((query)=>{
    //   if(query && query.catalogId){
    //     this.catalogId = query.catalogId

    //   }else{
    //     this.route.navigate(['performer','dashboard'])
    //   }
    // })
  }
  searchTextChanged(text: any){
    if(String(text.value).length<3){
return
    }
    window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => this.TriggerSearch(text.value), 500); 
  }
  TriggerSearch(value: string){
    if(value.length<3){
      return
    }
    this.isSearchingComplete=false
    this.spotifyService.searchSpotify("/Search/Search/"+value).subscribe((ret)=>
    {
      this.trackList=ret;
      this.isSearchingComplete=true
    });
  }
  OnToggleEvent(){
    if(this.isAddCustom){
      this.fgCustomTrack.enable()
    }else{
      this.fgCustomTrack.disable()
    }
    
  }
  OnSpotifyTrackSelected(track:any){
    if(track){
      this.fgCustomTrack.setValue({
        name:track.name,
        artist:track.artist,
        url:track.url,
        artwork:track.artwork
      })
    }
  }
  addToTrackList(){
    
    if(!this.fgCustomTrack.controls.name.valid || !this.fgCustomTrack.controls.artist.valid  ){
this.snackbarService.CreateNotification("Name and Artist Name are required")
return
    }
    let data= {
      songName:this.fgCustomTrack.controls.name.value,
      artistName:this.fgCustomTrack.controls.artist.value
    }
    this.catalogService.InsertSongAsync(this.catalogId,data).subscribe((result:any)=>{
      if(result){
        var track = {
          ArtistName:result.artistName,
          SongName:result.songName,
          Id:result.id,
          CatalogId:result.catalogId
        }
        this.loadSongsData()
        this.fgCustomTrack.reset()
      }
    })
    
  }
  deleteTrack(event:any){
    let agreed = window.confirm("Are you sure you want to delete track: "+event.SongName+"?");
    if(!agreed){
      return
    }
    this.catalogService.deleteTrackFromCatalog(this.catalogId,event.Id).subscribe((response)=>{
      if(response){
        this.loadSongsData()
        this.snackbarService.CreateNotification("successfully removed track "+event.SongName)
      }
    })
  }
  FilterTrackList(searchString:string|undefined){
    if(searchString && searchString?.length<4){
      return
    }

  }
  loadSongsData(state?:LazyLoadEvent){
    let data = BaseFilterQuery
    if(state){data=JSON.stringify(state)}
    this.catalogService.GetSongsList(this.catalogId,data).subscribe((result:any)=>{
      this.tracksInCatalogList = result.data
      this.totalRecords = result.totalCount
    })
  }
}
