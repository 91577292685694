<h1 class="d-flex justify-content-center">Simple, easy pricing</h1>
<hr>
<div class="d-block w-100 mx-auto" style="max-width: 1065px;">
    <!-- <a class="w-100 d-flex justify-content-end my-2" style="color: #6DDCFF; cursor: pointer;" (click)="skip()"><u>Skip and Explore></u></a> -->
<div class=" jukrContainer d-flex flex-row justify-content-around flex-wrap gap-3" >

    <div class="card  jukr-card logon-card w-100 h-100 " style="align-self: flex-start !important; " >
     
        <div class="d-flex flex-column w-100 p-4 my-2">
                <p>Starter Plan</p>
                <div class="d-flex flex-row mb-2">
                    <h1 class="d-flex flex-column justify-content-end m-0" style="vertical-align: bottom; line-height: 40px; ">15%</h1>
                    <span class="helper-span mx-1 d-flex flex-column justify-content-end">/event earning</span>
                </div>
                <p style="color: var(--helper-text-color) !important;">All the basic features to boost your income.</p>
                <div class="separator"></div>
                <div class="my-4">
                    <ul class="m-0 p-0">
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">No upfront recurring cost</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Get QR code</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Share code with your customers</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Set base bid </p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">accept shoutout requests</p>
                        </li>

                    </ul>
                </div>
                <div class="secondary-btn">
                   <button [disabled]="this.currentPlan!=null && this.currentPlan=='Starter'" [ngClass]="{'btn-primary-used':this.currentPlan!=null && currentPlan=='Starter'}" (click)="StartStarterPack()">{{this.starterButton}}</button>
                </div>
                

       
    </div>
    </div>
    <div style="background-image: var(--primary-gradient); padding: 3px; border-radius: 10px;">
    <div class="card  jukr-card logon-card  w-100 h-100" style="align-self: flex-start !important; " >
     
        <div class="d-flex flex-column w-100 p-4 my-2">
                <p>Subscription Plan</p>
                <div class="d-flex flex-row mb-2">
                    <h1 class="d-flex flex-column justify-content-end m-0" style="vertical-align: bottom; line-height: 40px; ">₹2500</h1>
                    <span class="helper-span mx-1 d-flex flex-column justify-content-end">/month</span>
                </div>
                <p style="color: var(--helper-text-color) !important;">Boost your income with no commission cuts and some extra features.</p>
                <div class="separator"></div>
                <div class="my-4">
                    <ul class="m-0 p-0">
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">No upfront recurring cost</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Get and customize QR code</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Share code with your customers</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Set base bid </p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Get prioritized requests</p>
                        </li>
                        <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Accept shoutout requests</p>
                        </li>     <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Get Subscriber button</p>
                        </li>     <li class="d-flex flex-row">
                            <img src="../../../assets/icons/check-circle.svg" style="height: 20px;"/>
                            <p class="p-0 mx-2 my-1">Market your music and events with customer details like mobile and email address</p>
                        </li>
                    </ul>
                </div>
                
                   <!-- <button (click)="StartSubscription()" [disabled]="this.currentPlan!=null && this.currentPlan=='Subscription'" [ngClass]="{'btn-primary-used':this.currentPlan!=null && currentPlan=='Subscription'}" class="btn primary-btn"><span>{{this.subscriptionButton}}</span></button> -->
                                  <button (click)="StartSubscription()" [disabled]="true" [ngClass]="{'btn-primary-used':this.currentPlan!=null && currentPlan=='Subscription'}" class="btn primary-btn"><span>Coming Soon</span></button>

                

       
    </div>
    </div>
</div>
</div>
</div>