<div class="d-flex flex-row h-100 p-2">

    <div class="d-flex flex-column col-2 d-none" style="min-height: 100%; background-color: var(--frame-color); ">

    </div>
    <div class="d-flex flex-column mw-100 w-100  mx-auto" style="flex-shrink: 1; flex-grow: 0;">
        <div class="jukr-frame d-flex flex-column p-2 gap-2">
            <div class="d-flex w-100">
                <div class="event-info-group p-2 flex-grow-1 d-flex flex-column" style="border-radius: var(--border-radius);"
                *ngIf="eventDetails">
                    <div class="d-flex flex-row justify-content-evenly">
                        <div class="details-item" >
                            <i class="d-inline-block bi bi-calendar-event mx-2 white" ></i>
                        <label class="fw-r">{{eventDetails.name}}</label>
                    </div>
                        <div class="details-item">
                            <i class=" d-inline-block bi bi-geo mx-2 white" ></i>
                            <label class="fw-r">{{eventDetails.venue}}</label>
                        </div>
                        <div class="details-item">
                            <i class=" d-inline-block bi bi-clock mx-2 white"></i>
                            <label class="fw-r">{{eventDetails.startDate | date:'short'}} - {{eventDetails.endDate | date:'short'}}</label>
                        </div>
                        <div class="details-item">
                            <i class=" d-inline-block bi bi-currency-rupee mx-2 white"></i>
                            <label class="fw-r">{{eventDetails.isFree? '0': eventDetails.minBid}}</label>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="d-flex flex-row justify-content-between ">
            <h4>Song Requests</h4>
            <p-button (onClick)="startStreaming()">{{this.startButtonState==0?'Start Receiving Requests':'Stop Listening'}}</p-button>
         <button class="btn primary-btn white px-5 d-none" style="width: fit-content;" (click)="startStreaming()" > {{this.startButtonState==0?'Start Receiving Requests':'Stop Listening'}}</button>
        </div>
            <div class="p-1 my-2">                      
                <div class="d-flex flex-column m-auto gap-3">
                    <div>
                        <p-panel [toggleable]="true" [collapsed]="true" collapseIcon="pi pi-angle-down" expandIcon="pi pi-angle-up">
                            <ng-template pTemplate="header">
                                <div class="d-flex flex-column">
                                    <p>Requests Archive</p>
                                    <div class="d-flex flex-row w-100" *ngIf="NowPlaying.length>0">


                                        <img src="{{NowPlaying[0].Track.thumbnail}}"
                                            *ngIf="!isCustomTrack"
                                            class="thumbnail-console">
                                            <i class=" bi bi-file-music-fill white " style="font-size: 3em;" *ngIf="isCustomTrack"> </i>
                                        <div class="px-3 d-flex flex-column">
                                            <label>{{NowPlaying[0].Track.name}}</label>
                                            <i class="white" style="font-size: 0.75rem; ">{{NowPlaying[0].Track.artist}}</i>
                                        </div>
                                        <div class="request-go-to-spotify d-flex flex-row">
                                            <a class="my-auto" title="go to spotify" href=""> <i
                                                    class="pi pi-external-link px-2 my-auto"></i><span
                                                    class="d-none d-lg-inline-block" style="font-size: 0.75rem;">Go to
                                                    spotify</span></a>
                                        </div>
            
                            
            
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="icons">
                                <!-- <p class="d-inline-block">Show Rejected?</p>
                                <p-toggleButton 
                                [(ngModel)]="showArchiveRejected" 
                                onLabel="Yes" 
                                offLabel="No" /> -->
                                Show Rejected?
                                <mat-slide-toggle class="my-auto" [(ngModel)]="showArchiveRejected"  ></mat-slide-toggle>
                            </ng-template>
                            <div>
                                <div *ngFor="let final of FinalQueue;trackBy:trackRequest">
                                    <div *ngIf="shouldShowArchive(final.Status)" class="d-flex flex-row w-100 p-2 m-2 gap-2" style="background-color: var(--highlight-bg); border-radius: var(--border-radius);">


                                        <img src="{{final.Track.thumbnail}}"
                                            *ngIf="!isCustomTrack"
                                            class="thumbnail-console">
                                            <i class=" bi bi-file-music-fill white " style="font-size: 3em;" *ngIf="isCustomTrack"> </i>
                                        <div class=" d-flex flex-column">
                                            <label style="overflow: hidden;
                                            text-overflow: ellipsis;
                                            ">{{final.Track.name}}</label>
                                            <i class="white" style="font-size: 0.75rem; ">{{final.Track.artist}}</i>
                                        </div>
                                        <div class="request-go-to-spotify d-flex flex-row">
                                            <a class="my-auto" title="go to spotify" href=""> <i
                                                    class="pi pi-external-link px-2 my-auto"></i><span
                                                    class="d-none d-lg-inline-block" style="font-size: 0.75rem;">Go to
                                                    spotify</span></a>
                                        </div>
            
                                        <div class="ms-auto"></div>
                                        <p-tag [style]="{fontSize:'0.75rem'}" [value]="ArchiveTagLabel(final.Status)" [severity]="ArchiveTagSeverity(final.Status)" />
                                    </div>
                                </div>
                            </div>
                        </p-panel>
                    </div>
                    <div class="request-card p-3 mb-2" *ngFor="let item of RequestQueue ;trackBy:trackRequest">
                        <div class="d-flex flex-row w-100">


                            <img src="{{item.Track.thumbnail}}"
                                *ngIf="!isCustomTrack"
                                class="thumbnail-console">
                                <i class=" bi bi-file-music-fill white " style="font-size: 3em;" *ngIf="isCustomTrack"> </i>
                            <div class="px-3 d-flex flex-column">
                                <label>{{item.Track.name}}</label>
                                <i class="white" style="font-size: 0.75rem; "> {{item.Track.artist}}</i>
                            </div>
                            <div class="request-go-to-spotify d-flex flex-row">
                                <a class="my-auto" title="go to spotify" href="{{item.Track.spotifyUrl}}"> <i
                                        class="pi pi-external-link px-2 my-auto"></i><span
                                        class="d-none d-lg-inline-block" style="font-size: 0.75rem;">Go to
                                        spotify</span></a>
                            </div>

                            <div class="request-decision my-auto d-flex flex-row  justify-content-end">
                                <button class=" request-decision-btn white request-decision-accept-btn"
                                    style="background-color: green;" (click)="AcceptRequest(item)"><i class="pi pi-check my-auto px-1"></i><span
                                        class="request-decision-label d-none d-lg-block white" >Accept</span></button>
                                <button class="request-decision-btn white request-decision-reject-btn" (click)="RejectRequest(item)"
                                    style="background-color: red;"><i class="pi pi-times my-auto  px-1"></i><span
                                        class="request-decision-label d-none d-lg-block white" >Reject</span></button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>
    <div class=" flex-column col-2 d-none" >
        <div class="p-3 event-details" style="background-color: var(--frame-color);" *ngIf="eventDetails!=null">
            <h4>Event Details</h4>
            <div class="d-flex flex-row justify-content-between">
                <p class="fw-r">Name</p>
                <p>{{eventDetails.name}}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p class="fw-r">Venue</p>
                <p>{{eventDetails.venue}}</p>
            </div>

            <div class="d-flex flex-row justify-content-between">
                <p>Start Date</p>
                <p>{{eventDetails.startDate | date}}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p>End Date</p>
                <p>{{eventDetails.endDate | date}}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p>Minimum Bid</p>
                <p>{{eventDetails.minBid}}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p>Priority Bid</p>
                <p>{{eventDetails.priorityBid}}</p>
            </div>


        </div>
    </div>
</div>