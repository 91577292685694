<div class="d-flex flex-column w-100">
    

<div class="justify-content-start w-100 mx-auto" style="max-width: 500px; height: 100vh;">
<app-user-transactions-list *ngIf="activeTab=='Transactions'"></app-user-transactions-list>

</div>
<div class="user-nav-menu" >
    <div class="d-flex flex-row w-100 justify-content-between my-auto">
        <div *ngFor="let item of navLinks" class=" w-100 h-100 " style="cursor: pointer;" >
            <div class="d-flex flex-column justify-content-center p-2 user-nav-item" (click)="onUserTabClick(item)" >
                <i class="{{item.icon}}" [ngClass]="item.class" ></i>
                <label class="d-flex mx-auto fw-r " [ngClass]="item.class" >{{item.label}}</label>
            </div>
        </div>
    </div>
</div>
</div>

