<div class="p-3 d-block w-100">
    <div class="d-flex flex-row" style="gap:2rem">
        <button class="btn primary-btn" style="width: max-content;" (click)="CreateCatalog()" matRipple><span>Create Catalog</span></button>
    </div>
    <div class="my-4">

   
    <div style="background-color: var(--frame-color);">
            <app-base-table [tableConfiguration]="tableConfiguration"
            [totalRecords]="totalCatalogs"
            [dataObj]="catalogList"
            [isCheckBoxEnabled]="false"
            [ngClass]="totalCatalogs==0?'d-none':'d-block'"
            >

            </app-base-table>
    </div>
    <p *ngIf="totalCatalogs == 0">No catalog found</p>
    
</div>
    
</div>
<ng-template #tags let-item="item" let-data="data">
    
    <div class="mx-1 d-inline-block"  *ngFor="let tag of data[item.name]">
    <p-tag [value]="tag" [rounded]="true" class="d-inline-block" [style]="{ 'background': 'linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)', color:'white'}"></p-tag>

</div>
</ng-template>

<ng-template #action let-item="item" let-data="data">
    <div class="d-flex flex-row gap-1">
    <p-button icon="pi pi-external-link"  [rounded]="true" [raised]="true" [text]="true" severity="info " (onClick)="OpenTracksDialog(data)"></p-button>
    <p-button icon="pi pi-trash" [rounded]="true" [raised]="true" [text]="true" severity="danger"  (onClick)="DeleteCatalog(data)"></p-button>
</div>
</ng-template>