import { AfterViewInit, Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialResponse } from 'google-one-tap';

import { AuthService } from 'src/app/Services/auth.service';
import { environment } from 'src/environments/environment';
import { UserStateService } from '../../services/user-state.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css']
})
export class PhoneNumberComponent implements OnInit, AfterViewInit{
  @Output() onOtpSend = new EventEmitter<string>();
  @Output() onPhoneNumber = new EventEmitter<string>();
  @Output() onGoogleLoggedIn = new EventEmitter<any>();
  captcha :any
  phoneNumber: string;
constructor(private authService : AuthService, private router : Router,private recaptchaV3Service: ReCaptchaV3Service, private userState:UserStateService){}
  ngAfterViewInit(): void {
    // this.InitializeGoogleLogon()
  }
  ngOnInit(): void {
    

  }
  InitializeGoogleLogon() {
    //@ts-ignore
// window.onGoogleLibraryLoad=()=>{
  //@ts-ignore
  google.accounts.id.initialize({
    client_id:environment.auth.google.clientId,
    callback:this.handleGoogleCallback.bind(this),
    auto_select:false,
    cancel_on_tap_outside:false,itp_support:true
  });
  //@ts-ignore
  google.accounts.id.renderButton(
    //@ts-ignore
    document.getElementById("googleButton"),
    {theme:"outline",size:"large",width:"300",shape:"pill"}
    
  );
  //@ts-ignore
  google.accounts.id.prompt((notification:PromptMomentNotification)=>{
    console.log(notification)
  })
  

// }
}
async handleGoogleCallback(response: CredentialResponse) {
this.authService.VerifyGoogleCredentials(response.credential).subscribe((result) => {
  this.userState.saveUserState(result)
this.onGoogleLoggedIn.emit(result)

})
}

OnSendOtp(){

  this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
     this.sendOtp(token)
    });
  }
async sendOtp(captcha:string){
  var phoneNumber = $('#phone-number').val()?.toString();
  let countryCode = $('#country-code').text();
  if(!this.validatephoneNumber(phoneNumber)){
    throw('Enter valid number')
  } 

   await  this.authService.PhoneNumberSendOtp(phoneNumber ,captcha).then((response)=>{
    //localStorage.setItem('OtpVerificationId',JSON.stringify( response.verificationId))
    this.onPhoneNumber.emit(phoneNumber)
    this.onOtpSend.emit(response.verificationId)
    

   }).catch((error)=>
   {
    throw(error)
   })
}
private validatephoneNumber(phoneNumber :any){
  var re = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  var result = re.test(phoneNumber)
  
  return result;
}

}
