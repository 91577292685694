import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '../loader.service';
import { finalize, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Behavior } from 'popper.js';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private totalRequests = 0;
  loader$ :BehaviorSubject<boolean|null>= new BehaviorSubject<boolean|null>(null)
  constructor(private httpService:HttpClient,private loader:LoaderService) { 
    this.loader$.subscribe((value)=>{
      if(value!=null)
            this.loader.setLoading(value)
      
      
        })
  }
  Get(Url:string,isLoader:boolean){
    if(isLoader){
      this.totalRequests++
      this.loader$.next(isLoader)
    }

    return this.httpService.get(Url,{withCredentials:true}).pipe(
      finalize(() => {
        if (isLoader) {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this.loader.setLoading(false);
          }
        }
      })
    )
  }

  Post(Url:string,data:any,headers:HttpHeaders|null,isLoader:boolean){
    if(isLoader){
      this.totalRequests++
      this.loader$.next(isLoader)
    }
if(headers==null){
  headers=new HttpHeaders()
}
    return this.httpService.post(Url,data,{headers:headers, withCredentials:true}).pipe(
      finalize(() => {
        if (isLoader) {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this.loader.setLoading(false);
          }
        }
      })
    )
  }
  Delete(Url:string,data:any,headers:HttpHeaders|null,isLoader:boolean){
    if(isLoader){
      this.totalRequests++
      this.loader$.next(isLoader)
    }
if(headers==null){
  headers=new HttpHeaders()
}
    return this.httpService.delete(Url,{headers:headers, withCredentials:true,body:data}).pipe(
      finalize(() => {
        if (isLoader) {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this.loader.setLoading(false);
          }
        }
      })
    )
  }
  
}
