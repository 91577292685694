import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-billing-commission-details',
  templateUrl: './billing-commission-details.component.html',
  styleUrls: ['./billing-commission-details.component.css']
})
export class BillingCommissionDetailsComponent implements OnInit {
billing :UntypedFormGroup
  constructor(private builder :UntypedFormBuilder) { }

  ngOnInit(): void {
    this.billing= this.builder.group({
      fullName :['',[Validators.required]],
      email : ['', [Validators.required]],
      phone : ['', [Validators.required, Validators.maxLength(10),Validators.minLength(10)]],
      upi: ['', [Validators.required]]
    })
  }

    onSubmit(){
if(this.billing.valid){

}
    }
  }


