<p-table
    [value]="dataObj"
    [lazy]="true"
    (onLazyLoad)="tableConfiguration.lazyLoad($event)"
    dataKey="Id"
    [tableStyle]="{ 'min-width': '300px', 'width':'100%' }"
    (onRowSelect)="OnRowSelect($event)"
    (onRowUnselect)="OnRowUnselect($event)"
    [paginator]="true"
    [rows]="10"
    [totalRecords]="totalRecords"
    [loading]="tableConfiguration.loading"
    responsiveLayout="stack" [breakpoint]="'960px'"
    [selectionMode]="selectionMode"
    [(selection)]="selectedItem"
    [styleClass]="'p-datatable-sm'"
    
    >
    <ng-template *ngIf="tableHeaderTemplate!=undefined" pTemplate="caption">
        <ng-container *ngTemplateOutlet="tableHeaderTemplate"></ng-container>
    </ng-template>
    <ng-template pTemplate="header">
        
        <tr>
            <th style="width: 4rem" *ngIf="isCheckBoxEnabled">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <!-- <th style="width: 4rem" *ngIf="isCheckBoxEnabled"></th> -->
            
            <th *ngFor="let header of tableConfiguration.headers" [pSortableColumn]="header.name"
            [ngClass]="header.isVisible?'visible':'invisible'"
            >{{header.displayName}}<p-sortIcon *ngIf="header.isSortable" [field]="header.name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" operator="and" [type]="header.type" *ngIf="header.isSortable && header.type=='text'" [field]="header.name" display="menu" ></p-columnFilter>
            </th>
            
        </tr>
        <!-- <tr>
            
            <th *ngFor="let header of tableConfiguration.headers"  [ngClass]="header.isVisible?'visible':'invisible'" >
            <p-columnFilter  [type]="header.type" *ngIf="header.isSortable" [field]="header.name"  ></p-columnFilter>
            </th>
        </tr> -->
    </ng-template>
    <ng-template pTemplate="body" let-data let-ri="rowIndex">
        <tr [pSelectableRow]="data">
            <td *ngIf="isCheckBoxEnabled">
                <p-tableCheckbox [value]="data"></p-tableCheckbox>
            </td>
            <td *ngFor="let item of tableConfiguration.headers" [ngClass]="item.isVisible?'visible':'invisible'">
                

                
                <div *ngIf="!item.isCustomTemplate">
                <span *ngIf="item.type!='date'" class="p-column-title">
                    {{data[item.name]}}
                </span>
                <span *ngIf="item.type == 'date'" class="p-column-title">
                    {{data[item.name] | date :'short'}}
                </span>
                <span *ngIf="item.type == 'enum'" class="p-column-title">
                {{data[item.name] | JobStatus}}
                </span>
            </div>
                <span *ngIf="item.isCustomTemplate===true && item.customTemplate" >
                <ng-container  *ngTemplateOutlet="item.customTemplate; context:{data:data,item:item}" 
                ></ng-container>
                </span>
          
            </td>
        </tr>
    </ng-template>
</p-table>