import { CanActivateFn } from '@angular/router';
import { UserStateService } from '../_common/services/user-state.service';
import { inject } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';

export const loaderGuard: CanActivateFn = (route, state) => {
  const userState = inject(UserStateService);
  if(userState.isLoading$.getValue()){
   return userState.isLoading$.pipe(
      filter(x=> x===false) ,
      map(x=>{
        return true
      }),
      take(1)
    )
  }
  else{
    return true;
  }
};
