import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { NavService } from '../Services/nav.service';
import { NavItem } from '../models/navItem';
import { UserStateService } from '../_common/services/user-state.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../snackbar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  signInButton:string
  checked:any
  timer:any
  navItems :NavItem[] 
  userEmail:any
  questionText:any
  feedbackForm:FormGroup
constructor(public authService :AuthService,private notifyService:SnackbarService,
   private navService:NavService,private userState:UserStateService, private router:Router,
   private formbuilder:FormBuilder){
    this.feedbackForm = formbuilder.group({
      Name:['',Validators.required],
      Email:['',[Validators.required,Validators.email]],
      Message:['']
    })
   }
  ngOnDestroy(): void {
    clearTimeout(this.timer)
    this.navService.RemoveAllfromNav()
  }
  ngOnInit(): void {
   this.rotatingtimout()
   this.navItems= [
    {
      event:()=>{this.router.navigate([],{fragment:'how-it-works'})},
      icon:'',
      isIconOnly:false,
      title: 'HOW DOES IT WORKS'
    },
     {
       event:()=> {this.router.navigate([],{fragment:'pricing'})},
       icon:'',
       isIconOnly:false,
       title: 'PRICING'
     },
     {
      event:()=>{this.router.navigate([],{fragment:'contact-us'})},
      icon:'',
      isIconOnly:false,
      title: 'CONTACT US'
    },
   ]
   if(this.userState.User==null){
    this.navItems.push({
      event:()=> {this.router.navigate(['performer','auth','sign-in'])},
      icon:'',
      isIconOnly:false,
      title: 'SIGN IN'
    })
   }
   this.navItems.forEach(element => {
    this.navService.AddToNav(element) 
   });
   
  }
  rotatingtimout(){
     this.timer = setTimeout(()=>{
      if($('input[name="slider"]:checked')[0].id=='item-1'){
        $("#item-2").prop("checked",true)
      }else if($('input[name="slider"]:checked')[0].id=='item-2'){
        $("#item-3").prop("checked",true)
      }
      else if($('input[name="slider"]:checked')[0].id=='item-3'){
        $("#item-1").prop("checked",true)
      }
      this.rotatingtimout();
    },2000)
  }
  submitQuestion(){
    if(this.feedbackForm.invalid){
      return
    }
    let body = {
      body:this.feedbackForm.controls.Message.value,
      replyTo:this.feedbackForm.controls.Email.value,
      name:this.feedbackForm.controls.Name.value
    }
    this.authService.SendQuestion(body).subscribe((response)=>{
      if(response){
this.notifyService.CreateNotification("Question submitted successfully.")
this.feedbackForm.reset()
      }
    })
  }
}
function OnUserSignInPressed() {
  throw new Error('Function not implemented.');
}


