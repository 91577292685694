<div class="">
    <!-- <h3 class="mb-4">Create Song Catalog</h3> -->

    <div class=" d-flex flex-column gap-3" style="background-color: var(--frame-color);">

    <div class="d-flex flex-column gap-3 mx-auto" style=" max-width: 300px;" [formGroup]="fgCatalog">
    <div class="d-flex flex-column gap-2">
        <label for="name">Catalog Name *</label>
        <input pInputText id="name"  aria-describedby="name-help" placeholder="eg. Party songs" formControlName="name"  />       
    </div>
    <div class="d-flex flex-column gap-2">
        <label for="genre">Genre</label>
        <input pInputText id="genre"  aria-describedby="genre-help" placeholder="eg. pop, hip-hop" formControlName="genre" />       
    </div>
    <div class="p-fluid d-flex flex-column gap-2">
        <div><label for="tags" >Tags</label><i class=" pi pi-info-circle mx-1 " style="color: var(--secondary-color);" pTooltip="Use these tags to easily search Catalogs. for example Dj, Rock, Pop, Best of the all, etc. This will be helpful while making events. You can create multiple tags for each catalog."></i></div>
        <p-chips [style]="{width:'100%'}" placeholder="Press enter after every tag" formControlName="tags"></p-chips>
    </div>
    <!-- <div>
        <p-fileUpload  chooseLabel="Choose" name="demo[]"  accept=".csv" [maxFileSize]="1000000" (onUpload)="onUpload($event)"></p-fileUpload>
    </div> -->

    <button matRipple class="btn primary-btn" type="submit" (click)="createCatalog()" [disabled]="fgCatalog.invalid"><span>Create</span></button>
    </div>
</div>
</div>