<!-- <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="OnTabChanged($event)"/> -->

<div class="w-100 h-100 d-flex m-2">
<div *ngIf="selectedTab==tabs[0]" class="d-flex w-100 ">
    
        <div class="d-flex w-100 flex-column gap-3">
            <div>
            <h5>Details</h5>
            <div class="event-info-group p-2" style="border-radius: var(--border-radius);"
            *ngIf="eventDetails">
                <div class="d-flex flex-column event-details w-100">
                    <div class="details-item">
                        <label class="mx-2 white" ><b>Venue</b></label>
                        <label class="fw-r d-flex ms-auto" >{{eventDetails.venue}}</label>
                    </div>
                    <div class="details-item">
                        <label class=" mx-2 white" ><b>Start Date</b></label>
                        <label class="fw-r d-flex ms-auto">{{eventDetails.startDate | date:'short'}} </label>
                    </div>
                    <div class="details-item">
                        <label class=" mx-2 white" ><b>End Date</b></label>
                        <label class="fw-r d-flex ms-auto">{{eventDetails.endDate | date:'short'}}</label>
                    </div>
                    <div class="details-item">
                        <label class="mx-2 white" ><b>Minimum Bid</b></label>
                        <label class="fw-r d-flex ms-auto">{{eventDetails.isFree? '0': eventDetails.MinBid}}</label>
                    </div>
                    <div class="details-item">
                        <label class="mx-2 white" ><b>Catalog Type</b></label>
                        <label class="fw-r d-flex ms-auto">{{eventCatalog?.name}}</label>
                    </div>   
                    <div class="details-item">
                        <label class="mx-2 white" ><b>Total Revenue Earned</b></label>
                        <label class="fw-r d-flex ms-auto">{{eventCatalog?.summary?.totalAmountPayedOut + eventCatalog?.summary?.totalCommissionEarned + eventCatalog?.summary?.totalGSTCollected}}</label>
                    </div> 
                    <div class="details-item">
                        <label class="mx-2 white" ><b>Total Deductions</b></label>
                        <label class="fw-r d-flex ms-auto">{{eventCatalog?.summary?.totalCommissionEarned + eventCatalog?.summary?.totalGSTCollected}}</label>
                    </div>                                   
                    <div class="details-item" *ngIf="allRequests!=undefined">
                        <label class="mx-2 white" ><b>Total Requests</b></label>
                        <label class="fw-r d-flex ms-auto">{{totalRequests}}</label>
                    </div>                  

                </div>
            </div>
        </div>
        <div>
            <h5>Song Requests</h5>
            <div >
                <p-dataView #dv [value]="allRequests" [rows]="10" [paginator]="true" [lazy]="true" (onLazyLoad)="LoadRequests($event)" [totalRecords]="totalRequests" [lazyLoadOnInit]="true"
                [ngClass]="allRequests==undefined || totalRequests==0?'d-none':'d-block'">
                    <ng-template pTemplate="list" let-requests>
                        <div class="grid gid-nogutter">
                            <div class="col-12 mb-2" *ngFor="let item of requests" >
                                <div style="background-color: var(--highlight-bg); border-radius: var(--border-radius); " class="p-3" >
                                    <div class="d-flex flex-row gap-3">
                                        <img class="my-auto" src="{{item.Track.thumbnail}}" style="height: 2rem; width: 2rem;"/>
                                        <div class="d-flex flex-column gap-1 flex-shrink-1">
                                            <label style="font-size: 1rem;">{{item.Track.name}}</label>
                                            <label class="fw-r" style="font-size: 0.75rem;"><i>{{item.Track.artist}}</i></label>
                                        </div>
                                        <div class="ms-auto my-auto d-flex flex-column flex-md-row gap-2">
                                            <div class="my-auto"><p-tag [value]="getFinalStatus(item.Status)" [severity]="getStatusSeverity(item.Status)" [style]="{fontSize:'0.75rem'}"> </p-tag></div>
                                            <label style="font-size: 1.25rem;" class="mx-md-0 mx-auto">₹{{item.Amount}}</label>
                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
            <p *ngIf="allRequests== undefined">All the requests will be displayed here once the event is closed.</p>
            <p *ngIf="allRequests!=undefined && allRequests.length==0">No requests received for this event.</p>
        </div>
       
    </div>
</div>
</div>