import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/Services/auth.service';
import { EventService } from 'src/app/Services/event.service';
import { NavService } from 'src/app/Services/nav.service';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { NavItem } from 'src/app/models/navItem';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { QrService } from 'src/app/Services/qr.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DialogService]
})
export class DashboardComponent implements OnInit,OnDestroy {
@ViewChild(QrCodeComponent) qrCodeComponent:QrCodeComponent
  user :any 
  UserObserver = this.authService.UserDetailsSubject.subscribe((user: any)=>
  { this.user= user})
  menuItems : any
  bottomMenuItems:any
  selectedMenu :string
 sideNavBarOpen:boolean=false
   constructor( private authService : AuthService,private eventService :EventService, private userState:UserStateService, private navService:NavService, private dialogService:DialogService,
    private qrService:QrService) {
    qrService.getQRCode().then((data)=>{
      if(data==null){
this.OpenQrDialog()
      }
    })
    this.menuItems=[{'name':'Dashboard','icon':'bi bi-grid','isActive':true},
    {'name':'Manage Events','icon':'bi bi-calendar2-event','isActive':this.userState.plan!=null},
    {'name':'Song Catalog','icon':'bi bi-file-earmark-music','isActive':this.userState.plan!=null},
    // {'name':'Earnings','icon':'bi bi-currency-rupee','isActive':this.userState.plan!=null},
    {'name':'My QR code','icon':'bi bi-qr-code','isActive':this.userState.plan!=null}]
    this.bottomMenuItems=[
      // {'name':'Billing','icon':'bi bi-receipt','isActive':false},
      {'name':'Profile','icon':'bi bi-person-circle','isActive':false},
    ]
    var QrNav:NavItem={
      icon:'pi-qrcode',
      isIconOnly:true,
      title:'QR CODE',
      event:()=>{
       this.OpenQrDialog()
      }
    }
    this.navService.AddToNav(QrNav)
   }ngOnDestroy(): void {
   this.navService.RemoveAllfromNav();
  }
OpenQrDialog(){
  this.dialogService.open(QrCodeComponent,{header:'Qr Code'})
}
  

  ngOnInit(): void {
    this.selectedMenu='Dashboard'
   
    
  }
  dashboardTabChanged(event:any){
    var element =(event.currentTarget as HTMLInputElement)
this.selectedMenu = element.id

  }
  CreateEvent(){
    this.selectedMenu='Create Event'
  }
 
  OnCreateEventSubmitCallback(isSuccess :boolean){
    this.selectedMenu="Dashboard"
  }
  OnCreateEventCanceled(data :any){
    this.selectedMenu="Dashboard";
  }
}
