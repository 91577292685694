import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, observable, of, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService } from './Services/auth.service';
import { LoaderService } from './loader.service';
import { catchError, finalize } from 'rxjs/operators';
import { UserStateService } from './_common/services/user-state.service';
import { HttpError } from '@microsoft/signalr';
import { StateService } from './Services/state.service';
import { AuthConstants } from './common/Contants/Contants';

@Injectable()

export class ApiInterceptor implements HttpInterceptor {
  errorCounter:number=0
 constructor(private userState : UserStateService, private loader : LoaderService, private state:StateService) {

  }
 intercept(
   req: HttpRequest<any>,
   next: HttpHandler
 ): Observable<HttpEvent<any>> {
  // this.totalRequests++;
  // this.loader.setLoading(true);
   // Get the request url
   let requestUrl = req.url;
   // if the request URL have the string prefix, 
   // then make the replace by the correct url 
   if (requestUrl.indexOf('@api-dev') !== -1) {
     requestUrl = requestUrl.replace('@api-dev', environment.api);
   }
   var token = this.state.GetItem(AuthConstants.ACCESS_TOKEN)
   var headers = req.headers;
  //  if(token){
  //   headers  = headers.append('Authorization','bearer '+token)
  //  }
// clone the http request
req = req.clone({
  url: requestUrl,
  headers:headers,
  withCredentials:true
});
   return next.handle(req).pipe(catchError((err)=>{
       return this.handleError(err)
   }),finalize(()=>{}))
 }
 handleError(Error:HttpErrorResponse):Observable<any>{
  if(Error!=null && Error.status==401 && this.errorCounter!=1){
    this.errorCounter++
    this.userState.refreshToken().then((x)=>{
        this.errorCounter=0
          return of("Please try again")
        
    }).catch(()=>{})
    return of("Refreshing Session")
  }
  else{
      this.errorCounter=0
      return throwError(Error)
  }
 }
}