import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadingComponent implements OnInit {

  options: AnimationOptions = {
    path : '../../assets/Dj-loader.json'
  }
  constructor(public loader :LoaderService) { }

  ngOnInit(): void {
  }

}
