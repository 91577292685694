import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { signOut } from 'firebase/auth';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AuthService } from 'src/app/Services/auth.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaymentsService } from 'src/app/Services/payments.service';
import { StateService } from 'src/app/Services/state.service';
import { AuthConstants } from 'src/app/common/Contants/Contants';
import { LoaderService } from 'src/app/loader.service';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  currentUser$:BehaviorSubject<any>=new BehaviorSubject<any>(null)
  public claims: any
  accessToken$:BehaviorSubject<string> = new BehaviorSubject<string>('')
  refreshToken$:BehaviorSubject<string> = new BehaviorSubject<string>('')
  isNewUser:boolean=false;
  userDetails:any
  plan:any
 UpdateUserDetailsSubject :Subject<any> = new Subject<any>()
  constructor(private authService: AuthService, private loader: LoaderService,private paymentService:PaymentsService, private state:StateService, private router:Router, private zone:NgZone
    ,private http:HttpService) {

    this.UpdateUserDetailsSubject.subscribe(()=>{
      zone.run(()=>{
        this.isLoading$.next(true)
        this.authService.getUser().subscribe((userDetails:any)=>{
          this.userDetails=userDetails
          this.plan = userDetails.planName
          this.currentUser$.next(userDetails)
          this.claims =userDetails.claims
          this.disableLoader()
        },(error)=>{this.disableLoader()})
      })


    })
    // this.accessToken$.subscribe((token)=>{
    //   if(token){
    //     this.claims = JSON.parse(atob(token.split('.')[1]))
    //   }
    // })

  }
  hasClaim(claim: string, value: string): boolean | null {
    if (this.claims != null) {
      return this.claims[claim] == value
    }
    return null
  }
  // public AuthStateUnSub = this.authService.auth.onAuthStateChanged(user => {
  //   if (user != null) {   
  //     user.getIdTokenResult().then(result => {
  //       this.claims = result.claims
  //       this.accessToken$.next(result.token)
  //       this.currentUser$.next(user) 
  //       this.disableLoader()
  //     })
  //   }
  //   else {
  //     this.signOutActivities()
  //     this.disableLoader()
  //   }


  // })

  disableLoader() {
      this.isLoading$.next(false)
      this.loader.setLoading(false)
    
  }
  saveUserState(response: any) {
this.currentUser$.next(response.user)
this.accessToken$.next(response.accessToken)
this.refreshToken$.next(response.refreshToken)
this.isNewUser=response.isNew
this.UpdateUserDetailsSubject.next(null)
if(response.user.claims.role=='admin'){
  this.state.SetItem(AuthConstants.ACCESS_TOKEN,response.accessToken);
}
}
refreshToken(){
  this.isLoading$.next(true)
  this.loader.setLoading(true)
 return new Promise<boolean>((resolve,rejects)=>{
  this.authService.refreshToken().subscribe((response:any)=>{
    this.saveUserState(response)

resolve(true)
  },(err)=>{
    if(err.status==401){
      this.SignOut()
    }
    this.disableLoader()
      rejects(err)
  })
 })
 
}

SignOut() {
  this.authService.signOut().subscribe(() => {
    this.signOutActivities()
    this.router.navigate(['']);
  })
}
private signOutActivities(){
this.currentUser$.next(null)
this.accessToken$.next('')
this.refreshToken$.next('')
this.claims=null
this.plan=null
this.userDetails=null
this.state.DestroyAlItems()
}
private UserDetails$ = this.currentUser$.subscribe(user=>{
  if(user!=null){
    this.state.SetItem(AuthConstants.USER_PROFILE,user)
  }
  
})

public get User(){
  return this.state.GetItem(AuthConstants.USER_PROFILE);
}
UpdateUserProfile(form :any){
 return this.http.Post("@api-dev/User/UpdateUserProfile",form,null,true);
}
}
