<div class="container-md d-flex flex-column px-3 gap-5">
<div class="row w-2"></div>
<div class="d-flex flex-column flex-md-row justify-content-start my-3 gap-5">
            <div class="corosal-container mx-auto mx-md-none">
                <input type="radio" name="slider" id="item-1" checked>
                <input type="radio" name="slider" id="item-2">
                <input type="radio" name="slider" id="item-3">
              <div class="cards">
                <label class="card" for="item-1" id="song-1">
                  <img src="../../assets/artist1.jpg" alt="song">
                </label>
                <label class="card" for="item-2" id="song-2">
                  <img src="../../assets/artist2.jpg" alt="song">
                </label>
                <label class="card" for="item-3" id="song-3">
                  <img src="../../assets/artist3.jpg" alt="song">
                </label>
               
              </div>
              
            </div>
            <div class="d-flex flex-column justify-content-center flex-shrink-1">
            <h1 class="text-center text-md-start" style="font-size: 3rem;">
                A smart way to accept song requests
            </h1>
            <p class="w-100 my-2 fw-r text-center text-md-start">
                Jumr allows to send a song request straight from your phone to the performer
            </p>

        </div>
            <!-- <div class="d-flex flex-row"  style="max-height: 800px;">
               
               <div class="d-flex flex-column w-100 mx-auto home-stage" style="max-width: 1000px;">
   
               
               
              
               
           </div>
            </div>   -->
       
        </div>
        <div class="d-flex flex-row flex-wrap mx-auto p-1  justify-content-around">
            <button matRipple class="btn primary-btn m-2 px-5" style="width: fit-content;" [routerLink]="['party']"><span>Request a song</span></button>
            <div matRipple class="secondary-btn m-2 " style="width: fit-content; "  *ngIf="this.authService.userData==null"><button class=" px-5" [routerLink]="['performer','auth','sign-in']">Log on as Performer</button></div>
            <div matRipple class="secondary-btn m-2 " style="width: fit-content; " *ngIf="this.authService.userData!=null" ><button class=" px-5" [routerLink]="['performer','auth','sign-in']">Go to Dashboard</button></div>

        </div>
<section id="how-it-works">
    <div class="d-flex flex-column gap-4">
        <h3 class="text-center text-md-start">How it works</h3>
        <div class="d-flex flex-row ">
        <div class=" d-flex flex-row flex-wrap gap-3">
            
            <div class=" base-working-frame working-frame">
                <i class="bi bi-search white" style="font-size: 1.5rem;"></i>
                <div>
                <h5>Search</h5>
                <label class="fw-r">Search a song you want to request</label>
            </div>
        </div>
            
            <div class="base-working-frame working-frame">
                <i class="bi bi-currency-rupee white"  style="font-size: 1.5rem;"></i>
                <div>
                <h5>Pay</h5>
                <label class="fw-r">Pay with UPI or any other mode</label>
            </div>
            </div>
       
            <div class="base-working-frame working-frame">
                <i class="bi bi-music-note white"  style="font-size: 1.5rem;"></i>
                <div>
                <h5>Request</h5>
                <label class="fw-r">Send the DJ your song request</label>
            </div>
            </div>
       
            <div class="base-working-frame working-frame">
                <i class="bi bi-check-lg white"  style="font-size: 1.5rem;"></i>
                <div>
                <h5>Approved</h5>
                <label class="fw-r">Your request gets approved</label>
            </div>
            </div>
      
            <div class="base-working-frame working-frame">
                <i class="bi bi-play white"  style="font-size: 1.5rem;"></i>
                <div>
                <h5>Play</h5>
                <label class="fw-r">Your song gets played by the DJ</label>
            </div>
            </div>
       
            <div class="base-working-frame working-frame">
                <i class="bi bi-speaker white"  style="font-size: 1.5rem;"></i>
                <div>
                <h5>Listen</h5>
                <label class="fw-r">Listen to your song request live</label>
            </div>
            </div>
            <div class="placeholder-working-frame"></div>
            <div class="placeholder-working-frame"></div>
            <div class="placeholder-working-frame"></div>
            <div class="placeholder-working-frame"></div>
        
        </div>
    </div>
    </div>
</section>
<section id="pricing">
    <h3 class="text-center text-md-start mb-3">Pricing</h3>
    <div class="d-flex flex-row">
        <div class="base-working-frame mx-auto" style="max-width: 30rem;">
            
            <div class="d-flex flex-column w-100 p-4 my-2">
                <p>Starter Plan</p>
                <div class="d-flex flex-row mb-2">
                    <h1 class="d-flex flex-column justify-content-end m-0" style="vertical-align: bottom; line-height: 40px; ">15%</h1>
                    <span class="helper-span mx-1 d-flex flex-column justify-content-end">/event earning</span>
                </div>
                <p style="color: var(--helper-text-color) !important;">All the basic features to boost your income.</p>
                <div class="separator"></div>
                <div class="my-4">
                    <ul class="m-0 p-0">
                        <li class="d-flex flex-row">
                            <i class="bi bi-check-circle-fill  my-auto" style="font-size: 1rem;"></i>
                            <p class="p-0 mx-2 my-1">No upfront recurring cost</p>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="bi bi-check-circle-fill white my-auto" style="font-size: 1rem;"></i>
                            <p class="p-0 mx-2 my-1">Get QR code</p>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="bi bi-check-circle-fill white my-auto" style="font-size: 1rem;"></i>
                            <p class="p-0 mx-2 my-1">Share code with your customers</p>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="bi bi-check-circle-fill white my-auto" style="font-size: 1rem;"></i>
                            <p class="p-0 mx-2 my-1">Set base bid </p>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="bi bi-check-circle-fill white my-auto" style="font-size: 1rem;"></i>
                            <p class="p-0 mx-2 my-1">accept shoutout requests</p>
                        </li>

                    </ul>
                    <p-button label="Get Started"
            
              [style]="{'border-radius':'10px'}" [outlined]="true" [class]="'d-flex'"
              styleClass='d-flex mt-2' [routerLink]="['/performer/auth/sign-in']"></p-button>
                </div>

                </div>


        </div>
    </div>
</section>

<section id="contact-us">
    <h3 class="text-center text-md-start">Got questions</h3>
        <p class="fw-r text-center text-md-start">write to us</p>
    <div class="d-flex flex-row justify-content-around flex-wrap">

    
    <div class="d-flex flex-column flex-fill  gap-2" [formGroup]="feedbackForm">
        
        
            <input pInputText placeholder="Email address" type="email" formControlName="Email" 
            [ngClass]="feedbackForm.controls.Email.invalid && (feedbackForm.controls.Email.dirty || feedbackForm.controls.Email.touched)?'ng-invalid ng-dirty':''" />
            <div
  *ngIf="feedbackForm.controls.Email.invalid && (feedbackForm.controls.Email.dirty || feedbackForm.controls.Email.touched)"
  class="" style="color: red;"
>
  <div *ngIf="feedbackForm.controls.Email.errors?.['required']">Email is required.</div>
  <div *ngIf="feedbackForm.controls.Email.errors?.['email']">
    Email is not valid.
  </div>
</div>
            <input pInputText placeholder="Name" type="text" formControlName="Name" 
            [ngClass]="feedbackForm.controls.Name.invalid && (feedbackForm.controls.Name.dirty || feedbackForm.controls.Name.touched)?'ng-invalid ng-dirty':''" />
            <div
  *ngIf="feedbackForm.controls.Name.invalid && (feedbackForm.controls.Name.dirty || feedbackForm.controls.Name.touched)"
  class="" style="color: red;"
>
  <div *ngIf="feedbackForm.controls.Name.errors?.['required']">Name is required.</div>

</div>
            <textarea rows="5" class="w-100 mb-3" formControlName="Message"  pInputTextarea placeholder="write your query here and we will get back to you" ></textarea>
            <p-button label="Submit"
            [disabled]="feedbackForm.invalid"
             (onClick)="submitQuestion()" icon="pi pi-check" [style]="{'border-radius':'10px'}" [outlined]="true"></p-button>
    </div>
    <div class="flex-fill">
    <img src="../../assets/031-Rock-Star.svg" class="d-flex  mx-auto" style="max-height: 20rem; max-width: 20rem; min-height: 10rem; min-width: 10rem;" alt="">
</div>
</div>
</section>
<div class="row w-2"></div>
    </div>
<app-footer></app-footer>