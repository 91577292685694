import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentsService } from 'src/app/Services/payments.service';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { PlanDetailsConstant } from 'src/app/common/Contants/Contants';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],

})
export class CheckoutComponent implements OnInit {
  accountDetails: UntypedFormGroup
  isUPIVerified: any
  isVerifyPressed: boolean = false
  isSubmitted:boolean = false
  planName:number
  planDetails:any
  nameAtBank:string
 
  constructor(private formBuilder: UntypedFormBuilder, private paymentService : PaymentsService, private router :Router, private route : ActivatedRoute,private userState:UserStateService) {
route.queryParams.subscribe((params)=>{
if(params.plan!=undefined && params.plan==0){
  this.planName= Number(params.plan);
}
else{
  router.navigate(['performer/dashboard'])
}
})
   }

  ngOnInit(): void {
if(this.planName!=undefined){
this.planDetails = PlanDetailsConstant.find(x=>x.PLanId==this.planName)
}
    this.accountDetails = this.formBuilder.group({
      UPI: ['',[Validators.required, Validators.pattern('[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}')]],
      BankAccount: this.formBuilder.group({
        Name: ['',[Validators.required]],
        AccountNumber: ['',[Validators.required]],
        IFSCCode: ['',[Validators.required]]
      }),
      BillingAddress:['',Validators.required],
      Plan:[this.planName]
    })
  }
 
  ValidateUPI(control : AbstractControl){
    if(control.valid){
    if(this.isUPIVerified){
return null
    }}
    return {'error':'UPI unverified'}
  }
  // ValidateUPIorBank(control: AbstractControl){
  //   if(this.accountDetails.controls['UPI'].valid || this.accountDetails.controls['BankAccount'].valid){
  //     return null;
  //   }
  //   return {'error':'Adding any one payment method is compulsory'}

  // }
  
  verifyUPI() {
    this.isVerifyPressed = true
this.paymentService.VerifyUpi(this.accountDetails.controls['UPI'].value).subscribe((result:any)=>{
if(result){
  this.isUPIVerified=result.accountExists
  this.nameAtBank = result.nameAtBank
}})
  }
  Submit(){
this.isSubmitted=true
if(this.validateRequest()){
  var accountDetailsObj= this.accountDetails.value
  if(this.planName==0){
  this.paymentService.RegisterPerformerStarterPack(accountDetailsObj).subscribe((response)=>{
    if(response!=null){
      this.userState.UpdateUserDetailsSubject.next(null)
      this.router.navigate(['performer/dashboard'])
    }
  });
}
}
if(this.planName==1){
  this.paymentService.RegisterSubscription(accountDetailsObj).subscribe((result :any)=>
  {
    if(result!=null){
      localStorage.setItem('subId', result.subReferenceId)
      window.open(result.authLink,'_blank');
      this.router.navigate(['performer/SubscriptionReturn'])
    }
  })
}
  this.isSubmitted=false


  }
  validateRequest(){
    if(this.accountDetails.controls['BillingAddress'].invalid){
      return false;
    }
    if((this.accountDetails.controls['UPI'].invalid || !this.isUPIVerified) && this.accountDetails.controls['BankAccount'].invalid){
      return false;
    }
    if(this.accountDetails.controls['UPI'].valid && !this.isUPIVerified){
      return false
    }
    return true;
  }
  upiTextBoxChanged(){
    if(this.isUPIVerified){
      this.isVerifyPressed=false
      this.isUPIVerified = false
      this.nameAtBank = ''
    }
  }
}
