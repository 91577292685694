import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { PayRequestModel } from '../models/payRequestModel';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
declare let Cashfree: any;
declare let EasebuzzCheckout: any;
@Injectable({
  providedIn: 'root'
})
export class PartyService {

  constructor(private http: HttpService, private zone: NgZone, private router : Router) { }
  GetPerformerById(performerId: string) {
    return this.http.Get('@api-dev/party/getperformerbyid?performerId=' + performerId,true);
  }
  GetPayOrderById(ids:string[]){
    return this.http.Get(`@api-dev/party/getPayOrder?orderIds=${ids.toString()}`,false)
  }
  GetPayOrderDetails(id:string){
    return this.http.Get(`@api-dev/party/GetPayOrderDetails?orderId=${id}`,true);
  }
  getAllPendingRequestStatus(){
    return this.http.Get('@api-dev/party/getAllPendingRequestStatus',false)
  }
  GetAllPayOrders(){
    return this.http.Get('@api-dev/party/GetAllPayOrders',true)
  }
  CreatePayOrder(model: PayRequestModel) {
    this.http.Post('@api-dev/party/CreatePayOrder', model,null,true).subscribe((response: any) => {
      if (response.mode == "Cashfree") {
        const cf = new Cashfree(response.orderSessionId);
        cf.redirect();
      }
      else if (response.mode == "Easebuzz") {
        if (response.isIframe=="true") {
          const ebCheckout = new EasebuzzCheckout(environment.eb.mId, environment.eb.mode);
          var options = {
            access_key: response.orderSessionId,
            onResponse: (response: any) => {
              this.router.navigate(['party','ProcessResponse'],{queryParams:{status:response.status,order_id:response.txnid}})
            }
          }
          this.zone.run(()=>{
          ebCheckout.initiatePayment(options);})

        }
        
        else{
          this.zone.run(()=>{
          window.location.href=response.orderSessionId
          })
        }
      }
      else if(response.mode=="free"){
        this.router.navigate(['party','ProcessResponse'],{queryParams:{status:response.status}})
      }
    })
  }
}
