<div class="spotify-track d-flex flex-row p-2 mb-1 w-100">
                   
    <img class="spotify-track-art" *ngIf="!isCustomTrack" src="{{track.artwork}}">
    <i class=" bi bi-file-music-fill white " style="font-size: 3em;" *ngIf="isCustomTrack"> </i>
    <div class="d-table-content px-4 flex-grow-1">
        <div class="white" style="overflow-wrap: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;">{{track.name}}</div>
        <i style="font-size: 0.75rem;" class="white">{{track.artist}}</i>

    </div>
</div>