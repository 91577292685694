import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../Services/auth.service';
import { UserStateService } from '../_common/services/user-state.service';
import { filter, first, map, take } from 'rxjs/operators';
import { SnackbarService } from '../snackbar.service';



@Injectable({
  providedIn: 'root'
})
export class PerformerAuthGuard {
  constructor(private userState: UserStateService, private router: Router, private notificationService:SnackbarService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree | undefined | Promise<boolean>> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userState.isLoading$.getValue()) {
      return this.validateLogon(state)
    }
    return this.userState.isLoading$.pipe(
      filter(x=> x===false) ,
      map(x=> {if(x===false){
        return this.validateLogon(state)
      }
    return false}),
      take(1)
    )
    

  }
  validateLogon(state: any) {
    let isperformer = this.userState.hasClaim('role', 'performer');
   let isLoggedIn = this.userState.currentUser$.getValue()!=null
   if(!isLoggedIn){
    return false
   }
if(isperformer==null){
  isperformer=false
}

  if(!isperformer){
    if(state.url.includes('performer/onboarding'))
    return true;
  else if(this.userState.hasClaim('role', 'user')){
    return this.router.navigate(['change-role'])
  }
  else{
    this.userState.SignOut()
    this.notificationService.CreateNotification("You are not a performer.")
    return false
  }

  }
  return true


}
}
