<div class="m-3">
    <section class="m-auto " style="max-width: 1200px;">
        
        <h1>About Us</h1>
        <div class="d-flex flex-row flex-wrap w-100" >
        <div class="" style="max-width: 60%; margin-right: 30px;">


<hr/>

<p>Welcome to Jumr, the ultimate platform dedicated to bridging the gap between talented live performing artists and their enthusiastic audience. We are a passionate team of music lovers and creators who understand the value of supporting local artists and making music a more interactive and engaging experience for everyone.</p>
<hr>
<h3>Our Mission</h3>
<p>
At Jumr, our mission is clear: we aim to empower live performing artists, whether they're DJs, singers, musicians, or any other type of musical entertainer, to thrive in their craft while providing a unique and exciting way for their fans to connect with them. We believe that music has the power to transcend boundaries, bring people together, and uplift spirits. By facilitating song requests in exchange for contributions, we're not only helping artists earn extra income, but we're also enhancing the overall live music experience.
</p>
<hr>
<h3>Supporting Local Talent</h3>
<p>
    We're deeply committed to promoting local artists on a global scale. We understand the challenges that independent artists face in gaining visibility and recognition. Through our platform, artists can showcase their talent, connect with a broader audience, and receive the appreciation they truly deserve. By enabling fans to request songs and support their favorite performers, we're fostering a stronger sense of community and enabling artists to continue doing what they love most. 
</p>
<hr>
<h3>How It Works</h3>
<p>Our platform is designed to be user-friendly and intuitive. Artists can create their profiles and set their own terms for song requests. Fans, on the other hand, can explore a diverse range of artists, discover new talents, and make personalized song requests for a small contribution. Whether it's a birthday celebration, a special event, or just a night out with friends, fans can now make their moments even more memorable by directly engaging with their preferred artists.</p>
<hr>
</div>

<div class="" style="width: auto;">
<h2>Contact Us</h2>
<hr>
<p>Tvox Studio</p>
<p>support&#64;jumr.in</p>
<p>tvoxmusic&#64;gmail.com</p>
<p>+91 8054829209</p>
<p>2373, sector 32A, chandigarh road, Ludhiana - 141010</p>
</div>
</div>
</section>
</div>