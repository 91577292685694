<div class="d-flex d-row justify-content-between flex-wrap" style="min-height:120px;gap: 20px;">
    <div class="widget p-4" style="min-width: 200px;">
        <span style="color: white; height: auto; font-size: 0.75rem;" class="fw-r ">Active plan</span>
        <div class="h-50 d-flex px-4 " >
            <h4 class="m-auto">{{this.userState.plan?this.userState.plan:''}}</h4>
        </div>
    </div>
    <div class="widget p-4" style="min-width: 200px;">
        <span style="color: white; height: auto; font-size: 0.75rem;" class="fw-r">Total Catalogs</span>
        <div class="h-50 d-flex px-4" >
            <h4 class="m-auto">{{this.totalCatalogs || 0}}</h4>
        </div>
    </div>
    <div class="widget p-4 flex-lg-wrap" style="min-width: 200px;">
        <span style="color: white; height: auto;; font-size: 0.75rem;" class="fw-r">Total Revenue</span>
        <div class="h-50 d-flex px-4" >
            <h4 class="m-auto">{{Constants.RUPEES_SIGN}}{{this.totalEarnings || 0}} </h4>
        </div>
    </div>
    <div class="widget p-4 flex-lg-wrap" style="min-width: 200px;; font-size: 0.75rem;">
        <span style="color: white; height: auto;" class="fw-r">Total Events</span>
        <div class="h-50 d-flex px-4" >
            <h4 class="m-auto">
              {{this.totalEvents || 0}} </h4>
        </div>
    </div>

</div>
<div class="bg-frame w-100 my-3">
<div class="p-3">
<h4>Todays active events</h4>
<div class="d-flex flex-row w-100 p-2 mb-2" style="background-color: var(--element-color);" *ngFor="let item of todayEvents">
<p class="m-0">{{item.name}} starting at {{item.startDate | date :'shortTime'}}</p>
<div style="margin-left: auto;" class="d-flex flex-row gap-3 my-auto" >
<p-button label="Share"  icon="bi bi-link" [outlined]="true" [link]="true" styleClass=""  [style]="{borderRadius:'var(--border-radius)', padding:'5px', color:'var(--secondary-color)', textDecoration:'underline'}" (onClick)="shareLink(item)"></p-button>
<div (click)="goToConsole(item)" class="d-inline-block my-auto" style="margin-left: auto; cursor: pointer ; color:var(--secondary-color);">
    
<u href="#" class="d-none d-md-inline-block" >Go to live console</u> <i
    class="pi pi-external-link px-2 my-auto" style="color:var(--secondary-color);"></i>
</div>
</div>
</div>
</div>
</div>