import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserStateService } from '../../services/user-state.service';
import { CreateUserModel } from 'src/app/models/createUserModel';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-basic-customer-details',
  templateUrl: './basic-customer-details.component.html',
  styleUrls: ['./basic-customer-details.component.css']
})
export class BasicCustomerDetailsComponent implements OnInit {
  userDetails:FormGroup
  @Output() onUserOnboarded = new EventEmitter<any>();
user:any
  constructor(private formBuilder:FormBuilder, private userState:UserStateService, private authService:AuthService){}
  ngOnInit(): void {
    this.user = this.userState.currentUser$.getValue()
    if(this.user!=null){
      var phoneNumber;
      if(this.user.phoneNumber!=null)
      phoneNumber = this.user.phoneNumber?.indexOf('+91')!=-1?this.user.phoneNumber.substring(3):this.user.phoneNumber;
    
      this.userDetails = this.formBuilder.group({name :[this.user.name, Validators.required],
        emailAddress: new FormControl({value:this.user.emailId, disabled:this.user.emailId!=''},Validators.required) ,
        phoneNumber: new FormControl({value:phoneNumber, disabled:this.user.phoneNumber!=""},Validators.required),
      countryCode: ['+91', Validators.required]}
    
        )
    }
    
  }
  onSubmit(){

    if(this.userDetails.valid){
      var model :CreateUserModel = {
        emailAddress :this.userDetails.controls['emailAddress'].value,
        name : this.userDetails.controls['name'].value,
        authProvider : this.user.authProvider,
        countryCode : this.userDetails.controls['countryCode'].value,
        fuID : this.user.id,
        role: 'user',
        phoneNumber: this.userDetails.controls['phoneNumber'].value,
        profilePicture: this.user.profilePicture
      }
      
        this.authService.CreateUserV2(model).subscribe((result)=>{
          this.userState.refreshToken()
          this.onUserOnboarded.emit(true)
    
    })
  }else{
    this.onUserOnboarded.emit(false)
  }

  }

}
