import { Injectable } from '@angular/core';
import { NavItem } from '../models/navItem';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavService {
navList$= new BehaviorSubject<NavItem[]>([])

  constructor() { 

  }
  add = (navItem:NavItem)=>{
    this.navList$.pipe(take(1)).subscribe((current:NavItem[])=>{
      if(current.findIndex(i=>i.title==navItem.title)<0){
        current.push(navItem)
        this.navList$.next(current)
      }
    })
  }
  AddToNav(item:NavItem){
    var newArray = this.navList$.value
    newArray.push(item)
    this.navList$.next(newArray);

  }
  RemoveAllfromNav(){
    this.navList$.next([])
  }
}
