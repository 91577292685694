import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent {
  menuItems:any
  menuItemConstants={
    Dashboard:'Dashboard',
    Users:'Users',
    Jobs:'Jobs',
    Events:'Events'
  }
  selectedNav=this.menuItemConstants.Jobs
  constructor(){
    this.menuItems=[{name:this.menuItemConstants.Dashboard,icon:'bi bi-grid',isActive:true},
    {name:this.menuItemConstants.Users,icon:'bi bi-people',isActive:true},
    {name:this.menuItemConstants.Jobs,icon:'bi bi-collection', isActive:true},
    {name:this.menuItemConstants.Events ,icon:'bi bi-collection', isActive:true},
    {}

  ]
  }
  navClicked(name:any){
    this.selectedNav= name
  }
}
