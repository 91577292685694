<app-loading style="width: inherit;"></app-loading>
<!-- <div class="d-flex flex-row">
<div class="w-100 p-4"><img alt="logo" class="mx-3 mx-md-auto" src="../assets/icons/Jukr.png"></div>
<div class="collapse navbar-collapse" id="navbar-links">
    <div class="navbar-nav">
        <a class="nav-item nav-link" href="#">Features</a>
</div>
</div>

</div> -->
<!-- <img alt="logo" class="" src="../assets/icons/logo.png"> -->

<div id="home">
  <nav class="navbar sticky-top navbar-expand-lg" style="height: 80px; background-color: var(--background-color);">
    <div class="d-flex flex-grow-1">
      
    <a class="navbar-brand mx-3 " href=""><h1 style="font-family: pacifico; font-size: 2rem;">Jumr</h1></a>
      
    <div class="d-flex flex-row flex-grow-1 w-100">
    <div class="justify-content-end d-none d-lg-flex align-items-center flex-grow-1" style="flex-basis: auto; " id="navbarNavDropdown">
      <ul class=" mx-1 mx-lg-3 my-auto" style="list-style: none;">
        <li class="p-0 mx-3 d-md-inline-block" *ngFor="let navItem of this.navService.navList$ | async">
          <i *ngIf="navItem.isIconOnly" class="pi header-icon" [ngClass]="navItem.icon" style="font-size: 1rem;cursor: pointer;" (click)="triggerNavItem(navItem)"></i>
          <a *ngIf="!navItem.isIconOnly" style="cursor: pointer;" class="nav-link" (click)="navItem.event()">{{navItem.title}}</a>
        </li>
      </ul>
        </div>
        <div class="d-flex flex-basis-auto ms-auto justify-content-end p-2 h-100" >
        <app-user-widget style="height: 100%; display: block;;"></app-user-widget>
      </div>
    </div>
      <!-- <button class="navbar-toggler justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <i class="bi bi-list white"></i>
      </button> -->
      <div class="d-lg-none mx-2 d-flex">
        <i  class="bi bi-list white d-flex my-auto header-icon" (click)="sidebarVisible=true" style="font-size: 2rem;cursor: pointer;" ></i>
      </div>
    </div> 
  </nav>
    <router-outlet ></router-outlet>
</div>
<p-sidebar #sidebarRef [(visible)]="sidebarVisible" position="right">
  <ng-template pTemplate="headless">
    <span>
      <p-button type="button"  icon="pi pi-times" [text]="true" (onClick)="sidebarVisible=false" [rounded]="true" styleClass="h-2rem w-2rem"></p-button>
  </span>
  <ul class=" mx-1 mx-lg-3" style="list-style: none;">
    <!-- <li class="p-0 mx-3 d-block">
      <div class="d-flex mx-2 my-3">
        <a style="cursor: pointer;" class="nav-link" (click)="sidebarVisible=false; returnToHome()" >HOME</a>
      </div>

    </li> -->
    <li class="p-0 mx-3 d-block" *ngFor="let navItem of this.navService.navList$ | async">
      <div class="d-flex mx-2 my-3">
      <a  style="cursor: pointer;" class="nav-link" (click)="sidebarVisible=false; navItem.event()">{{navItem.title}}</a>
    </div>
    </li>
  </ul>
    </ng-template>
    </p-sidebar>

   



