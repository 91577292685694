import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { EventService } from 'src/app/Services/event.service';
import { BaseFilterQuery, Constants } from 'src/app/common/Contants/Contants';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';
import { TableHeaderItem } from 'src/app/models/TableHeaderItem';
import { EventHistory } from 'src/app/models/eventDetailsModel';
import { ViewEventComponent } from '../event/view-event/view-event.component';


@Component({
  selector: 'app-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.css']
})
export class EventHistoryComponent implements OnInit,AfterViewInit {
  @ViewChild('status') statusTemplate:TemplateRef<any> 
eventsList :EventHistory[]
stateOptions: any[] = [
  { label: 'Inactive', value: false },
  { label: 'Active', value: true }
];

eventListClone: { [s: string]: EventHistory } = {};
selectedEvent:any[]=[]
isEventEdit:boolean=false
GetAllEvents:Subject<any>=new Subject<any>()
isLoading = false
totalEvents=0
headers :TableHeaderItem[] =[]
tableConfiguration:TableConfigurationModel ={
  headers:this.headers,
  id:'id',
  lazyLoad:(event:any)=> this.loadData(event),
  loading:this.isLoading,
  onRowSelect:(event:any)=> this.OnCheckboxChanged(event),
  onRowDeselect:(event:any)=> this.OnCheckboxUnchecked(event)
}

  constructor(private eventService :EventService, private dialogService:DialogService) {
    this.GetAllEvents.subscribe(()=>{
      this.loadData()
    })
    
   }
  ngAfterViewInit(): void {
    
    this.headers= [
      {
        displayName:'Name',
        isVisible:true,
        name:'Name',
        type:'text',
        isCustomTemplate:false,
        isSortable:true
      },
      {
        displayName:'Venue',
        isVisible:true,
        name:'Venue',
        type:'text',
        isCustomTemplate:false,
        isSortable:true
      },
      {
        displayName:'Start Date',
        isVisible:true,
        name:'StartDate',
        type:'date',
        isCustomTemplate:false,
        isSortable:true
      },
      {
        displayName:'End Date',
        isVisible:true,
        name:'EndDate',
        type:'date',
        isCustomTemplate:false,
        isSortable:true
      },{
        displayName:'Details',
        isVisible:true,
        name:'Status',
        type:'text',
        isCustomTemplate:true,
        isSortable:false,
        customTemplate:this.statusTemplate
      }
    ]
    this.tableConfiguration={
      headers:this.headers,
      id:'id',
      lazyLoad:(event:any)=> this.loadData(event),
      loading:this.isLoading,
      onRowSelect:(event:any)=> this.OnCheckboxChanged(event),
      onRowDeselect:(event:any)=> this.OnCheckboxUnchecked(event)
    }
  }

  ngOnInit(): void {
    // this.GetAllEvents.next(null)
   
  }
  OnCheckboxChanged(event:any){
    this.selectedEvent.push(event.data)
  }
  OnCheckboxUnchecked(event:any){
    this.selectedEvent.splice(this.selectedEvent.findIndex(x=>x==event.data),1)
  }
  EditClicked(){
    this.eventService.EditEventSubject.next(this.selectedEvent[0])
    this.isEventEdit=true
  }
  DeleteClicked(){
    if(new Date(this.selectedEvent[0].EndDate)<=new Date()){
      window.alert("You cannot delete elapsed event as it will be processed/ is being processed for payout");
      return
    }
    let isConfirmed= window.confirm("Are you sure you want to delete event: "+ this.selectedEvent[0].Name)
    if(isConfirmed){
      this.eventService.DeleteEvent(this.selectedEvent[0].Id).subscribe((isSuccess)=>{
        if(isSuccess){
          this.GetAllEvents.next(null)
          this.selectedEvent=[]
        }
      })
    }
  }
  OnToggleEvent(event:any,index:number){
    if(this.eventListClone[event.id]==null){
      this.eventListClone[event.id as string] = { ...event };
      this.eventListClone[event.id as string].isActive=!event.isActive
    }
    

    event.isDirty=true
this.eventsList[index]=event

  }
  OnEventSave(event:any,index:number){
    
    this.eventService.UpdateEvent(event).subscribe((response)=>{
      if(response){
        event.isDirty=false
        this.eventsList[index]=event
      }
    })

  }
  OnEventCancel(event:any,index:number){
event.isDirty=false
this.eventsList[index]=this.eventListClone[event.id as string]
this.eventsList[index].isDirty=false
delete this.eventListClone[event.id as string]
  }
  editComplete(){
    this.isEventEdit=false;
    this.GetAllEvents.next(null)
    this.selectedEvent=[]
  }
  loadData(event?:LazyLoadEvent){
    let filter = BaseFilterQuery
    if(event){
      filter = JSON.stringify(event)    }

      this.eventService.FilterAllEvents(filter).subscribe((events:any)=>{
        
          this.eventsList = events.data
          this.totalEvents = events.totalCount
        
      })
  }
  OpenEventDetails(event:any){
    this.dialogService.open(ViewEventComponent,{header:event.Name, width:'80vw',height:'80vh',data:event})
  }
}
