import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { PaymentsService } from 'src/app/Services/payments.service';
import { StateService } from 'src/app/Services/state.service';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { AuthConstants } from 'src/app/common/Contants/Contants';

@Component({
  selector: 'app-user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.css']
})
export class UserWidgetComponent implements OnInit {
  user: any | null
  defaultPhotoUrl: string | null
  //plan: any | null = ''
  constructor(private authService: AuthService, private paymentService: PaymentsService,private  state :StateService,public userState: UserStateService, private router:Router) {
    // this.authService.userDetailsDbSubject.subscribe(() => {
    //   if (authService.dbUser != null) {
    //     this.user = this.authService.dbUser;
    //     if (this.user.ProfilePicture != null) {
    //       this.photoUrl = this.user.ProfilePicture
    //     }
    //     else {
          this.defaultPhotoUrl = '../../../../assets/avatar-male.png'
    //     }
    //   }
    //   else {
    //     this.user = null;
    //     this.photoUrl = null;
    //   }
      // if (this.authService.isPerformer) {
      //   this.paymentService.getCurrentPlan().subscribe((plan) => {
      //     if(plan!=null){
      //     state.SetSession(AuthConstants.PLAN, plan);
      //     this.plan = plan;
      //     }
      //   })
      // }
    // })

  }

    
  
  ngOnInit(): void {
  }
toggleProfile(){

  var contextWrapper = document.getElementsByClassName('user-widget-context-wrap')[0]
  if(contextWrapper?.classList.contains('user-widget-context-visible')){
    contextWrapper.classList.remove('user-widget-context-visible')
  }
  else{
  contextWrapper?.classList.add('user-widget-context-visible')
  }
}
dashboardClicked(){
  if(this.userState.hasClaim('role','performer')){
    this.router.navigate(['performer','dashboard'])
  }else{
    this.router.navigate(['user','dashboard'])
  }
}
logoutUser(){
  this.userState.SignOut()
  
}
myProfileClicked(){
  this.router.navigate(['user-profile'])
}
}
