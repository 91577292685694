import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';
import { TableHeaderItem } from 'src/app/models/TableHeaderItem';
import { CreateCatalogComponent } from '../../Catalogs/create-catalog/create-catalog.component';
import { SnackbarService } from 'src/app/snackbar.service';
import { AddSongsToCatalogComponent } from '../../Catalogs/add-songs-to-catalog/add-songs-to-catalog.component';
import { LazyLoadEvent } from 'primeng/api';
import { CatalogService } from 'src/app/Services/catalog.service';
import { OdataResponse } from 'src/app/models/OdataResponse';
import { query } from '@angular/animations';
import { BaseFilterQuery } from 'src/app/common/Contants/Contants';

@Component({
  selector: 'app-song-catalog',
  templateUrl: './song-catalog.component.html',
  styleUrl: './song-catalog.component.css'
})
export class SongCatalogComponent implements OnInit,AfterViewInit{
  @ViewChild('tags') tagsTemplate:TemplateRef<any> 
  @ViewChild('action') actionTemplate:TemplateRef<any> 
  totalCatalogs:number =0
  catalogList:any
  isCatalogLoading:boolean=false
  catalogHeaders:TableHeaderItem[] = [
    
  ]
  tableConfiguration:TableConfigurationModel = {
    headers:this.catalogHeaders,
    id:'',
    lazyLoad:(event:any)=>this.loadCatalogs(event),
    loading:this.isCatalogLoading,

  }
  ref: DynamicDialogRef | undefined;
  constructor(private catalogService:CatalogService, public dialogService: DialogService, private notificationService:SnackbarService){}
  ngAfterViewInit(): void {
    this.catalogHeaders=[
      {
        displayName:'Id',
        isVisible:false,
        name:'Id',
        type:'text',
        isSortable:true
      },
      {
      displayName:'Catalog Name',
      isVisible:true,
      name:'Name',
      type:'text',
      isSortable:true
    },
    {
      displayName:'Tags',
      isVisible:true,
      name:'Tags',
      type:'text',
      isCustomTemplate:true,
      customTemplate:this.tagsTemplate,
      isSortable:true
    },
    {
      displayName:'Tracks',
      isVisible:true,
      name:'Action',
      type:'action',
      isCustomTemplate:true,
      customTemplate:this.actionTemplate
    }]
    this.tableConfiguration = {
      headers:this.catalogHeaders,
      id:'',
      lazyLoad:(event:any)=>this.loadCatalogs(event),
      loading:this.isCatalogLoading,
  
    }
  }
  ngOnInit(): void {
   
  }
  loadCatalogs(event?:LazyLoadEvent){
    let query = BaseFilterQuery
    if(event){
     query = JSON.stringify(event)
    }
    this.catalogService.GetCatalogsAsync(query).subscribe((result:any)=>{
      if(result){
        let response = result as OdataResponse
        this.totalCatalogs = response.totalCount
        this.catalogList = response.data
      }
    })
  }
  CreateCatalog(){
   this.ref =  this.dialogService.open(CreateCatalogComponent,{header:'Create Catalog', height:'80vh', width:'100%', style:{maxWidth:'95%'}})
   this.ref.onClose.subscribe((catalog:any)=>{
    if(catalog){
      this.notificationService.CreateNotification(`Catalog created: ${catalog.name}`)
      this.ref = this.dialogService.open(AddSongsToCatalogComponent,{header:`Add Tracks to catalog: ${catalog.name}`, width:'80vw',height:'80vh', data:{
        catalogName:catalog.name,
        catalogId:catalog.id
      }})
      this.ref.onClose.subscribe(()=>{
        this.loadCatalogs()
      })
    }
   })
  }
  OpenTracksDialog(data:any){
    this.ref = this.dialogService.open(AddSongsToCatalogComponent,{header:`Add Tracks to catalog: ${data.Name}`, width:'100%',height:'80vh',style:{maxWidth:'95%'}, data:{
      catalogName:data.Name,
      catalogId:data.Id
    }})
    this.ref.onClose.subscribe(()=>{
      this.loadCatalogs()
    })
  }
  DeleteCatalog(data:any){
   let agreed =  window.confirm(`Are you sure you want to delete catalog: ${data.Name}?
All the contained tracks will also be deleted.` )
if(!agreed){
  return
}
this.catalogService.deleteCatalog(data.Id).subscribe(response =>{
  if(response){
    this.notificationService.CreateNotification("Successfully deleted catalog: "+data.Name);
    this.loadCatalogs()

  }
})
  }
  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
}
}
