import { Component, OnInit } from '@angular/core';
import { TableRowSelectEvent, TableRowUnSelectEvent } from 'primeng/table';
import { AdminService } from '../_services/admin.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit{
  searchUserValue:any
  searchTimeoutObj: NodeJS.Timeout;
  userSelected:any[] = []
  constructor(private adminService:AdminService){}
  ngOnInit(): void {
    this.adminService.GetUsersAsync('test').subscribe((users)=>{
        this.usersList=users
    })
  }
  usersList:any
OnCheckboxUnchecked($event: any) {
this.userSelected.splice(this.userSelected.findIndex(x=>x.id==$event.id),1)
}
OnCheckboxChanged($event: any) {
this.userSelected.push($event)
}
searchUser(){
  if(this.searchUserValue && this.searchUserValue!=''){
    if(this.searchTimeoutObj){
      clearTimeout(this.searchTimeoutObj)
      
    }
   this.searchTimeoutObj= setTimeout(()=>{
      this.adminService.SearchUsers(this.searchUserValue).subscribe((users)=>{
        this.usersList=users
      })
    },500)
  }
}
}
