<div class="d-flex flex-row h-100">
    <div class="px-3 w-100" style="max-width: 250px;">
<div class="d-flex flex-column bg-dark h-100 db-nav">
    
        <ul class="nav nav-pills flex-column py-3 px-2">
            <a class="d-flex mb-2 menu-item" style="text-decoration: none; cursor: pointer;"  *ngFor="let item of menuItems"   id="{{item.name}}">
                <div class="w-100 p-1 d-flex nav-item " [ngClass]="{'nav-selected':selectedNav == item.name}" (click)="navClicked(item.name)" >
                    <i [ngClass]="item.icon"  style="font-size: 1.5rem;"></i>           
            <span class="nav-link d-none d-lg-inline-block "  href="javascript:void(0)">
                {{item.name}}
            </span>
        </div>
    </a>
        </ul>
    
</div>

</div>
<div id="dashboard-body" class="w-100 d-flex flex-column" style="min-width: 450px;">
    <h4>{{this.selectedNav}}</h4>
    <div class="d-block p-3" style="background-color: var(--frame-color);" >
        <app-users-list *ngIf="this.selectedNav == menuItemConstants.Users"></app-users-list>
        <app-admin-jobs-dashboard *ngIf="this.selectedNav == menuItemConstants.Jobs"></app-admin-jobs-dashboard>
        <app-all-events-list *ngIf="this.selectedNav == menuItemConstants.Events"></app-all-events-list>
    </div>
</div>
</div>