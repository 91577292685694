import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar,MatSnackBarModule } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'

})
export class SnackbarService {

  constructor(private zone : NgZone,private snackBar : MatSnackBar) { }

  CreateNotification(message :string){
    this.zone.run(()=>this.snackBar.open(message,"X",{panelClass:['mx-auto','error-bar'],duration:5000}).onAction().subscribe(()=>{
      this.snackBar.dismiss()
  }))
  }
}
