<div class="d-block w-100 mx-auto" style="max-width: 1365px;">
<div class=" jukrContainer d-flex flex-row justify-content-around flex-wrap gap-4 px-3 mt-3">
    <div class="card  jukr-card logon-card w-100 h-100 order-1" style="align-self: flex-start !important; ">

        <div class="d-flex flex-column w-100 p-4 my-2">
            <h3 class="d-flex justify-content-center">Payment Details</h3>
            <p class="text-center">Please provide your valid payment details for checkouts</p>
            <form [formGroup]="accountDetails" *ngIf="planName==0">
                <div class="form-group mb-4">
                    <p class="form-label">Billing Address*</p>
                    <div class="w-100 justify-content-center">
                       <input class="form-input" placeholder="Enter your address here" value="" formControlName="BillingAddress"/>
                    </div>
                </div>
                <div style="border: 1px solid rgba(255,255,255,0.1); border-radius: 10px; margin-bottom: 20px;" class="p-4">
            <div class="form-group mb-4">
                <p class="form-label">UPI ID*</p>
                <div class="w-100 justify-content-center">
                   <input class="form-input" placeholder="eg. 9876543210@okaxis" value="" formControlName="UPI" (keypress)="upiTextBoxChanged()"/>
                </div>
                <span *ngIf="nameAtBank!=undefined" class="fw-r">{{nameAtBank}}</span>
            </div>
            
            <div class="d-flex flex-row">
            <div class="secondary-btn" style="max-width: 30%;">
                <button (click)="verifyUPI()" [disabled]="isUPIVerified">Verify UPI</button>
            </div>
            <div class="mx-2 my-auto" *ngIf="isVerifyPressed">
            <img src="../../../../assets/icons/Verified Account.svg" height="20px" *ngIf="isUPIVerified"/>
            <img src="../../../../assets/icons/Cancel.svg" height="20px" *ngIf="!isUPIVerified" />
        </div>
        </div>
        <br>
        <div class="d-flex flex-row separator-parent">
                
            <div role="separator" class="separator"></div>
            <p class="m-0 mx-3 h-auto">Or</p>
            <div role="separator"  class="separator"></div>

        </div>
        <br>
        <div formGroupName="BankAccount">
        <div class="form-group mb-4">
            <p class="form-label">Name(as per Bank Account)*</p>
            <div class="w-100 justify-content-center">
               <input class="form-input" placeholder="eg. John Doe" value=""  formControlName="Name"/>
            </div>
        </div>
        <div class="form-group mb-4">
            <p class="form-label">Account Number</p>
            <div class="w-100 justify-content-center">
               <input class="form-input" placeholder="eg. xxxxxxxxxxxxxxxxx335" value=""  formControlName="AccountNumber"/>
            </div>
        </div>
        <div class="form-group mb-4">
            <p class="form-label">IFSC code</p>
            <div class="w-100 justify-content-center">
               <input class="form-input" placeholder="eg. xxxxxxxxxxxxxxxxx335" value="" formControlName="IFSCCode"/>
            </div>
        </div>
    </div>

      </div>  
           
        </form>
        <p class="fw-r ">No payment will be deducted when submitting</p>
        <button (click)="Submit()" class="btn primary-btn" [disabled]="isSubmitted"><span>Submit</span></button>
        </div>
    </div>
    <div class="card  jukr-card logon-card w-100 h-100 order-0" style="align-self: flex-start !important; ">

        <div class="d-flex flex-column w-100 p-4 my-2">
            <h3 class="mx-auto">Checkout Summary</h3>
            <div class="d-block my-4">
                <div class="d-flex flex-column ">
                    <div class="d-flex justify-content-between my-3">
                        <label class="form-label">Plan Name</label>
                        <h4>{{planDetails.PlanName}}</h4>
                    </div>
                    <div class="d-flex justify-content-between">
                        <label class="form-label">Sub total</label>
                        <div class="d-flex flex-row align-bottom">
                        <label class="form-label m-0 align-bottom"><span>₹{{planDetails.PlanFees}}</span></label>                       
                    </div>
                        
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="planName==0">
                        <label class="form-label">Commissions</label>
                        <div class="d-flex flex-row align-bottom">
                        <label class="form-label m-0 align-bottom"><span>{{planDetails.Commissions}}%</span></label>                       
                    </div>
                        
                    </div>
                </div>

            </div>
            </div>
            </div>

</div>
</div>