<div *ngIf="this.qrService.qrDetails==null">
<p>You don't currently have a QR code connected with your account</p>
<p>Create a QR code to share with your audience and start accepting Requests</p>
<p>Please provide a unique name for your audience to recognize you!!</p>
</div>
<div *ngIf="this.qrService.qrDetails!=null">
<p>Your QR code is displayed below. Kindly print or share with your audience to start accepting requests.</p>
</div>
<hr>

<div class="d-flex flex-row" >
    <div class="form-group mb-4" style="max-width: max-content;">
        <p class="form-label">Unique Name*</p>
        <div class="w-100 justify-content-center">
           <input pInputText  [disabled]="this.qrService.qrDetails!=null" type="text" class="form-input" id="uniqueId" placeholder="eg. Dj_Cody" value="" style="text-transform: lowercase"/>
        </div>
    </div>
    <button [disabled]="this.qrService.qrDetails!=null" class="btn primary-btn my-auto ms-3" style="width: fit-content;" (click)="GenerateQrCode()"><span>Get QR code</span></button>
</div>

<div class="m-auto jukr-card d-flex flex-column p-5" *ngIf="this.qrService.qrDetails!=null">
<div id="qrCode" class="m-auto d-flex flex-column p-3" style="border: 1px solid black; background-image: var(--primary-gradient); border-radius: 5px;">
    <h4 class="m-auto mb-3">Scan to request a song</h4>
    <img id="qrCodeImg" [src]='this.qrService.qrBitMap' height="200px" width="200px" class="m-auto mb-3"/>
    <p class="m-auto">Performer ID: {{this.qrService.qrDetails.performerName}}</p>
</div>
<hr>
<div class="secondary-btn m-auto" style="width: fit-content;"><button (click)="DownloadCode()" style="width: fit-content;"><span>Download</span></button></div>
</div>