import { Pipe } from "@angular/core";
import { UserStateConstant } from "src/app/common/Contants/Contants";

@Pipe({
    name:'AuthStage'
})
export class AuthStagePipe{
transform(value:number){
    if(value==UserStateConstant.ONBOARDING){
        return 'Onboarding'
    }
    if(value == UserStateConstant.COMPLETE){
        return 'Complete'
    }
    if(value == UserStateConstant.PLAN){
        return 'Plan'
    }
    return value
}
}
@Pipe({
    name:'JobStatus'
})
export class JobStatusPipe{
transform(value:number){
    if(value==0){
        return 'Started'
    }
    if(value == 2){
        return 'Complete'
    }
    if(value == 1){
        return 'Errored'
    }
    return value
}
}