import { Injectable } from '@angular/core';
import { UserStateService } from './user-state.service';
import { environment } from 'src/environments/environment';
import { UserStateConstant } from 'src/app/common/Contants/Contants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
public isLoading=true
  constructor( private router :Router) { }
  GenerateRedirectionParams(url:string){

    if(url){
      var queryParams
      if(url.indexOf('?')==-1){
        return undefined
      }
      if(url.indexOf('?')!=-1){
        var params = url.split('?')
        var paramsDict = params[1].split('&')
        queryParams = {
          queryParams:paramsDict
        }
        return queryParams
        
      }
      
    }
    return undefined;
  }
  GenerateRedirectionUrl(url:string){
    if(url){
      var queryParams
      if(url.indexOf('?')==-1){
        return [url]
      }
      else{
        return [url.split('?')[0] ]
      }
      
    }
    return []
  }
  HandleSuccessAuthRedirect(userState:any,response:any, redirectUrl:any){
    userState.saveUserState(response);
        if(response.user.claims?.role =='admin'){
          this.router.navigate(['admin/dashboard'])
           return
        }
        if (response.user.userStage==UserStateConstant.ONBOARDING) {
          this.router.navigate(['performer/onboarding'])
        }
        else if(response.user.userStage==UserStateConstant.PLAN){
          this.router.navigate(['performer/plan'])
        }
          else if(redirectUrl!=undefined){
            this.router.navigate(this.GenerateRedirectionUrl(redirectUrl),this.GenerateRedirectionParams(redirectUrl))
          }
          else{
  this.router.navigate(['/performer/dashboard'])}
  }
}
