export const Plans={
    Starter:0,
    Subscription:1

}
export const AuthConstants={
    USER:'User',
    TOKEN:'token',
    PLAN:'plan',
    USER_PROFILE:'userProfile',
    ACCESS_TOKEN:'accessToken'
    
}
export const defaultTimer = 60
export const PhoneAuthConstants={
    VERIFICATION_ID:'VerificationId',
    PHONE_NUMBER:'phoneNumber'
}
export const PlanDetailsConstant=[
    {
        PLanId:0,
        PlanName:'Starter',
        PlanFees:0, 
        Commissions:15       
    },
    {
        PLanId:1,
        PlanName:'Subscription',
        PlanFees:2500,  
        Commissions:0      
    }
]
export const Constants={
    PERFORMER_ID:'performer_id',
    PENDING_REQUESTS:'pendingRequests',
    RUPEES_SIGN:'₹',
    LIVEEVENT:'LiveEventDetails'
}
export const UserStateConstant={
    ONBOARDING:0,
    PLAN:1,
    COMPLETE:2
}
export const BaseFilterQuery = '{"first":0,"rows":10,"sortOrder":1,"filters":{},"globalFilter":null}'
export const CatalogType=[
    {
      name:'Spotify',
      value:'spotify'
    },{
      name:'Custom Catalog',
      value:'custom'
    }
  ]
