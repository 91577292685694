import { Routes } from "@angular/router";
import { AdminDashboardComponent } from "../Admin/dashboard/admin-dashboard.component";
import { adminGuard } from "../Guards/admin.guard";
import { SignInComponent } from "../performer/auth/sign-in/sign-in.component";
import { authGuard } from "../Guards/auth.guard";


export const adminRoute :Routes=
[
{path:'dashboard',component:AdminDashboardComponent}
]