<div class="d-block p-4 m-auto" style="max-width: 500px;">
    <div><a (click)="returnButtonClicked()"><i class="bi bi-chevron-left white" style="font-size: 1.5rem;"></i></a></div>
    <div class="header mt-2">
        <h4>Request Details</h4>
    </div>
    <div class="d-flex flex-column" *ngIf="orderDetails!=null" >
        <app-selected-track [track]="orderDetails.track"></app-selected-track>
        <div class="d-flex flex-column p-2 mt-2">
            
            <div class="d-flex flex-row justify-content-between my-1 ">
                <label class="fw-r row-header">Reference Id</label>
                <label class="fw-r">{{orderDetails.id}}</label>
               
            </div>
            <div class="d-flex flex-row justify-content-between my-1">
            <label class="fw-r row-header">Amount</label>
            <label class="fw-r">{{constants.RUPEES_SIGN}}{{orderDetails.amount}}</label>
        </div>
        <div class="d-flex flex-row justify-content-between my-1">
            <label class="fw-r row-header">Performer</label>
            <label class="fw-r">{{orderDetails.performer.name}}</label>
        </div>
        <div class="d-flex flex-row justify-content-between my-1">
            <label class="fw-r row-header">Status</label>
            <p-tag  value="{{orderDetails.status}}"></p-tag>
        </div>
        </div>

    </div>
</div>