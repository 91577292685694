<div class=" jukrContainer">
    <div class="card  jukr-card logon-card m-auto">
        <div class="d-flex flex-column w-100 p-4 my-5">

            <div>
                <h1 class="d-flex justify-content-center">Onboarding!</h1>
                <p class="d-flex justify-content-center">Please verify your details to get started</p>
            </div>
            <hr>


<form [formGroup]="userProfile" (ngSubmit) = 'onSubmit()' novalidate>
            <div class="form-group mb-4">
                <p class="form-label">Full Name*</p>
                <div class="w-100 justify-content-center">
                   <input class="form-input" placeholder="Enter your Full Name" value="" formControlName="name"/>
                </div>
            </div>
            <div class="form-group mb-4">
                <p class="form-label">Email Address*</p>
                <div class="w-100 justify-content-center">
                   <input class="form-input" placeholder="Enter your Email address" formControlName="emailAddress"/>
                </div>
            </div>
            <div class="form-group mb-4">
                <p class="form-label">Phone Number*</p>
                <div class="form-group gp-phone-number d-flex flex-row">
                    <span class="country-code px-2 text-decorate-icon" id="country-code">+91</span>
                    <input for="Phone" class=" py-0 px-2 text-decorate-input" h-100 id="phone-number" maxlength="10"
                        placeholder="eg. 9876543210" formControlName="phoneNumber"  value="{{this.userProfile.controls['phoneNumber'].value}}"/>
            
                </div>
            </div>
            <hr>
     


            <div class="form-element">
                <button class="btn  primary-btn"  ><span>Sign Up</span></button>
            </div>
        </form>

           



        </div>
    </div>
</div>