<div class="m-2">
    <div class=" d-flex flex-column gap-3  catalog-container" style="background-color: var(--frame-color);">
        <!-- <div class="d-flex flex-row gap-4">
        <div class="form-group gp-phone-number d-inline-flex " style="height: 2.5rem;"  [ngClass]="isAddCustom?'disabled-input':''">
        <input type="text"
         (keyup)="searchTextChanged($event.target)"
         [disabled]="isAddCustom"
         
          class="text-decorate-input "
                placeholder="Search on Spotify">
                <p-progressSpinner [style]="{'color':'red','height':'2rem','width':'2rem'}" [hidden]="isSearchingComplete"></p-progressSpinner>
            </div>
            <mat-slide-toggle class="my-auto" [(ngModel)]="isAddCustom"  (change)="OnToggleEvent()" >{{isAddCustom? 'Add using spotify':'Add custom'}}</mat-slide-toggle>
        </div> -->
        <!-- <div  class="row" >
            <div class="col-4">
                <div class="d-flex flex-row w-100">
                <p-scrollPanel [style]="{ width: '100%', height: '400px' }" class="flex-grow-1">
                    <div *ngFor="let track of trackList" (click)="OnSpotifyTrackSelected(track)">
                        <app-selected-track [track]="track"  >

                        </app-selected-track>
                        <p-divider></p-divider>
                    </div>
                    
                </p-scrollPanel>
                <p-divider layout="vertical"></p-divider>
            </div>
            </div>
            
            <div class="col-4 h-100 d-flex flex-row flex-grow-1">
                
                
                <div class="d-flex flex-column gap-4 flex-grow-1" [formGroup]="fgCustomTrack">
                <div class="d-flex flex-column gap-2">
                    <label for="name">Song Name *</label>
                    <input pInputText id="name" [disabled]="!isAddCustom"  aria-describedby="name-help" placeholder="eg. Party songs" formControlName="name" />       
                </div>
                <div class="d-flex flex-column gap-2">
                    <label for="name">Artist Names *</label>
                    <input pInputText id="name"  [disabled]="!isAddCustom" aria-describedby="name-help" placeholder="eg. Party songs" formControlName="artist" />       
                </div>
                <button class="btn primary-btn" (click)="addToTrackList()"><span>Add to Catalog</span></button>
                </div>
            </div>
            <div class="col-4">
                <div class="d-flex flex-row">
                    <p-divider layout="vertical"></p-divider>
                    <p-scrollPanel [style]="{ width: '100%', height: '400px' }">
                        <div class="w-100" *ngFor="let track of tracksInCatalogList">
                            <div class="d-flex flex-row">
                            <app-selected-track style="width: 100%;" [track]="track" >
    
                            </app-selected-track> -->
                            <!-- <i class="my-auto bi bi-trash" style="color: red;"></i> -->
                            <!-- <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger "></p-button>
                        </div>
                            <p-divider></p-divider>
                        </div>
                    </p-scrollPanel >
                </div>
            </div>
        </div> -->

        <div class="d-flex flex-row gap-4 h-100 flex-wrap flex-grow-1" [formGroup]="fgCustomTrack">
            <div class="d-flex flex-column gap-2 w-100" style="max-width: 350px;">
                <label for="name">Song Name *</label>
                <input pInputText id="name" [disabled]="!isAddCustom"   aria-describedby="name-help" placeholder="eg. I like you" formControlName="name" />       
            </div>
            <div class="d-flex flex-column gap-2 w-100" style="max-width: 350px;">
                <label for="name">Artist Names *</label>
                <input pInputText id="name"  [disabled]="!isAddCustom" aria-describedby="name-help" placeholder="eg. Alap walker" formControlName="artist" />       
            </div>
            <div class="d-flex flex-column gap-2 justify-content-end">
                <button class="btn primary-btn d-flex " (click)="addToTrackList()"><span>Add to Catalog</span></button>
            </div>
            
            </div>

        
           
            </div>
            <div class="d-flex my-3  mx-md-5 flex-column gap-3">
            <!-- <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Search by Name or Artist Name" [(ngModel)]="searchText" (keyup)="FilterTrackList(this.searchText)" />
            </span> -->
            <div>
                <div>
                    <app-base-table [tableConfiguration]="tableConfiguration"
                    [totalRecords]="totalRecords"
                    [dataObj]="tracksInCatalogList"
                    [isCheckBoxEnabled]="false"
                    
                    >

                    </app-base-table>
                </div>
                <!-- <div *ngFor="let track of tracksInCatalogList;index as i" class="d-flex flex-row gap-2">
                        <app-selected-track [track]="track" [isCustomTrack]="true" class="d-flex w-100"></app-selected-track>
                        <p-button icon="pi pi-trash" [rounded]="true" [text]="true" severity="danger " (onClick)="deleteTrack(i,$event)"></p-button>
                </div> -->
            </div>
                
            </div>
           
        </div>
        <ng-template #action let-data="data" let-item="item">
            <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger " [style]="{color:'red'}" (onClick)="deleteTrack(data)"></p-button>
        </ng-template>

            


