import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonPipe } from '@angular/common';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})

export class PaymentsService {
  private baseurl="https://localhost:7180/api/payments/"
  constructor(private http: HttpService) { }

  CheckStatus(endpoint:string, order_id :string){
    return this.http.Get("@api-dev/payments/"+endpoint+"?order_id="+order_id,false);
  }
  VerifyUpi(vpa : any){
  const json =JSON.stringify(vpa);
  let headers = new HttpHeaders();
headers= headers.append('content-type', 'application/json');
    return this.http.Post("@api-dev/Payments/VerifyUPI",json, headers,true);
  }
  RegisterPerformerStarterPack(model :any){
    return this.http.Post('@api-dev/Payments/RegisterPerformer', model,null,true);
  }
  getCurrentPlan(){
    return this.http.Get('@api-dev/Payments/GetCurrentPlan',false);
  }
  RegisterSubscription(model :any){
    return this.http.Post('@api-dev/Payments/CreateSubscription',model,null,false);
  }
  VerifySubscription(){
    return this.http.Get('@api-dev/Payments/VerifySubscriptionStatus',false);
  }
}

