import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import {Subscription, interval} from 'rxjs';
import { PhoneAuthConstants, UserStateConstant, defaultTimer } from 'src/app/common/Contants/Contants';
import { StateService } from 'src/app/Services/state.service';
import { CommonService } from 'src/app/_common/services/common.service';
import { Location } from '@angular/common'
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.css']
})
export class EnterOtpComponent implements OnInit, OnDestroy {
  phoneNumber :string | null 
  @Output() sendOtp = new EventEmitter<string>();
  otp : string
  verifyCode :string
  timerSubscription: Subscription;
  timerElapsed: boolean=false;
  redirectUrl: string | null;
  constructor(public location : Location,private authService : AuthService,private router : Router, private stateService :StateService, private route : ActivatedRoute,
     private commonService :CommonService, private userState:UserStateService,private recaptchaV3Service: ReCaptchaV3Service ) { }
  ngOnDestroy(): void {
    this.stateService.RemoveItem(PhoneAuthConstants.PHONE_NUMBER)
    this.stateService.RemoveItem(PhoneAuthConstants.VERIFICATION_ID)
    this.timerSubscription.unsubscribe()
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params)=>{
      
        this.redirectUrl = params.get('redirectUrl')
     
    })
    this.verifyCode= this.stateService.GetItem(PhoneAuthConstants.VERIFICATION_ID)
    
     this.phoneNumber = this.stateService.GetItem(PhoneAuthConstants.PHONE_NUMBER) || null;
     if(this.phoneNumber==null && typeof(this.verifyCode)!= typeof(Object)){
      this.router.navigate(['performer/auth/sign-in'])
      
     }
     this.startTimer(defaultTimer);
  }
  processOtp(){
if(this.otp.length!=6){
  throw('Invalid OTP')
}

      this.authService.verifyOtp(this.otp, this.verifyCode).subscribe((response: any) => {
        this.commonService.HandleSuccessAuthRedirect(this.userState,response,this.redirectUrl)
  //       this.userState.saveUserState(response);
  //       if(this.userState.hasClaim('role','admin')){
  //          window.location.href = environment.adminHost+'/dashboard'
  //          return
  //       }
  //       if (response.user.userStage==UserStateConstant.ONBOARDING) {
  //         this.router.navigate(['performer/onboarding'])
  //       }
  //       else if(response.user.userStage==UserStateConstant.PLAN){
  //         this.router.navigate(['performer/plan'])
  //       }
  //         else if(this.redirectUrl!=undefined){
  //           this.router.navigate(this.commonService.GenerateRedirectionUrl(this.redirectUrl),this.commonService.GenerateRedirectionParams(this.redirectUrl))
  //         }
  //         else{
  // this.router.navigate(['/performer/dashboard'])}
        })
  
      
  
    
  }
  onOtpChange(otp :string){
this.otp=otp;
  }
  startTimer(timer: number) {
    var minutes ;
    var seconds;
     this.timerSubscription=interval(1000).subscribe(x=>{
      minutes=Math.floor(timer/60);
      seconds = Math.floor(timer%60);
    
      minutes=minutes<10? '0'+minutes:minutes;
      seconds=seconds<10? '0'+seconds:seconds;
      $("#timer").text(minutes+':'+seconds);
      --timer;
      if(timer<0){
    this.timerSubscription.unsubscribe()
    this.timerElapsed=true
      }
    })
      }
      ResendOtp(){
        this.recaptchaV3Service.execute('resendOtp')
        .subscribe((token: string) => {
          this.authService.PhoneNumberSendOtp(this.phoneNumber,token).then(response=>{
            this.verifyCode =response.verificationId;
            this.timerElapsed=false
            this.startTimer(defaultTimer)
          
          })
        });


      }
}
