
import { Component, OnInit, inject } from '@angular/core';


@Component({
  selector: 'app-redirect-response',
  templateUrl: './redirect-response.component.html',
  styleUrls: ['./redirect-response.component.css']
})
export class RedirectResponseComponent implements OnInit {

  constructor() {
   
   }

  ngOnInit(): void {

  }

}
