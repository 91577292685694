import { Component, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  FacebookAuthProvider, GoogleAuthProvider, AuthErrorMap, AuthErrorCodes, prodErrorMap, PhoneAuthProvider,getRedirectResult } from 'firebase/auth';
import { AuthService } from 'src/app/Services/auth.service';
import { StateService } from 'src/app/Services/state.service';
import { PhoneAuthConstants, UserStateConstant } from 'src/app/common/Contants/Contants';
import { Location } from '@angular/common';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { CredentialResponse,PromptMomentNotification } from 'google-one-tap';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/_common/services/common.service';
import { ReCaptchaV3Service, RecaptchaLoaderService } from 'ng-recaptcha';
declare let grecaptcha :any;
var errorMap= prodErrorMap
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit,OnDestroy {
  isNewUser =false;
  isEnterOtpVisible=false;
  phoneNumber :any
  redirectUrl: string | null;
  constructor(public location:Location,private authService :AuthService, public router : Router,
  private userState:UserStateService, private stateService : StateService, private route: ActivatedRoute,private recaptchaV3Service: ReCaptchaV3Service,
  private commonService :CommonService, private zone: NgZone) { }
  ngOnDestroy(): void {
         //@ts-ignore
         google.accounts.id.cancel()
         
         
  }

  ngOnInit(): void {
this.InitializeGoogleLogon()
this.route.queryParamMap.subscribe((params)=>{
  if(params.has('redirectUrl')){
    this.redirectUrl = params.get('redirectUrl')
  }
})
  //   var mode = this.stateService.GetItem('logonMode')
  //   if(mode!=undefined){
  //   getRedirectResult(this.auth)
  // .then((result : any) => {
    
    
  //   const credential = GoogleAuthProvider.credentialFromResult(result);
  //   const token = credential?.accessToken;

  //   const user = result.user;
  //   this.userState.saveUserState(result);
  //   localStorage.removeItem('logonMode')
  //   // IdP data available using getAdditionalUserInfo(result)
  //   // ...
  // }).catch((error) => {
  //   // Handle Errors here.
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  //   // The email of the user's account used.
  //   const email = error.customData.email;
  //   // AuthCredential type that was used.
  //   const credential = GoogleAuthProvider.credentialFromError(error);
  //   localStorage.removeItem('logonMode')
  //   // ...
  // });}

  }
  InitializeGoogleLogon() {
        //@ts-ignore
    // window.onGoogleLibraryLoad=()=>{
      //@ts-ignore
      google.accounts.id.initialize({
        client_id:environment.auth.google.clientId,
        callback:this.handleGoogleCallback.bind(this),
        auto_select:false,
        cancel_on_tap_outside:false,itp_support:true,
        
        
      });
      //@ts-ignore
      google.accounts.id.renderButton(
        //@ts-ignore
        document.getElementById("googleButton"),
        {theme:"outline",size:"large",width:"300",shape:"rectangular"}
        
      );
      //@ts-ignore
      google.accounts.id.prompt((notification:PromptMomentNotification)=>{
       
      })

    // }
  }
  async handleGoogleCallback(response: CredentialResponse) {
    this.authService.VerifyGoogleCredentials(response.credential).subscribe((result) => {
      // this.userState.saveUserState(result)
      // this.zone.run(()=>{
      //   if (result.user.userStage==UserStateConstant.ONBOARDING) {
      //     this.router.navigate(['performer/onboarding'])
      //   }
      //   else if(result.user.userStage==UserStateConstant.PLAN){
      //     this.router.navigate(['performer/plan'])
      //   }
      //   else if (this.redirectUrl != undefined) {
      //     this.router.navigate(this.commonService.GenerateRedirectionUrl(this.redirectUrl), this.commonService.GenerateRedirectionParams(this.redirectUrl))
      //   }
      //   else {
      //     this.router.navigate(['/performer/dashboard'])
      //   }
      // })
      this.commonService.HandleSuccessAuthRedirect(this.userState,result,this.redirectUrl)


    })
  }
OnSendOtp(){

  this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
     this.sendOtp(token)
    });
  }
 

   sendOtp(recaptcha:string){

    this.phoneNumber = $('#phone-number').val()?.toString();
    let countryCode = $('#country-code').text();
    if(!this.validatephoneNumber(this.phoneNumber)){
      throw('Enter valid number')
    } 
   
      this.authService.PhoneNumberSendOtp(this.phoneNumber ,recaptcha).then((response)=>{

      this.stateService.SetItem(PhoneAuthConstants.VERIFICATION_ID,response.verificationId)
      this.stateService.SetItem(PhoneAuthConstants.PHONE_NUMBER,this.phoneNumber)
      
      this.router.navigate(['/performer/auth/enter-otp'],{queryParamsHandling:'preserve'})

     }).catch((error)=>
     {
       errorMap
      throw(error.message)
     })
  }
   private validatephoneNumber(phoneNumber :any){
    var re = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
    var result = re.test(phoneNumber)
    
    return result;
  }

  logonWithProvider(mode :string){
    var provider :any
    if(mode=="Google"){
      provider =new GoogleAuthProvider();
    }
    else if(mode =="Facebook"){
      provider = new FacebookAuthProvider()
    }
    this.stateService.SetItem('logonMode',mode);
    
    this.authService.LogonWithProvider(provider);

  }
}
