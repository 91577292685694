<div>
    <div class="request-card p-3 mb-2">
        <p-table [value]="transactionHistory" [tableStyle]="{ 'min-width': '50rem'}" class="event-table"  >
            <ng-template pTemplate="header">
                <tr class="event-table-header">
                   
                    <th>Name</th>
                    <th>Total Requests</th>
                    <th>Total Accepted</th>
                    <th>Total Rejected</th>
                    <th>Total Earnings</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-event let-ri="rowIndex">
                <tr [pSelectableRow]="event" class="event-table-data"  >
                   
                    <td>{{event.eventName}}</td>
                    <td>{{event.totalRequests}}</td>
                    <td>{{event.totalAccepted }}</td>
                    <td>{{event.totalRejected }}</td>

                    <td>Rs {{event.totalEarnings }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
