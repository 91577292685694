<div>
    <span class="p-input-icon-left my-2">
        <i class="pi pi-search"></i>
        <input type="text" placeholder="Search name, email, phone, role" class="p-inputtext-sm" pInputText [(ngModel)]="searchUserValue" (keyup)="searchUser()" />
    </span>
    <p-table [value]="usersList" [tableStyle]="{ 'min-width': '50rem'}" class="event-table" 
    (onRowSelect)="OnCheckboxChanged($event)" (onRowUnselect)="OnCheckboxUnchecked($event)" 
    >
        <ng-template pTemplate="header">
            <tr class="event-table-header">
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Onboarding Status</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-ri="rowIndex">
            <tr [pSelectableRow]="user" class="event-table-data"  >
                <td>
                    <p-tableCheckbox [value]="user"  ></p-tableCheckbox>
                </td>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.phoneNumber}}</td>
                <td>{{user.role}}</td>
                <td><p-tag>{{user.userStage | AuthStage}}</p-tag> </td>
        

                <!-- <td>{{event.isActive? 'Active':'In active'}}</td> -->               
            </tr>
        </ng-template>
    </p-table>
</div>
