<div class="container">
    <div class="logon-container">
        <input placeholder="phone number" id="phone-number" [maxLength]="10" [minLength]="10"/>
        <button class="button" id="login-button" (click)="logon()">Login</button>
        <div id="recaptcha"></div>
    </div>
    <div class="google-auth">
        <button (click)="signUpWithGoogle()">Google</button>
    </div>
    
</div>