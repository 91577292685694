import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/Services/event.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {
  transactionHistory:Array<any>
constructor(private eventService :EventService){}
  ngOnInit(): void {
    this.eventService.GetTransactionSummary().subscribe((history:any)=>{
      if(history!=undefined){
        this.transactionHistory=history
      }
    })
  }
}
