<div class="form-group">
    <p class="">Enter OTP sent to +91 {{this.phoneNumber}}</p>
    <div class="w-100 justify-content-center">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6, inputClass:'each_input'}"
            class=" d-flex justify-content-center label-bg"></ng-otp-input>
    </div>
    <div class="d-flex flex-row my-3">
        <p id="timer"></p>
        <a class="mx-3" (click)="ResendOtp()">resend OTP</a>
    </div>
</div>