import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { PaymentsService } from 'src/app/Services/payments.service';
import { UserStateService } from 'src/app/_common/services/user-state.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {
currentPlan :any
starterButton:string = "Start Now"
subscriptionButton:string = "Subscribe Now"
  constructor(private router : Router, private paymentService :PaymentsService,private authService : AuthService, public userState:UserStateService) { }

  ngOnInit(): void {

  this.paymentService.getCurrentPlan().subscribe((plan: any)=>{
    if(plan!=null){
       this.currentPlan=plan.planName
       if(plan.planName=="Starter"){
        this.starterButton="Activated"
       }
       else{
        this.subscriptionButton="Activated"
       }
    }
  },(error)=>{
    this.currentPlan==null
  })


    
  }
  StartSubscription(){
    this.router.navigate(["performer/plan/checkout"], {queryParams:{plan:1}} )
  }
  StartStarterPack(){
    this.router.navigate(["performer/plan/checkout"], {queryParams:{plan:0}} )
  }
  skip(){
    this.router.navigate(['performer','dashboard'])
  }
}
