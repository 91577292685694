import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PaymentsService } from '../Services/payments.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-process-return',
  templateUrl: './process-return.component.html',
  styleUrls: ['./process-return.component.css']
})
export class ProcessReturnComponent implements OnInit {
 order_id :any
  constructor(private route :ActivatedRoute, private paymentService :PaymentsService,private router : Router) { }

  ngOnInit(): void {
    this.route.queryParams.forEach((item: Params)=>{
      this.order_id=item['order_id'];
    });
    if(this.order_id!=null){
this.paymentService.CheckStatus("CheckStatus",this.order_id).subscribe((ret)=>{

  var data :any = ret
  if(data.orderStatus=='PAID'){
    this.router.navigate(['/PaymentSuccess']);
  }
  
});
    }

  }

}
