import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http:HttpService) { }
  GetUsersAsync(query:string){
    return this.http.Get(`@api-dev/Admin/GetUsers?query=${query}`,true);
  }
  SearchUsers(query:string){
    return this.http.Get(`@api-dev/Admin/SearchUsers?query=${query}`,true);
  }
  getAllJobs(query:string){
    return this.http.Get(`@api-dev/Admin/Jobs?query=${query}`,true)
  }
  getAllEvents(query:string){
    return this.http.Get(`@api-dev/Admin/Events?query=${query}`,true)
  }
}
