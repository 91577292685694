import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import {  AnimationOptions} from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { PaymentsService } from 'src/app/Services/payments.service';
import { StateService } from 'src/app/Services/state.service';
import { Constants } from 'src/app/common/Contants/Contants';

@Component({
  selector: 'app-load-payment',
  templateUrl: './load-payment.component.html',
  styleUrls: ['./load-payment.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadPaymentComponent implements OnInit,OnDestroy {
  requestId: string;
  performerId: string;
  queryParamSub:Subscription
  messageTop:string
  messageBottom:string
  status:any
  options: AnimationOptions = {path:'../../../assets/payment-processing.json'
    
  }
  constructor(private route :ActivatedRoute, private router:Router,private paymentService:PaymentsService,private stateService:StateService){

  }
 
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
//     this.route.queryParams.forEach((item: Params) => {
//       this.requestId = item['order_id'];
//     });
//     this.route.paramMap.subscribe((item: ParamMap) => {
//       if (item.has('status')) {
//         this.status = item.get('status')
//       }
//     })
//     if (this.requestId != null) {
//       this.paymentService.CheckStatus("CheckStatus", this.requestId).subscribe((ret) => {

//         var data: any = ret
//         if (data.orderStatus == 'PAID') {
//           this.SetSuccess()
//         }
//         else {
//           this.SetFailure()
//         }

//       });
//     }
// if(this.status!=null){
//   this.paymentService.CheckStatus("CheckStatus", this.requestId)
//   if(this.status=='success'){
//     this.SetSuccess()
//   }
//   else{
// this.SetFailure()
//   }
// }
this.messageTop='Payment is being processed'
this.messageBottom='Please do not close the tab or press back.'

}
public SetSuccess(){
  this.messageTop="Payment Successful"
  this.options={
    path : '../../assets/payment-success1.json'}
    this.messageBottom='You will be redirected automatically'
    this.AutoRedirect()

}
public SetFailure() {
  this.messageTop="Payment Failed"
  this.options={
    path : '../../assets/payment-failed.json'}
    this.messageBottom='You will be redirected automatically'
this.AutoRedirect()
  }
AutoRedirect(){
  setTimeout(() => {
var performerId= this.stateService.GetSession(Constants.PERFORMER_ID)
if(performerId==undefined)
    this.router.navigate(['party'])
  else
  this.router.navigate(['party',performerId])
  }, 3000);
}

}
