import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  enterBilling(plan :string){
    if(plan=='starter'){
//check if account details present
this.router.navigate(['/performer/plans/activate/starter'])
    }
    else if(plan='subscribe'){

    }
  }
}
