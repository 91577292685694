import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { QrService } from 'src/app/Services/qr.service';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit{

  constructor(public qrService : QrService, private domSanitizer : DomSanitizer){}
  ngOnInit(): void {
    this.qrService.getQRCode()
  }
  GenerateQrCode(){
    var uniqueId = $("#uniqueId").val() as string
if(uniqueId==''){
  throw 'Unique Name is required.'
}
this.qrService.CreateQRCode(uniqueId).then((data: any)=>{
}).catch((error)=>{
  throw error;
})
  }

 
  DownloadCode(){
    var element = document.getElementById('qrCode')
    if(element!=null){
    html2canvas(element).then((canvas)=>{
      const imgWidth = 208;
			const pageHeight = 295;
			const imgHeight = canvas.height * imgWidth / canvas.width;
			const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
			const position = 0;
			pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
			pdf.save(this.qrService.qrDetails.performerName+'_QR.pdf'); // Generated PDF
    })
  }
  }
}
