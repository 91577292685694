<div class="d-block p-4 m-auto" style="max-width: 500px;">
    <h4>
        Scan the QR code
    </h4>
    <div>
        <div class="position-relative">
        <div class="position-absolute p-1 top-0 end-0" style=" z-index: 999;">
           
            <div (click)="action.isTorch = !action.isTorch; handle(action, 'torcher')">
            <span class="material-symbols-outlined white" >
                {{action.isTorch ? "flash_off":"flash_on" }} 
                </span>
            </div>
        </div>
    </div>
    <ngx-scanner-qrcode #action="scanner"
    [vibrate]="300" 
    [isBeep]="false"
    (event)="onScan($event)"
    ></ngx-scanner-qrcode>
</div>
    <p *ngIf="action.isLoading">Loading...</p>
    <p *ngIf="message!=undefined" >{{message}}</p>
</div>