import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, provideRouter, withDebugTracing } from '@angular/router';
import { ProcessReturnComponent } from './process-return/process-return.component';
import { CustomerRequestComponent } from './customer-request/customer-request.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { LogonHomeComponent } from './auth/logon-home/logon-home.component';
import { EnterOtpComponent } from './performer/auth/enter-otp/enter-otp.component';
import { SignInComponent } from './performer/auth/sign-in/sign-in.component';
import { RedirectResponseComponent } from './common/redirect-response/redirect-response.component';
import { DashboardComponent } from './performer/dashboard/dashboard.component';
import { PerformerAuthGuard } from './Guards/performer-auth.guard';
import { BillingCommissionDetailsComponent } from './performer/dashboard/billing-commission-details/billing-commission-details.component';
import { OnboardingComponent } from './performer/onboarding/onboarding.component';
import { PlanComponent } from './performer/plan/plan.component';
import { compileClassMetadata } from '@angular/compiler';
import { CheckoutComponent } from './performer/plan/checkout/checkout.component';
import { SubscriptionReturnComponent } from './performer/subscription-return/subscription-return.component';
import { RequestComponent } from './Party/request/request.component';
import { ConsoleComponent } from './performer/live/console/console.component';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './policies/terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './policies/about-us/about-us.component';
import { RefundPolicyComponent } from './policies/refund-policy/refund-policy.component';
import { LoadPaymentComponent } from './Party/load-payment/load-payment.component';
import { EbProcessPaymentComponent } from './Party/eb-process-payment/eb-process-payment.component';
import { authGuard, authSigninGuard } from './Guards/auth.guard';
import { activePlanGuard } from './Guards/active-plan.guard';
import { UserDashboardComponent } from './Party/user-dashboard/user-dashboard.component';
import { loaderGuard } from './Guards/loader.guard';
import { PayOrderDetailsComponent } from './Party/pay-order-details/pay-order-details.component';
import { ConvertToPerformerComponent } from './convert-to-performer/convert-to-performer.component';
import { adminRoute } from './Routes/adminroutes';
import { UserProfileComponent } from './_common/components/user-profile/user-profile.component';
import { adminGuard } from './Guards/admin.guard';
import { AdminDashboardComponent } from './Admin/dashboard/admin-dashboard.component';
import { CameraScanComponent } from './Party/camera-scan/camera-scan.component';
import { BaseTableComponent } from './_common/components/base-table/base-table.component';
import { CreateCatalogComponent } from './performer/Catalogs/create-catalog/create-catalog.component';
import { AddSongsToCatalogComponent } from './performer/Catalogs/add-songs-to-catalog/add-songs-to-catalog.component';
import { ScanRedirectionComponent } from './Party/scan-redirection/scan-redirection.component';

const routes: Routes = [
  
{path:'admin', children:adminRoute, canActivate:[authGuard,adminGuard]},
    {path: 'party/VerifyReturn',component: ProcessReturnComponent},
    {path:'', component:HomeComponent},
    {path:'PaymentSuccess', component:PaymentSuccessComponent},
    {path:'logon', component:LogonHomeComponent},{
      path:'auth/enter-otp', component:EnterOtpComponent
    },
    {path:'auth/redirect',component: RedirectResponseComponent},
    {path: 'performer/dashboard', component:DashboardComponent, canActivate:[PerformerAuthGuard,authGuard,activePlanGuard]},
    {path:'change-role', component:ConvertToPerformerComponent, canActivate:[authGuard]},
    {path:'performer', canActivate:[PerformerAuthGuard,authGuard], children:[
      {
        path:'plans/activate/starter', component:BillingCommissionDetailsComponent
      },
      {
        path:'onboarding', component:OnboardingComponent
      },
      {
        path:'plan', component:PlanComponent
      },
      {
        path:'plan/checkout', component: CheckoutComponent
      },
      {
        path:'SubscriptionReturn', component:SubscriptionReturnComponent
      },
      {path:'live',component:ConsoleComponent},
      {path:'create-catalog', component:CreateCatalogComponent},
      {path:':catalogId/tracks',component:AddSongsToCatalogComponent}
    ]},
    {path:'performer/auth',canActivate:[authSigninGuard], children:[
      {
        path:'enter-otp', component:EnterOtpComponent
      
    }
  ,{
    path:'sign-in', component:SignInComponent
  
}]},
{path:'Party/RequestDetails',component:PayOrderDetailsComponent},
    {path:'party/ProcessPayment',component:LoadPaymentComponent},
    {path:'party/ProcessResponse',component:EbProcessPaymentComponent},
{path:'scan-code',component:CameraScanComponent},
    {path:'party',component:RequestComponent, canActivate:[loaderGuard]},
    {path:'party/:performer', component :RequestComponent, canActivate:[loaderGuard]},
    {path:'redirect/party/:id',component:ScanRedirectionComponent,canActivate:[loaderGuard]},
    {path:'user/dashboard', component:UserDashboardComponent},
{path:'user-profile', component:UserProfileComponent, canActivate:[authGuard]},
{path:'privacy-policy',component:PrivacyPolicyComponent},
{path:'terms-and-conditions',component:TermsAndConditionsComponent},
{path:'about-us',component:AboutUsComponent},
{path:'refund-policy',component:RefundPolicyComponent},
{path:'pricing',component:PlanComponent},

{path:'test', component:BaseTableComponent},
{path:'**',component:HomeComponent}
];

const extraOptions: ExtraOptions = {
  // "enableTracing": true
  scrollPositionRestoration:'enabled',
  anchorScrolling:'enabled',
  scrollOffset:[0,90]
 };

@NgModule({
  imports: [RouterModule.forRoot(routes,extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
