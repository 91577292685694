import { Component, OnInit, Output ,EventEmitter, Input, OnDestroy} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EventService } from 'src/app/Services/event.service';
import { SnackbarService } from 'src/app/snackbar.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CatalogService } from 'src/app/Services/catalog.service';
import { BaseFilterQuery, CatalogType, Constants } from 'src/app/common/Contants/Contants';
import { isatty } from 'tty';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent implements OnInit, OnDestroy {
eventDetails : FormGroup
@Output() SubmitEvent = new EventEmitter<boolean>() 
@Output() CancelEvent = new EventEmitter<boolean>() 
@Output() CreateCatalogEvent = new EventEmitter<boolean>()
isDateEditable:boolean=true
editEvent:any
CatalogType = CatalogType
catalogList=[]
  constructor(private snackbar : SnackbarService, private builder :FormBuilder, private eventService : EventService,private catalogService:CatalogService) { }
  ngOnDestroy(): void {
    
  }
  dateTimeNow = new Date()
defaultDate: Date = new Date(new Date().setHours(this.dateTimeNow.getHours(), 0, 0, 0))
  ngOnInit(): void {
    this.eventService.EditEventSubject.subscribe((event)=>{
      this.editEvent=event
      this.UpdateEventForEdit();
    })
if(this.editEvent!=null){
  this.defaultDate = this.editEvent.startDate
}
    this.eventDetails = this.builder.group({
      Name:[this.editEvent?.name, [Validators.required]],
      StartDate:[this.defaultDate, [Validators.required]],
      EndDate:[this.defaultDate,[Validators.required]],
      Venue: [this.editEvent?.venue,[Validators.required]],
      MinBid:[this.editEvent?.minBid,],
      PriorityBid:['0'],
      IsActive:[false],
      catalogType:[this.editEvent?.catalogType || CatalogType[0].value,[Validators.required]],
      customCatalogs:[this.editEvent?.customCatalogs],
      IsFree:[false]
    })
  }
  UpdateEventForEdit(){
    let startDate;
    let endDate;
    let customCatalog
    if(this.editEvent!=null){
      startDate = new Date(this.editEvent.StartDate)
      endDate = new Date(this.editEvent.EndDate)
      if(this.editEvent.CustomCatalogs){
        customCatalog = 
          this.editEvent.CustomCatalogs?.map((catalog:any)=>{
            return {
              Id:catalog.Id,
              Name:catalog.Name,
              Tags:catalog.Tags
            }
          })
        
      }
      
    }
    
    if(startDate){
      this.isDateEditable=startDate<=new Date()?false:true
    }
        this.eventDetails = this.builder.group({
          Name: [{value:this.editEvent?.Name, disabled:true}, [Validators.required]],
          StartDate:[{value:startDate,disabled:!this.isDateEditable}, [Validators.required]],
          EndDate:[{value:endDate,disabled:!this.isDateEditable},[Validators.required]],
          Venue: [this.editEvent?.Venue,[Validators.required]],
          MinBid:[{value:this.editEvent?.MinBid, disabled:!this.isDateEditable}],
          PriorityBid:[this.editEvent?.PriorityBid],
          IsActive:[this.editEvent?.IsActive],
          catalogType:[this.editEvent?.CatalogType,[Validators.required]],
          customCatalogs:[customCatalog],
          IsFree:[{value:this.editEvent?.IsFree,disabled:!this.isDateEditable}]
        })
        this.SearchCatalogs(this.eventDetails)
  }
  Cancel(){
    if(this.eventDetails.dirty){
      let isAgree = window.confirm("Are you sure you want to leave this page? All the details will be lost.")
      if(isAgree){
        this.eventDetails.reset() 
        this.CancelEvent.emit(true);  
      }
    }else{
      this.eventDetails.reset() 
      this.CancelEvent.emit(true);  
    }
    
  }
  SubmitAndActivate(){
this.eventDetails.controls['IsActive'].setValue(true)
this.Submit();
  }
  Save(){
    this.eventDetails.controls['IsActive'].setValue(false)
this.Submit();
  }
  ValidateForm(checkDates=true){
    if(this.eventDetails.invalid ){
      throw 'Please fill required details'
    }
    if(checkDates){
    var dt = this.eventDetails.get('StartDate')?.value
    if( new Date(this.eventDetails.get('StartDate')?.value)< new Date()){
      throw 'Start Date should be in future'
    }
    if(new Date(this.eventDetails.get('StartDate')?.value) > new Date(this.eventDetails.get('EndDate')?.value)){
      throw 'Start Date should be less than end date.'
    }
  }
  if(!this.eventDetails.controls.IsFree.value && this.eventDetails.controls.MinBid.value as number <=0){
    throw 'Minimum bid cannot be less than or equal to 0. Try raising the amount.'
  }

    // if(Number(this.eventDetails.get('MinBid')?.value) >=Number(this.eventDetails.get('PriorityBid')?.value)){
    //   throw 'Minimum Bid should be lower than Priority Bid'
    // }
    // if(Number(this.eventDetails.get('MinBid')?.value) >=0){
    //     throw 'Minimum Bid should be greater than 0'
    //   }
  }
  Submit(){
    this.ValidateForm()  
    var eventData = this.eventDetails.value;
    
    this.eventService.saveEvent(eventData).subscribe((result)=>{
      if(result){
        this.snackbar.CreateNotification('Event create successfully')
 this.SubmitEvent.emit(true);
      }
    })
  }
  Update(){
    if(this.eventDetails.get('StartDate')?.dirty || this.eventDetails.get('EndDate')?.dirty){
      this.ValidateForm()
    }else{
      this.ValidateForm(false)
    }
    var eventData = this.eventDetails.value;
    
    this.eventService.updateEvent(this.editEvent.Id, eventData).subscribe((result)=>{
      if(result){
        this.snackbar.CreateNotification('Event updates successfully')
 this.SubmitEvent.emit(true);
      }
    })

  }
  onlyNumberKey(evt:any) {
             
    // Only ASCII character in that range allowed
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
}
   
SearchCatalogs(event:any){
  if(this.eventDetails.value.catalogType == 'custom'){
    this.catalogService.GetCatalogsAsync(BaseFilterQuery).subscribe((catalogs:any)=>{
      this.catalogList = catalogs.data
    })
  }
}
LoadCatalogs(event:any){
  console.log(event)
}
CreateCatalogClicked(){
  if(this.eventDetails.dirty){
    let isAgree = window.confirm("Are you sure you want to leave this page? All the details will be lost.")
    if(isAgree){
      this.CreateCatalogEvent.emit()
    }
  }else{
    this.CreateCatalogEvent.emit()
  }
  
}
}
