import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SseService {
eventSource:EventSource
errorRetry=0
  constructor() { }
  createEventSource(relativeUrl:string):Observable<any>{
this.eventSource = new EventSource(environment.api+relativeUrl)
return new Observable(observer=>{
  this.eventSource.onmessage=event =>{
    if(event.data!=''){
      const messageData  = JSON.parse(event.data || '{}');
      observer.next(messageData);
    }
    this.errorRetry=0

  }
  this.eventSource.onerror =event=>{
    if(this.errorRetry==10){
    observer.error('error');
    this.eventSource.close()
    }
    this.errorRetry++
  }

})
  }
  CloseEventSource(){
    if(this.eventSource.readyState==this.eventSource.OPEN){
      
    }
  }
}
