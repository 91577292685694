import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-selected-track',
  templateUrl: './selected-track.component.html',
  styleUrls: ['./selected-track.component.css']
})
export class SelectedTrackComponent {
  @Input() track :any
  @Input() isCustomTrack:boolean = false
constructor(){}
}
