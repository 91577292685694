<!-- <div class="d-block" style="height: 2rem;">


<div class="d-none flex-row" style="column-gap: 30px;" *ngIf="selectedEvent.length==1">  
    
    <ul class=" mx-1 mx-lg-3 my-auto" style="list-style: none; list-style-type: 0s;">
        <li class="p-0" style="float: left;">
            <app-navlink icon="bi bi-pen" label="Edit" (action)="EditClicked()"></app-navlink>
        </li>
        <li class="p-0 d-inline-block" style="float: left;">
            <app-navlink icon="bi bi-trash" label="Delete" (action)="DeleteClicked()"></app-navlink>
        </li>
      </ul>
</div>
</div> -->
<div class="d-block p-4" style="background-color: var(--frame-color);">
<app-base-table [dataObj]="eventsList" [tableConfiguration]="tableConfiguration" [totalRecords]="totalEvents" [tableHeaderTemplate]="tableHeader" /> 
<!-- <p-table [value]="eventsList" [tableStyle]="{ 'min-width': '50rem'}"
responsiveLayout="stack" [breakpoint]="'960px'" class="event-table" (onRowSelect)="OnCheckboxChanged($event)" (onRowUnselect)="OnCheckboxUnchecked($event)" >
    <ng-template pTemplate="header">
        <tr class="event-table-header">
            <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Name</th>
            <th>Venue</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Action</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event let-ri="rowIndex">
        <tr [pSelectableRow]="event" class="event-table-data"  >
            <td>
                <p-tableCheckbox [value]="event"  ></p-tableCheckbox>
            </td>
            <td>{{event.name}}</td>
            <td>{{event.venue}}</td>
            <td>{{event.startDate | date :'short'}}</td>
            <td>{{event.endDate | date :'short'}}</td>
            <td><mat-slide-toggle [(ngModel)]="event.isActive"  (change)="OnToggleEvent(event,ri)" >{{event.isActive? 'Active':'Inactive'}}</mat-slide-toggle>

            </td>
            <td><i class="pi pi-check event-save" *ngIf="event.isDirty" (click)="OnEventSave(event,ri)"></i><i class="pi pi-times event-cancel" *ngIf="event.isDirty" (click)="OnEventCancel(event,ri)"></i></td>
            
        </tr>
    </ng-template>
</p-table> -->
</div>
<p-dialog header="Edit Event" [modal]="true" [(visible)]="isEventEdit" [style]="{width:'90%', height:'85%'}">
    <app-create-event (SubmitEvent)="editComplete()" (CancelEvent)="isEventEdit=false"></app-create-event>
</p-dialog>
<ng-template #status let-data="data" let-item="item">
    <!-- <p-tag [value]="data[item.name]"></p-tag> -->
    <p-button icon="pi pi-chevron-right"  [rounded]="true"  [text]="true" severity="info" (onClick)="OpenEventDetails(data)"></p-button>
</ng-template>
<ng-template #tableHeader>
    <p-toolbar [style]="{padding:'0px', border:'none'}"  *ngIf="selectedEvent.length==1">
        <div class="p-toolbar-group-start">

            <button pButton icon="pi pi-pencil" label="Edit" class="me-2" (click)="EditClicked()"></button>
            <button pButton icon="pi pi-trash"  label="Delete" class="me-2" (click)="DeleteClicked()"></button>
            
        </div>
    </p-toolbar>
</ng-template>