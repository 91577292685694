import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  baseUrl = '@api-dev/Catalog'
  constructor(private http:HttpService){}
  GetCatalogsCount(){
    return this.http.Get(`${this.baseUrl}/count`,false)
  }
  CreateCatalog(data: any) {
    return this.http.Post(`${this.baseUrl}`,data,null,true);
  }
  GetSongsList(catalogId:any,query:any){
    return this.http.Get(`${this.baseUrl}/${catalogId}/tracks?query=${query}`,true);
  }
  InsertSongAsync(catalogId:any,data:any){
    return this.http.Post(`${this.baseUrl}/${catalogId}/tracks`,data,null,true);
  }
  GetCatalogsAsync(query:string){
    return this.http.Get(`${this.baseUrl}?query=${query}`,true);
  }
  deleteTrackFromCatalog(catalogId:any, id:any){
    return this.http.Delete(`${this.baseUrl}/${catalogId}/track/${id}`,null,null,true)
  }
  deleteCatalog(catalogId:any){
    return this.http.Delete(`${this.baseUrl}/${catalogId}`,null,null,true)
  }
}
