import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserStateService } from '../_common/services/user-state.service';
import { inject } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const adminGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const userState = inject(UserStateService);
  if (!userState.isLoading$.getValue()) {
    return validateLogon(state,userState,router)
  }
  return userState.isLoading$.pipe(
    filter(x=> x===false) ,
    map(x=> {if(x===false){
      return validateLogon(state,userState)
    }
  return false}),
    take(1)
  )
  
};
const validateLogon :Function =(state:RouterStateSnapshot,userState:UserStateService,router:Router)=>{ 
   const isLoggedIn = userState.currentUser$.getValue()!=null

if(isLoggedIn){
  let isAdmin = userState.hasClaim('role','admin')
  if(isAdmin){  
   return true
  }
}
return router.navigate([''])
}

