import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QrService } from 'src/app/Services/qr.service';

@Component({
  selector: 'app-scan-redirection',
  templateUrl: './scan-redirection.component.html',
})
export class ScanRedirectionComponent implements OnInit{
  mode: string | null;
  id: string | null;
constructor(private route:ActivatedRoute,private qrService:QrService, private router:Router){
  this.route.queryParamMap.subscribe((query)=>{
    if(query.has("mode")){
      this.mode=query.get("mode")
    }
  })
  this.route.paramMap.subscribe((params)=>{
    if(params.has("id")){
      this.id=params.get("id")
    }
  })
}

  ngOnInit(): void {
    if(this.id){
      this.qrService.getPerformerNameForRedirection(this.id,this.mode).subscribe((response:any)=>{
        if(response){
          this.router.navigate(['party',response])
        }
      })
    }
    this.router.navigate(['party'])
  }
}
