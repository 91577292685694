import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
private baseurl="@api-dev"
  constructor(private http: HttpClient) {
    
   }
  searchSpotify(endpoint:string){
    return this.http.get(this.baseurl+endpoint);
  }
  PayDj(amount: number){
return this.http.post(this.baseurl+"/Payments/Pay",amount);
  }
}
