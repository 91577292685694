import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { updateEmail, updateProfile } from 'firebase/auth';
import { AuthService } from 'src/app/Services/auth.service';
import { StateService } from 'src/app/Services/state.service';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { AuthConstants, UserStateConstant } from 'src/app/common/Contants/Contants';
import { CreateUserModel } from 'src/app/models/createUserModel';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {
userProfile :UntypedFormGroup
user: any
userProfileSession:any

  constructor(private authService : AuthService, private formBuilder : UntypedFormBuilder, private router : Router, private stateService :StateService,
    private userStateService:UserStateService) { }

  ngOnInit(): void {
    this.userProfileSession = this.userStateService.currentUser$.getValue();
    if(this.userProfileSession.userStage!=UserStateConstant.ONBOARDING){this.router.navigate(['performer/dashboard'])}
     var phoneNumber;
     if(this.userProfileSession.phoneNumber!=null)
     phoneNumber = this.userProfileSession.phoneNumber?.indexOf('+91')!=-1?this.userProfileSession.phoneNumber.substring(3):this.userProfileSession.phoneNumber;
    this.userProfile = this.formBuilder.group({name :[this.userProfileSession.name, Validators.required],
    emailAddress: new FormControl({value:this.userProfileSession.emailId, disabled:this.userProfileSession.emailId!=""},Validators.required) ,
    phoneNumber: new FormControl({value:phoneNumber, disabled:this.userProfileSession.phoneNumber!=""},Validators.required),
  countryCode: ['+91', Validators.required]}

    )
  //   if(this.authService.userData==null){

  //   this.authService.UserDetailsSubject.subscribe((user :any)=>{
  //     this.updateUserProfile(user);
  //   })
  // }
  // else{
  //   this.updateUserProfile(this.authService.userData)
  // }
  }
  // private updateUserProfile(user : any){
  //   this.user=user
  //   this.userProfile.patchValue({
  //     name:user?.displayName,
  //     phoneNumber: user?.phoneNumber.substring(3)
  //   })
  // }
onSubmit(){
  if(this.userProfile.valid){
    var model :CreateUserModel = {
      emailAddress :this.userProfile.controls['emailAddress'].value,
      name : this.userProfile.controls['name'].value,
      authProvider : this.userProfileSession.authProvider,
      countryCode : this.userProfile.controls['countryCode'].value,
      fuID : this.userProfileSession.id,
      role: 'performer',
      phoneNumber: this.userProfile.controls['phoneNumber'].value,
      profilePicture: this.userProfileSession.profilePicture
    }
    
      this.authService.CreateUserV2(model).subscribe((result)=>{
        this.userStateService.refreshToken().then((response=>{
if(response){
  this.router.navigate(['performer/plan'])
}else{
  this.userStateService.SignOut()
}
        }));
        
      }, (error)=>{
        throw error;
      })
    
  
  }
}
}
