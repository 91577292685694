import { Component } from '@angular/core';
import * as $ from 'jquery'
import { initializeApp } from 'firebase/app';
import { AuthService } from './Services/auth.service';
import { CommonService } from './_common/services/common.service';
import { LoaderService } from './loader.service';
import { UserStateService } from './_common/services/user-state.service';
import { NavService } from './Services/nav.service';
import { NavItem } from './models/navItem';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent  {
  title = 'Jumr- A song request app for DJs, Singers, performers';
user :any;
sidebarVisible:boolean=false
  constructor(public authService :AuthService, public userState:UserStateService,private loader:LoaderService, public navService:NavService,
    private router:Router, private titleService:Title){
      titleService.setTitle(this.title)
if(userState.User!=null){
  // userState.refreshToken().then(()=>{}).catch((error)=>{
  //   console.log(error)
  // })
  userState.UpdateUserDetailsSubject.next(null)
}

    
      
    
    // this.authService.UserDetailsSubject.subscribe(()=>{
    //   if(this.authService.userData){
    //   this.authService.getUser()}
    // })

  }
  triggerNavItem(event:any){
    event.event()
  }
  returnToHome(){
    this.router.navigate([],{fragment:'home'})
  }
}
