<div class="p-5 d-flex flex-column">
<h1>
    Add Billing Details
</h1>
<form [formGroup]="billing" (ngSubmit) = 'onSubmit()' novalidate>
    <div class="form-group">
<label class="form-element" for="fullName">Full Name</label>
<input  type="text" name="fullName" formControlName="fullName"/>
    </div>
<div class="form-group">
<label class="form-element" for="email">Email ID</label>
<input type="text" name="email" formControlName="email"/>
</div>
<div class="form-group">
<label class="form-element" for="phone">Mobile Number</label>
<input type="text" name="phone" formControlName="phone"/>
</div>
<div class="form-group">
<label class="form-element" for="upi">UPI ID</label>
<input type="text" name="upi" formControlName="upi"/>
</div>
<button type="submit" class="btn btn-primary"> Submit</button>
</form>
</div>
