import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';


import { UserStateService } from '../_common/services/user-state.service';

import { filter, map, take } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ElementSchemaRegistry } from '@angular/compiler';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const userState = inject(UserStateService);
  if (!userState.isLoading$.getValue()) {
    return validateLogon(state,userState,router)
  }
  return userState.isLoading$.pipe(
    filter(x=> x===false) ,
    map(x=> {if(x===false){
      return validateLogon(state,userState)
    }
  return false}),
    take(1)
  )
  

};
export const authSigninGuard:CanActivateFn=(route,state)=>{
  const userState = inject(UserStateService);
  const router = inject(Router)
  if (!userState.isLoading$.getValue()) {
    const isLoggedIn = userState.currentUser$.getValue()!=null
    if(isLoggedIn){
      return  router.navigate(['performer','dashboard'])
    }
    return !isLoggedIn
  }
  return userState.isLoading$.pipe(
    filter(x=> x===false) ,
    map(x=> {if(x===false){
      const isLoggedIn = userState.currentUser$.getValue()!=null
      if(isLoggedIn){
         router.navigate(['performer','dashboard'])
      }else
      return !isLoggedIn
    }
  return false}),
    take(1)
  )
}

const validateLogon :Function =(state:RouterStateSnapshot,userState:UserStateService,router:Router)=>{
const isLoggedIn = userState.currentUser$.getValue()!=null

if(isLoggedIn){
  if(state.url.includes('/performer/auth')){
    
   return  router.navigate(['performer','dashboard'])
  }
  else 
  return true
}
else{
  return router.navigate(['/performer/auth/sign-in'], { queryParams: { redirectUrl: state.url } })

}
}
  

