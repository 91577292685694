import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartyService } from 'src/app/Services/party.service';
import { Constants } from 'src/app/common/Contants/Contants';
@Component({
  selector: 'app-pay-order-details',
  templateUrl: './pay-order-details.component.html',
  styleUrls: ['./pay-order-details.component.css']
})
export class PayOrderDetailsComponent implements OnInit {
  requestId:any
  orderDetails:any
  constants= Constants
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params)=>{
      if(params.has('requestId')){
        this.requestId = params.get('requestId')
        this.partyService.GetPayOrderDetails(this.requestId).subscribe((response)=>{
          if(response){
          this.orderDetails=response
        }
        })
      }
      
    })
  }
  constructor(private router:Router ,private route:ActivatedRoute, private partyService:PartyService, private location:Location){}
  returnButtonClicked(){
    this.location.back()
  }
}
