import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { UserStateService } from '../_common/services/user-state.service';
import { SnackbarService } from '../snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-convert-to-performer',
  templateUrl: './convert-to-performer.component.html',
  styleUrls: ['./convert-to-performer.component.css']
})
export class ConvertToPerformerComponent implements OnInit {
  constructor(private authService:AuthService, private userState:UserStateService, private notificationService:SnackbarService, private router:Router){}
  ngOnInit(): void {
    let isPerformer = this.userState.hasClaim('role','performer')
    if(isPerformer){
      this.router.navigate(['performer','dashboard'])
    }
  }
  switchAccount(){
      this.authService.SwitchRole('performer').subscribe(()=>{
        this.notificationService.CreateNotification('Account switched. Please sign in again')
        this.userState.SignOut()
      })
  }
  redirectToUser(){
    this.router.navigate(['user','dashboard'])
  }
}
