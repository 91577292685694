<div class="d-block h-100 user-widget" matRipple *ngIf="this.userState.userDetails!=null" style="cursor: pointer;" (click)="op.toggle($event)">
<div class="d-flex flex-row h-100 px-2">
    <div class="profile-picture h-100 d-flex m-auto" style="align-items: center;">
        <p-avatar image="{{this.userState.userDetails.profilePicture!=''? this.userState.userDetails.profilePicture: this.defaultPhotoUrl}}" [style]="{'display':'flex','margin-top':'auto','margin-bottom':'auto'}" shape="circle"></p-avatar>
        <!-- <img class="d-block my-auto" src="{{this.userState.userDetails.profilePicture!=''? this.userState.userDetails.profilePicture: this.defaultPhotoUrl}}"> -->
    </div>
    <div class="d-none d-sm-inline-block m-auto px-2" style="min-width: 150px;">
        <label class="w-100">{{this.userState.userDetails.name}}</label>
        <span class="fw-r" style="color: var(--secondary-color); font-size: 12px;">{{this.userState.plan? this.userState.plan : ' '}}</span>
    </div>
</div>

</div>
<p-overlayPanel #op [style]="{background:'var(--frame-color)', width:'inherit'}" >
    <ng-template pTemplate="content">
        <div class="user-widget-context d-flex flex-column fw-r p-2" style="width: inherit;" (click)="op.hide()">
            <div class="d-flex flex-row my-auto px-1 py-1 user-widget-item"><i class="bi bi-grid d-flex white mx-2 my-auto"></i>  <a class="d-flex my-auto nav-link" (click)="dashboardClicked()">Dashboard</a></div>
            <div class="d-flex flex-row my-auto px-1 py-1 user-widget-item"><i class="bi bi-person d-flex white mx-2 my-auto"></i>  <a class="d-flex my-auto nav-link" (click)="myProfileClicked()">My profile</a></div>
            <div class="d-flex flex-row my-auto px-1 py-1 user-widget-item"><i class="bi bi-box-arrow-left d-flex white mx-2 my-auto"></i><a href="javascript:void(0)" class="d-flex my-auto nav-link" (click)="logoutUser()">Sign out</a></div>
        </div>
    </ng-template>
</p-overlayPanel>
<!-- <div style="position: relative; width: inherit; height: 100px; background-color: red; z-index: 1001; margin-top: 10px; border-radius: 10px;" class="p-2">
<ul style="list-style: none;">
    <li> My profile</li>
    <li>Logout</li>
</ul>
</div>
 <div class="user-widget-context-wrap">
    

</div> -->


<!-- class="d-md-inlineblock m-auto px-2 d-sm-none d-xs-none" -->