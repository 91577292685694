<div class="d-flex flex-column p-3">
<h4 class="mb-3">Request History</h4>
<div class="d-flex flex-column p-3 mb-1" style="background-color: var(--element-color); border-radius: 10px;" *ngFor="let request of requests"> 
    <app-selected-track [track]="request.track" class="w-100"></app-selected-track>
    <div class="d-flex flex-column p-2 mt-2">          
        <div class="d-flex flex-row justify-content-between my-1">
        <label class="fw-r row-header">Amount</label>
        <label class="fw-r">{{constants.RUPEES_SIGN}}{{request.amount}}</label>
    </div>
    <div class="d-flex flex-row justify-content-between my-1">
        <label class="fw-r row-header">Performer</label>
        <label class="fw-r">{{request.performerName.name}}</label>
    </div>
    <div class="d-flex flex-row justify-content-between my-1">
        <label class="fw-r row-header">Status</label>
        <p-tag  value="{{request.status}}"></p-tag>
    </div>
    </div>
</div>
</div>
