import { Component, OnInit } from '@angular/core';
import { PartyService } from 'src/app/Services/party.service';
import { Constants } from 'src/app/common/Contants/Contants';

@Component({
  selector: 'app-user-transactions-list',
  templateUrl: './user-transactions-list.component.html',
  styleUrls: ['./user-transactions-list.component.css']
})
export class UserTransactionsListComponent implements OnInit {
  requests: any;
  constants=Constants
  constructor(private partyService:PartyService){}
  ngOnInit(): void {
    this.partyService.GetAllPayOrders().subscribe((response:any)=>{
      if(response!=null && response.length>0){
        this.requests = response
      }
    })
  }

}
