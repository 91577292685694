import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SseService } from './sse.service';
import { Subject } from 'rxjs';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class EventService {
EventStreamSubject:Subject<any> = new Subject<any>()
EditEventSubject:Subject<any>=new Subject<any>();
  constructor(private http: HttpClient, private sseService : SseService, private http2 : HttpService) { }
  saveEvent(model :any){
    return this.http2.Post('@api-dev/Events/',model,null,false);
  }
  updateEvent(id:string,model:any){
    return this.http2.Post(`@api-dev/Events/Edit/${id}`,model,null,true);
  }
  UpdateEvent(event:any){
    return this.http2.Post('@api-dev/Events/UpdateEvent',event,null,false)
  }
  GetAllEvents(){
    return this.http2.Get('@api-dev/Events/GetAllEvents',true);
  }
  FilterAllEvents(filter:any){
    return this.http2.Get(`@api-dev/Events/FilterAllEvents?query=${filter}`,true)
  }
  DeleteEvent(id:string){
    return this.http2.Post(`@api-dev/Events/Delete/${id}`,null,null,true)
  }
  getEventById(eventId:string){
    return this.http2.Get('@api-dev/Events/GetEvent?eventId='+eventId,false)
  }
  GetTransactionSummary(){
    return this.http2.Get('@api-dev/Events/GetTransactionHistory',true)
  }
  getRequestsbyEventStream(model :any){
    let url ='/Events/StreamRequests?slt='+ model
      return this.sseService.createEventSource(url);
  }
  RequestPermissionForStream(model:any){
    return this.http2.Post('@api-dev/Events/PostPermissionForStream',model,null,false);
  }
  GetEventDashboardDetails(){
    return this.http2.Get('@api-dev/Events/GetEventSummary',false)
  }
  GetUpcomingEvents(){
    return this.http2.Get('@api-dev/Events/GetUpcomingEvents',false)
  }
  CloseEventListening(){
    this.sseService.CloseEventSource()
  }
  AcceptRequest(id:string){

    let headers = new HttpHeaders()
    headers = headers.append('content-type','application/json')
    headers=headers.append('requestId',id)
    return this.http2.Post('@api-dev/Events/Console/Action/Accept',null,headers,false)
  }
  RejectRequest(id:string){

    let headers = new HttpHeaders()
    headers = headers.append('content-type','application/json')
    headers=headers.append('requestId',id)

    return this.http2.Post('@api-dev/Events/Console/Action/Reject',null,headers,false)
  }
  InitRequests(token:string,callerId:string){
    this.http2.Post(`@api-dev/Events/InitSocket?token=${token}&callId=${callerId}`,null,null,false)
  }
  GetAllRequestsForEvent(eventId:string, first:number, rows:number){
    return this.http2.Get(`@api-dev/Events/GetAllRequests/${eventId}?Skip=${first}&Limit=${rows}`,true)
  }
  GetEventDetails(id:string){
    return this.http2.Get(`@api-dev/Events/GetEventDetails/${id}`,true)
  }

}
