import { Component, OnInit } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { TableRowSelectEvent, TableRowUnSelectEvent } from 'primeng/table';
import { TableHeaderItem } from 'src/app/models/TableHeaderItem';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-admin-jobs-dashboard',
  templateUrl: './admin-jobs-dashboard.component.html',
  styleUrls: ['./admin-jobs-dashboard.component.css']
})
export class AdminJobsDashboardComponent implements OnInit {
  jobs:any[] = [
    {
      name:'Event Complete',
      value:'job-event-complete'
    },
    {
      name:'Event Reconcile',
      value:'job-event-reconcile'
    },
    {
      name:'Payment Process',
      value:'job-payment-process'
    }
  ]
  SelectedJob:any
  constructor(private adminService:AdminService){}
  ngOnInit(): void {
    this.tableConfiguration = {
     headers:this.columns,
     lazyLoad:(event:any)=>this.loadData(event),
     id:'events',
     loading:false
    }
   // this.adminService.getAllEvents().subscribe((events:any)=>{
   //   this.events = events
   // })
   this.totalRecords=100
   }
   columns:TableHeaderItem[] = [
     {
       displayName:'Job Name',
       name:'Name',
       type:'text',
       isVisible:true
     },
     {
       displayName:'Created Date',
       name:'CreateDate',
       type:'date',
       isVisible:true
     },
     {
       displayName:'Status',
       name:'Status',
       type:'text',
       isVisible:true
     },
     {
      displayName:'Message',
      name:'Message',
      type:'text',
      isVisible:true
    }
   
   ]
   jobList: any[];
   tableConfiguration: TableConfigurationModel;
   totalRecords: number;
    loadData(state:LazyLoadEvent) {
   
     var query = JSON.stringify(state);
     this.adminService.getAllJobs(query).subscribe((result:any)=>{
       this.jobList=result.data
       this.totalRecords = result.totalCount
     })
     
     }
     onJobsSelected(event:any){
      
     }
   
}
