import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getAuth, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, User } from "firebase/auth";
import { phoneResponse } from '../models/phoneResponseModel';
import { Observable, observable, Subject } from 'rxjs';
import { CreateUserModel } from '../models/createUserModel';
import { StateService } from './state.service';
import { AuthConstants } from '../common/Contants/Contants';
import { CommonService } from '../_common/services/common.service';
import { LoaderService } from '../loader.service';
import { HttpService } from './http.service';





@Injectable({
  providedIn: 'root'
})
export class AuthService {

  
  app: any
  userData: any | undefined
  claims: any = {}
  isPerformer = false
  isLoggedInSubject = new Subject<boolean>();
  userSubject = new Subject();
  claimsSubject = new Subject();
  UserDetailsSubject = new Subject();
  isPerformerSubject = new Subject<boolean|undefined>();

  userDetailsDbSubject = new Subject();
  dbUser :any
  constructor(private client: HttpClient,private state :StateService, private commonService:CommonService, private loader:LoaderService,private http:HttpService) {
    this.userDetailsDbSubject.subscribe((user)=>{
      this.dbUser=user;
    })
  }

  VerifyGoogleCredentials(credential: string):Observable<any> {
    var headers = new HttpHeaders();
var data = {
  userType:0
}
    headers = headers.append('content-type','application/json')
    headers = headers.append('token',credential)
 return this.http.Post(`@api-dev/auth/VerifyGoogleCredentials`,null,headers,true)
  }
  CreateUser(response: any, scope: any) {
    var user = { user: response, scope: scope }
    return this.client.post('@api-dev/auth/CreateUser', user);
  }
  async PhoneNumberSendOtp(phoneNumber: any, recaptcha: string): Promise<phoneResponse> {
    var response = new phoneResponse();
    var headers = new HttpHeaders().append("Content-Type","application/json")
    return new Promise(async (resolve,reject)=>{
      var data={
        phoneNumber:phoneNumber,
        token:recaptcha
      }
      await this.http.Post("@api-dev/auth/GeneratePhoneOtp",data,headers,true).subscribe((confirmationResult: any) => {

        response.isSuccess = true;
        response.verificationId = confirmationResult;
        return resolve(response)
  
      },(error: any) => {
        
        var response = new phoneResponse();
        response.isSuccess = false
        response.error = error
        return reject(response)
  
      });
    })
    
    
    


  }
  verifyOtp(otp: string, verifyCode: string) {
    var data = {
      otp:otp,
      verificationCode:verifyCode
    }
    return this.http.Post("@api-dev/auth/verifyPhoneOtp",data,null,true)


  }
  LogonWithProvider(provider: any) {
   
  }

  getUserToken() {
    
var token = this.state.GetSession(AuthConstants.TOKEN);
if(token==undefined){
  return undefined
}
  return token.accessToken;


  }
 
  
  logoutUser() {
    this.userData = undefined
  }

  isPerformerLoggedIn() {

    let user = sessionStorage.getItem('token')
    if (user != null) {
      return true
    }
    return false

  }
  private removeSessionStorage() {
    this.state.DestroyAllSessions()
    this.state.DestroyAlItems()
  }
  getUserDetails() {
    return this.userData
  }
 
  updateProfile(name: string, email:string){
    if(this.userData!=undefined){
   
    
  }
  throw 'user unavailable';
  }
  CreateUserV2(model :CreateUserModel){
    return this.client.post('@api-dev/auth/CreateUserV2', model);
  }
  // AuthStateUnsubscribe = this.auth.onAuthStateChanged((user) => {
  //   if (user) {

  //     this.UserDetailsSubject.next(this.userData = user);
  //     this.claims = user.getIdTokenResult().then((result) => {

  //       this.claimsSubject.next(this.claims = result.claims);
  //       this.isPerformerSubject.next(this.isPerformer = this.hasClaim('role', 'performer'));

  //     })
  //   }
  //   else {
  //     this.signOutActivities()
  //   }
  //   if(this.commonService.isLoading){
  //     this.commonService.isLoading=false
  //     this.loader.setLoading(false)
  //   }
    
  // })
  
  //Private Method performs signOut activites
  private signOutActivities() {
    this.claims = null;
    this.isPerformerSubject.next(undefined)
    this.claimsSubject.next(undefined)
    this.isPerformer = false
    this.removeSessionStorage();
  }
  getUser(){
    // var isLoggedIn = this.state.GetSession(AuthConstants.TOKEN)
    // if(isLoggedIn!=undefined){
     return this.http.Get('@api-dev/Auth/GetUser',false)
//      .subscribe((user)=>{
// // this.userDetailsDbSubject.next(user);
//      })}
  }
  SwitchRole(role:string){
   
    var headers= new HttpHeaders().append('Content-Type','application/json')
    return this.http.Post('@api-dev/auth/switchAccount',JSON.stringify(role), headers,true)
  }
  refreshToken(){
    var headers= new HttpHeaders().append('Content-Type','application/json')
    return this.http.Post('@api-dev/auth/RefreshToken',null,headers,true)
  }
  signOut(){
    return this.http.Post("@api-dev/auth/SignOut",null,null,true)
  }
  
  SendQuestion(body:any){
    return this.http.Post('@api-dev/ContactUs',body,null,true)
  }

}
