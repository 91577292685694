import { Component, OnInit } from '@angular/core';
import { stat } from 'fs';
import { MenuItem } from 'primeng/api';
import { DataViewLazyLoadEvent } from 'primeng/dataview';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { EventService } from 'src/app/Services/event.service';
import { CatalogType } from 'src/app/common/Contants/Contants';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';
import { TableHeaderItem } from 'src/app/models/TableHeaderItem';

@Component({
  selector: 'app-view-event',  
  templateUrl: './view-event.component.html',
  styleUrl: './view-event.component.css'
})
export class ViewEventComponent implements OnInit {
tabs:MenuItem[]=[
{
  label:'Details',
},{
  label:'Summary'
}
]
CatalogType=CatalogType
eventCatalog:any
activeTab:MenuItem = this.tabs[0]
selectedTab=this.activeTab
eventDetails:any
allRequests:any
totalRequests=0
constructor(private config:DynamicDialogConfig,private eventsService:EventService ){}
  ngOnInit(): void {
    this.eventDetails = this.config.data
    this.eventCatalog=CatalogType.find(x=>x.value ==this.eventDetails.CatalogType )
    this.eventsService.GetEventDetails(this.eventDetails.Id).subscribe((response:any)=>{
      this.eventDetails=response
    })
 
  
  }
OnTabChanged(event:MenuItem){
  this.selectedTab = event
}
getStatusSeverity(status:string){
  if(status == "ACCEPTED" || status =="COMPLETE"){
    return 'success'
  }
  else return 'danger'
}
getFinalStatus(status:string){
  if(status == "ACCEPTED" || status =="COMPLETE"){
    return 'ACCEPTED'
  }
  else return 'REJECTED'
}
LoadRequests(event:DataViewLazyLoadEvent){
  if(new Date(this.eventDetails.EndDate)<=new Date()){
    this.eventsService.GetAllRequestsForEvent(this.eventDetails.Id, event.first, event.rows).subscribe((response:any)=>{
      this.allRequests = response.data
      this.totalRequests=response.totalCount
    })
   
  }
}
}
