import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogService } from 'src/app/Services/catalog.service';
import { EventService } from 'src/app/Services/event.service';
import { StateService } from 'src/app/Services/state.service';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { AuthConstants, Constants } from 'src/app/common/Contants/Contants';
import { SnackbarService } from 'src/app/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tab-dashboard',
  templateUrl: './tab-dashboard.component.html',
  styleUrls: ['./tab-dashboard.component.css']
})
export class TabDashboardComponent implements OnInit {
  totalEvents: any;
  totalEarnings: any;

todayEvents:any
  totalCatalogs: any;
Constants=Constants
  constructor(private eventService : EventService,private state :StateService, private router :Router, public userState:UserStateService, private catalogService:CatalogService
    ,private snackBar:SnackbarService
  ) { }

  ngOnInit(): void {
    this.eventService.GetEventDashboardDetails().subscribe((response:any)=>{
      if(response.TotalEvents!=undefined){
        this.totalEvents= response.TotalEvents
      }
      else{
        this.totalEvents = '--'
      }
      if(response.TotalEarnings!=undefined){
this.totalEarnings=response.TotalEarnings
      }
      else{
        this.totalEarnings = '--'
      }
      //this.plan = this.state.GetSession(AuthConstants.PLAN).planName

    })
    this.eventService.GetUpcomingEvents().subscribe((lstEvents:any)=>{
this.todayEvents=lstEvents
    })
    this.catalogService.GetCatalogsCount().subscribe((count)=>{
      if(count){
        this.totalCatalogs=count
      }
    })
  }
  goToConsole(event:any){
    if(event!=null){
      let targetUrl = `${window.location.origin}/performer/live?eventId=${event.id}`
      window.open(targetUrl,'_blank');
// this.router.navigate(['performer/live'],{queryParams:{'eventId':event.id}})
    }
  }
  shareLink(event:any){
    let eventlink =`${window.location.origin}/performer/live?eventId=${event.id}`
    if (!navigator.share){
      navigator.clipboard.writeText(eventlink);
      this.snackBar.CreateNotification("Link is copied to clipboard")
      return
    }
    navigator.share({
      url: eventlink,
      title: `${event.name} console link`,
      text: `Please find link to the event ${event.name}`
  })
  
  }

}
