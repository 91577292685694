import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/Services/auth.service';
import { NavService } from 'src/app/Services/nav.service';
import { PartyService } from 'src/app/Services/party.service';
import { SpotifyService } from 'src/app/Services/spotify.service';
import { StateService } from 'src/app/Services/state.service';
import { OtpComponent } from 'src/app/_common/components/otp/otp.component';
import { PhoneNumberComponent } from 'src/app/_common/components/phone-number/phone-number.component';
import { UserStateService } from 'src/app/_common/services/user-state.service';
import { Constants, UserStateConstant } from 'src/app/common/Contants/Contants';
import { NavItem } from 'src/app/models/navItem';
import { PayRequestModel } from 'src/app/models/payRequestModel';
import { PendingRequestsModel } from 'src/app/models/pendingRequestsModel';
import { constants } from 'zlib';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css'],
  animations:[
    trigger("cameraAnimation",[
      state("shown",
      style({       
        opacity:1
        
      })),
      state('hidden',style({       
        opacity:0,
        left:'-4rem'
  
      })),
      transition('hidden => shown',[
        animate('0.6s 0.1s ease-in-out')
      ])
    ])
  ]

})

export class RequestComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(PhoneNumberComponent) phoneComponent: PhoneNumberComponent|null;
  @ViewChild(OtpComponent) otpComponent: OtpComponent|null;
  cameraAnimationState='hidden'
  performerId: string |undefined
  eventDetails : any
  paramSearch:boolean = false
  timeout: number | undefined;
  trackList:any = null
  selectedTrack: any;
  finalBid:string
  isPriorityBid:boolean =false
  priceError:any
  phoneAuthStage:number=0
  isLogonRequired: boolean
  verificationId: any;
  phoneNumber: string;
  isSearchingComplete:boolean=true
  pendingRequests: PendingRequestsModel[]|null
  pendingRequestSubject: Subject<any> = new Subject()
  userLoggedInSubject: Subject<any> = new Subject()
  pendingRequestTimer:any
  isPayPressed: boolean;
  isCustomSearch:boolean=false
  constructor(private route : ActivatedRoute, private partyService : PartyService, private router : Router, private spotifyService : SpotifyService, 
    private authService :AuthService, private zone:NgZone
    ,private userState:UserStateService, private stateService : StateService, private navService:NavService ){
    route.paramMap.subscribe((params)=>{
      if(params.keys.includes('performer')){
        this.paramSearch= true
      this.FindandCheckInPerformerInternal(String( params.get('performer')))}else{
        let eventStoreDetails = this.stateService.GetItem(Constants.LIVEEVENT)
        if(eventStoreDetails){
          if(new Date(eventStoreDetails.eventEndDate)> new Date() && eventStoreDetails.performerId!=undefined){
            this.router.navigate([eventStoreDetails.performerId],{
              relativeTo:this.route,
              
            })
          }else{
            this.stateService.RemoveItem(Constants.LIVEEVENT)
          }
        }
      }
    })
    

  }
  ngAfterViewInit(): void {
    this.cameraAnimationState='shown'
  }
  ngOnDestroy(): void {
    if(this.pendingRequestSubject.observers.length>0){
    this.pendingRequestSubject.unsubscribe()
    clearTimeout(this.pendingRequestTimer)
    
    }
  }
  ngOnInit(): void {
    this.navService.RemoveAllfromNav()
    this.pendingRequestSubject.subscribe(()=>{
      // let pendingRequests:PendingRequestsModel[] = this.stateService.GetItem(Constants.PENDING_REQUESTS)
    if(this.pendingRequests!=null && this.pendingRequests.length>0){
      let acceptedIndex = this.pendingRequests.findIndex(x=>x.status=='ACCEPTED'); 
      if(acceptedIndex>-1){
        this.pendingRequests.splice(acceptedIndex,1)
        let dummyArr = this.pendingRequests
        this.pendingRequests =dummyArr
      }
      if(this.pendingRequests.length==0){
        this.pendingRequests=null
        return;}
     const requestIds= this.pendingRequests.map(x=>x.id)
      this.partyService.GetPayOrderById(requestIds).subscribe((result:any)=>{
       
       
          this.pendingRequests = result
          if(this.pendingRequests){
          acceptedIndex = this.pendingRequests.findIndex(x=>x.status=='ACCEPTED');
          if(acceptedIndex>-1){
            //pop message of success
          }}
      })
      this.pendingRequestTimer= setTimeout(()=>this.pendingRequestSubject.next(null),20000)
    }
    })
    this.userLoggedInSubject.subscribe(()=>{      
      if(this.userState.currentUser$.value!=null){
        this.navService.RemoveAllfromNav()
        this.partyService.getAllPendingRequestStatus().subscribe((response:any)=>{
          if(response!=null && response.length>0){
            this.pendingRequests=response
            this.pendingRequestSubject.next(null)
          }
        })
  
    }else{
      const signInNav:NavItem={
  title:'SIGN IN',
  event:()=>{this.isLogonRequired=true; this.phoneAuthStage=1},
  isIconOnly:false,
    icon:undefined  }
  this.navService.AddToNav(signInNav)
    }
    })
    this.userLoggedInSubject.next(null)
  }
  FindandCheckInPerformer(){
   var Id = String($("#performer-id").val())
   this.paramSearch=false
    if(Id.length>0  ){
      this.router.navigate([Id],{
        relativeTo:this.route,
        
      })
    }
  }
  private FindandCheckInPerformerInternal(performerId: string){
    this.partyService.GetPerformerById(performerId).subscribe((event:any)=>{
      if(event!=null){
        let stateEvent = this.stateService.GetItem(Constants.LIVEEVENT)
        if(stateEvent && stateEvent.eventId != event.id){
          this.stateService.RemoveItem(Constants.LIVEEVENT)
          this.router.navigate(['party'])
          return
        }
        this.performerId = performerId
    this.eventDetails = event
    
    this.isCustomSearch = this.eventDetails.catalogType!=0
    this.ResetBid()
    this.stateService.SetItem(Constants.LIVEEVENT,{eventId:this.eventDetails.id,
      performerId:this.performerId,
      eventEndDate:this.eventDetails.endDate,
      eventStartDate:this.eventDetails.startDate
    })
      }
     
    },
    (error)=>{
      this.stateService.RemoveItem(Constants.LIVEEVENT)
      this.router.navigate(['party'])
      throw error
    })
  }
  searchTextChanged(text: any){
    if(String(text.value).length<3){
return
    }
    window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => this.TriggerSearch(text.value), 500); 
  }
  TriggerSearch(value: string){
    if(value.length<3){
      return
    }
    this.isSearchingComplete=false
    if(this.eventDetails.catalogType==0){
    this.spotifyService.searchSpotify("/Search/Search/"+value).subscribe((ret)=>
    {
      this.selectedTrack=null
      this.trackList=ret;
      this.isSearchingComplete=true
    });
  }else{
    this.spotifyService.searchSpotify(`/Search/Custom/${value}?catalog=${this.eventDetails.customCatalogs}`).subscribe((ret)=>{
      this.selectedTrack=null
      this.trackList=ret;
      this.isSearchingComplete=true

    })
  }
  }
  onTrackSelected(track :any){
    this.selectedTrack = track
    this.trackList=null
    this.ResetBid() 
    
  }
  onBidChange(bid:any){
this.finalBid=bid.value
this.isPriorityBid=false
  }
  private ResetBid(){
    this.finalBid=this.eventDetails.minBid
  }
  onPriorityPressed(){
    this.isPriorityBid=true
    this.finalBid=this.eventDetails.priorityBid
  }
  onPayPressed(){
    if(!this.eventDetails.isFree && Number(this.finalBid)<this.eventDetails.minBid){
      this.priceError="Price cannot be lower than minimum price. try raising the amount."
      return
    }
    this.priceError=null
  this.isPayPressed= true
    if(this.userState.currentUser$.getValue()==null){
      this.isLogonRequired=true
      this.phoneAuthStage=1
    }
      else if(this.userState.userDetails.userStage==UserStateConstant.ONBOARDING){
        this.isLogonRequired=true
        this.phoneAuthStage=3
      }
    else{
      this.CreatePayObject();
    }
  }
  onOTPSent(verificationId :any){
    this.phoneAuthStage=2
    this.verificationId=verificationId
  }
  onPhoneNumberChange(phoneNumber :string){
    this.phoneNumber=phoneNumber
  }
  OtpProcessed(response :any){
    this.userLoggedInSubject.next(null)
    if(response.user.userStage == UserStateConstant.ONBOARDING){
      this.zone.run(()=>{
this.phoneAuthStage=3
      })

return;
    }
    else if(this.isPayPressed){
      this.isLogonRequired=false
      this.CreatePayObject()
    }else{
      this.isLogonRequired=false
    }
  }
  Togglebutton(isValid :boolean){

  }
  private CreatePayObject(){
    const currentUser= this.userState.currentUser$.getValue() 
var model : PayRequestModel = {
  bid: this.finalBid,
  isPriorityBid:this.isPriorityBid,
  track:{
    trackId:this.selectedTrack.trackId,
    artist:this.selectedTrack.artist,
    name:this.selectedTrack.name.replace  (/[^a-zA-Z0-9-\s\|\-]+/g,''),
    thumbnail:this.selectedTrack.artwork,
    spotifyUrl:this.selectedTrack.url,
    catalogType:this.eventDetails.catalogType
  },
  performerId:this.eventDetails.userId,
  eventId:this.eventDetails.id,
  authProvider:currentUser.authProvider,
  countryCode:'+91',
  fUId:currentUser.id,
  phoneNumber:currentUser.phoneNumber

}

this.partyService.CreatePayOrder(model)
this.stateService.SetSession(Constants.PERFORMER_ID,this.performerId)
this.isPayPressed=false
// .subscribe((response :any)=>{
  // const cf = new Cashfree(response.order_session_Id);
  // cf.redirect();
  // const ebCheckout = new EasebuzzCheckout('2PBP7IABZ2','test');
  // var options = {
  //   access_key:response.order_session_Id,
  //   onResponse:(response:any)=>{
  //       console.log(response)
  //   }
    
  // }
  // ebCheckout.initiatePayment(options);
  // window.open(response.order_session_Id,'_blank');
// })

  }
  GoogleLoggedIn(response:any){
    this.userLoggedInSubject.next(null)

    if(response.user.userStage == UserStateConstant.ONBOARDING){
      this.zone.run(()=>{
this.phoneAuthStage=3
      })

return;
    }
    else if(this.isPayPressed){
      this.isLogonRequired=false
      this.CreatePayObject()
    }else{
      this.isLogonRequired=false
    }
    
  }
  isUserOnboarded(response:boolean){
    if(response){
      this.userLoggedInSubject.next(null)
      this.isLogonRequired=false
      if(this.isPayPressed){
      this.isLogonRequired=false
      this.CreatePayObject()
      }
    }
  }
  LogOutUser(){
    if(this.phoneAuthStage==3){
      
    }
  }
  requestDetailsClicked(id:string){
    this.router.navigate(['Party','RequestDetails'],{queryParams:{requestId:id}})
  }
}
