import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';
import { AdminService } from '../_services/admin.service';
import { TableHeaderItem } from 'src/app/models/TableHeaderItem';

@Component({
  selector: 'app-all-events-list',
  templateUrl: './all-events-list.component.html',
  styleUrls: ['./all-events-list.component.css']
})
export class AllEventsListComponent implements OnInit{
  constructor(private adminService:AdminService){

  }
ngOnInit(): void {
 this.tableConfiguration = {
  headers:this.columns,
  lazyLoad:(event:any)=>this.loadData(event),
  id:'events',
  loading:false
 }
// this.adminService.getAllEvents().subscribe((events:any)=>{
//   this.events = events
// })
this.totalRecords=100
}
columns:TableHeaderItem[] = [
  {
    displayName:'Event Name',
    name:'Name',
    type:'text',
    isVisible:true
  },
  {
    displayName:'Venue',
    name:'Venue',
    type:'text',
    isVisible:true
  },
  {
    displayName:'Start Date',
    name:'StartDate',
    type:'date',
    isVisible:true
  }

]
events: any[];
tableConfiguration: TableConfigurationModel;
totalRecords: number;
 loadData(state:LazyLoadEvent) {

  var query = JSON.stringify(state);
  this.adminService.getAllEvents(query).subscribe((result:any)=>{
    this.events=result.data
    this.totalRecords = result.totalCount
  })
  
  }

}



