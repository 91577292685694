export const environment = {
  production: true,
  baseUrl:'https://stg.jumr.in/',
   api:'https://api-stg.jumr.in/api',
   wss:'https://api-stg.jumr.in/hub',
  firebaseConfig:{
   apiKey: "AIzaSyD0zK-Y9zIZge3QoBFkHOwerYCek3XMmQ8",
   authDomain: "jukr-dev.firebaseapp.com",
   projectId: "jukr-dev",
   storageBucket: "jukr-dev.appspot.com",
   messagingSenderId: "981764002751",
   appId: "1:981764002751:web:ffb8350c5ad4a3e3aa470a",
   measurementId: "G-M7EYS10D7G"
 },
 eb:{
  mId:'KEKH36BMCZ',
  mode:'prod'
},
auth:{
  google:{
    clientId:'865446333219-79l0kh3gdpp2v5prmr5r0cg1e11af8j3.apps.googleusercontent.com'
  }
},recaptcha: {
  siteKey: '6LfSopwoAAAAAGt4TWbGhugAPO-V92d5HJQzRC0y',
}
};
