import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';

import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/Services/auth.service';
import { EventService } from 'src/app/Services/event.service';
import { NavService } from 'src/app/Services/nav.service';
import { WebsocketService } from 'src/app/Services/websocket.service';
import { EventDetails } from 'src/app/models/eventDetailsModel';
import { NavItem } from 'src/app/models/navItem';
import { PayRequestModel } from 'src/app/models/payRequestModel';
import { SnackbarService } from 'src/app/snackbar.service';
import { QrCodeComponent } from '../../dashboard/qr-code/qr-code.component';


@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css'],
  providers:[DialogService]
})
export class ConsoleComponent implements OnInit,OnDestroy {
  startButtonState:any =0
RequestQueue :Array<any>=[]
FinalQueue:Array<any>=[]
protocol:any
  messageQueue$: any;
  ArchiveQueue$ :any
  isCustomTrack:boolean=false
  showArchiveRejected= false
  NowPlaying: any[]=[];
 // RequestQueue :BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
constructor(private route :ActivatedRoute,private router: Router, private eventService:EventService, private authService :AuthService,
  private zone:NgZone,private websocketService:WebsocketService
   , private snackbarService:SnackbarService, private navService:NavService, private dialogService:DialogService){
  this.route.queryParamMap.subscribe((params)=>{
    var eventId=params.get('eventId')
    if(eventId==null){
      this.router.navigate(['performer','dashboard'])
    }
    else{

        this.eventService.getEventById(String(eventId)).subscribe((event )=>{
          if(event!=null){
            this.eventDetails=new EventDetails(event)
            this.isCustomTrack = this.eventDetails.catalogType!=0
            //this.startStream()
          }
          else{
            this.router.navigate(['performer','dashboard'])
          }
          })
          
     
    }
  })
}


eventDetails:EventDetails
@HostListener('window:beforeunload')
  async ngOnDestroy() {
    this.stopStream()
  }
  ngOnInit(): void {
    var QrNav:NavItem={
      icon:'pi-qrcode',
      isIconOnly:true,
      title:'QR CODE',
      event:()=>{
       this.OpenQrDialog()
      }
    }
    this.navService.AddToNav(QrNav)
  }
  // opens the qr dialog
  OpenQrDialog(){
    this.dialogService.open(QrCodeComponent,{header:'Qr Code'})
  }
  //Push message to queue
  private updateQueueInternal(stream:any){
    this.zone.run(()=>{
      
        this.RequestQueue.push(stream)    
        
    })
  }
  private updateArchiveInternal(stream:any){
    this.zone.run(()=>{
      
        this.FinalQueue= stream 
        this.NowPlaying = this.FinalQueue.filter(x=>x.Status== "ACCEPTED" || x.Status== "COMPLETE")
        
    })
  }
  //parse stream and push to queue
  private updateQueue(stream:any){
    if(stream!=null){
      stream.forEach((element:any) => {
     this.updateQueueInternal(stream)
    });
    

    }
  }
  //start streaming messages
  private startStream(slt:string){

   var streamer= this.eventService.getRequestsbyEventStream(slt).subscribe((stream)=>{
   this.updateQueue(stream);
  },(error)=>{
    streamer.unsubscribe()
    this.stopStream()
  })
      
 

  }
//end stream pressed
  stopStream(){
    

   
//if it is in started state
    if( this.startButtonState==1){
      
      
      if( confirm("are you sure you want to stop listening requests?")){

      if(this.protocol=='socket'){
        this.websocketService.Close(this.eventDetails.id)
        this.startButtonState=0
        this.RequestQueue=[]
        this.messageQueue$.unsubscribe()
        this.ArchiveQueue$.unsubscribe()
      }
      else{
      this.zone.run(()=>{
        this.eventService.CloseEventListening()
        this.startButtonState=0
        this.RequestQueue=[]
        
        
      })
    }
  }
      return
    }
  

  }
  startStreaming(){
    if(this.startButtonState==1){
    this.stopStream()
    return
  }
    this.RequestQueue=[]
    if(this.eventDetails!=null){
      var model ={
        eventId:this.eventDetails.id,
      }
      this.eventService.RequestPermissionForStream(model).subscribe((response :any )=>{
        if(response!=null){
          this.protocol = response.protocol
          if(response.protocol=='socket'){
            this.StartSocket(response.token);
          }else{
this.startButtonState=1
          this.startStream(response.token);
        }
      }
      })
    }
  }
  trackRequest(index:number,request:any){
    return request.Id
  }
  AcceptRequest(request:any){
    if(request==null){
      throw 'Unable to accept. try again'
    }
    if(this.protocol=='socket'){
      this.websocketService.Action(request.Id,'Accept').then((response)=>{
        if(response){
          this.RequestQueue.splice(this.RequestQueue.indexOf(request),1)          
          this.snackbarService.CreateNotification('Request Accepted')
          this.websocketService.SyncArchive()  
        }
      })
    }else{
    this.eventService.AcceptRequest(request.Id).subscribe((response)=>{
      if(response){
        this.RequestQueue.splice(this.RequestQueue.indexOf(request),1)
        this.snackbarService.CreateNotification('Request Accepted')
      }
    })

  }
  }
  RejectRequest(request:any){
    if(request==null){
      throw 'Unable to reject. try again'
    }
    if(this.protocol=='socket'){
      this.websocketService.Action(request.Id,'Reject').then((response)=>{
        if(response){
          this.RequestQueue.splice(this.RequestQueue.indexOf(request),1)
          this.snackbarService.CreateNotification('Request Rejected')  
          this.websocketService.SyncArchive() 
        }
      })
    }else{
    this.eventService.RejectRequest(request.Id).subscribe((response)=>{
      if(response){
        this.RequestQueue.splice(this.RequestQueue.indexOf(request),1)
        this.snackbarService.CreateNotification('Request Rejected')
      }
    })
  }
  }
  StartSocket(token:string){
this.websocketService.connect(token).subscribe((connObj)=>{
  if(connObj.isConntected && connObj.id!=undefined){
    this.startButtonState=1
    this.eventService.InitRequests(token,connObj.id)
    this.messageQueue$ = connObj.messageQueue.subscribe((stream)=>{
      if(stream!=null){
        stream.forEach((element:any) => {
          this.updateQueueInternal(element);
        });
   
      }
    })
    this.ArchiveQueue$ = connObj.archiveQueue.subscribe((stream)=>{
      if(stream!=null){
       
          this.updateArchiveInternal(stream);
      
   
      }
    })
  }
  
},error=>{
  this.stopStream()
},()=>{
  this.stopStream()
})

  }
  shouldShowArchive(status:any){
    if(!this.showArchiveRejected){
    if(status == "ACCEPTED" || status =="COMPLETE"){
      return true;
    }
    return false;
  }
  else{
    return true;
  }
  }
  ArchiveTagLabel(status:any){
    if(status == "ACCEPTED" || status =="COMPLETE"){
      return 'ACCEPTED';
    }else{
      return 'REJECTED'
    }
  }
  ArchiveTagSeverity(status:any){
    if(status == "ACCEPTED" || status =="COMPLETE"){
      return 'success';
    }else{
      return 'danger'
    }
  }
}
