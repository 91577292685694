import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerRequestComponent } from './customer-request/customer-request.component';
import { SpotifyService } from './Services/spotify.service';
import { ProcessReturnComponent } from './process-return/process-return.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { LogonHomeComponent } from './auth/logon-home/logon-home.component';
import { ApiInterceptor } from './HttpInterceptor';
import { environment } from 'src/environments/environment';
import { EnterOtpComponent } from './performer/auth/enter-otp/enter-otp.component';
import { SignInComponent } from './performer/auth/sign-in/sign-in.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { RedirectResponseComponent } from './common/redirect-response/redirect-response.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalErrorHandler } from './GlobalErrorHandler';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DashboardComponent } from './performer/dashboard/dashboard.component';
import { BillingComponent } from './performer/dashboard/billing/billing.component';
import { BillingCommissionDetailsComponent } from './performer/dashboard/billing-commission-details/billing-commission-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingComponent } from './performer/onboarding/onboarding.component';
import { PlanComponent } from './performer/plan/plan.component';
import { CheckoutComponent } from './performer/plan/checkout/checkout.component';
import { SubscriptionReturnComponent } from './performer/subscription-return/subscription-return.component';
import { LoadingComponent } from './loading/loading.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { UserWidgetComponent } from './performer/dashboard/user-widget/user-widget.component';
import { TabDashboardComponent } from './performer/dashboard/tab-dashboard/tab-dashboard.component';
import { CreateEventComponent } from './performer/dashboard/create-event/create-event.component';
import { EventHistoryComponent } from './performer/dashboard/event-history/event-history.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCameraRotate, faClockRotateLeft, faHouse } from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-regular-svg-icons';
import { QrCodeComponent } from './performer/dashboard/qr-code/qr-code.component';
import { RequestComponent } from './Party/request/request.component';
import { SelectedTrackComponent } from './Party/selected-track/selected-track.component';
import { DialogModule } from 'primeng/dialog';
import { PhoneNumberComponent } from './_common/components/phone-number/phone-number.component';
import { OtpComponent } from './_common/components/otp/otp.component';
import { ConsoleComponent } from './performer/live/console/console.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HomeComponent } from './home/home.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {MatRippleModule} from '@angular/material/core';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { NO_ERRORS_SCHEMA } from '@angular/compiler';
import { TermsAndConditionsComponent } from './policies/terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './policies/about-us/about-us.component';
import { RefundPolicyComponent } from './policies/refund-policy/refund-policy.component';
import { FooterComponent } from './_common/components/footer/footer.component';
import { LoadPaymentComponent } from './Party/load-payment/load-payment.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EbProcessPaymentComponent } from './Party/eb-process-payment/eb-process-payment.component';
import { UserProfileComponent } from './_common/components/user-profile/user-profile.component';
import { SecondaryNavItemsComponent } from './_common/components/secondary-nav-items/secondary-nav-items.component';
import { TransactionHistoryComponent } from './performer/dashboard/transaction-history/transaction-history.component';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BasicCustomerDetailsComponent } from './_common/components/basic-customer-details/basic-customer-details.component';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { UserDashboardComponent } from './Party/user-dashboard/user-dashboard.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { TagModule } from 'primeng/tag';
import { CarouselModule } from 'primeng/carousel';
import { PayOrderDetailsComponent } from './Party/pay-order-details/pay-order-details.component';
import { UserTransactionsListComponent } from './User/user-transactions-list/user-transactions-list.component';
import { ConvertToPerformerComponent } from './convert-to-performer/convert-to-performer.component';
import { AdminDashboardComponent } from './Admin/dashboard/admin-dashboard.component';
import { UsersListComponent } from './Admin/users-list/users-list.component';
import { InputTextModule } from 'primeng/inputtext';
import { AuthStagePipe, JobStatusPipe } from './_common/pipe/shared.pipe';
import { NavlinkComponent } from './_common/components/navlink/navlink.component';
import { AdminJobsDashboardComponent } from './Admin/admin-jobs-dashboard/admin-jobs-dashboard.component';
import { DropdownModule } from 'primeng/dropdown';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { CameraScanComponent } from './Party/camera-scan/camera-scan.component';
import { BaseTableComponent } from './_common/components/base-table/base-table.component';
import { AllEventsListComponent } from './Admin/all-events-list/all-events-list.component';
import { SongCatalogComponent } from './performer/dashboard/song-catalog/song-catalog.component';
import { CreateCatalogComponent } from './performer/Catalogs/create-catalog/create-catalog.component';
import { ChipsModule } from 'primeng/chips';
import { AddSongsToCatalogComponent } from './performer/Catalogs/add-songs-to-catalog/add-songs-to-catalog.component';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { DataViewModule } from 'primeng/dataview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SidebarModule } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ToolbarModule } from 'primeng/toolbar';
import { InputOtpModule } from 'primeng/inputotp';
import { ViewEventComponent } from './performer/dashboard/event/view-event/view-event.component';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ScanRedirectionComponent } from './Party/scan-redirection/scan-redirection.component';
export function playerFactory() {
  return player;
} 
LOAD_WASM().subscribe();
@NgModule({
  declarations: [
    AppComponent,
    CustomerRequestComponent,
    ProcessReturnComponent,
    PaymentSuccessComponent,
    LogonHomeComponent,
    EnterOtpComponent,
    SignInComponent,
    RedirectResponseComponent,
    DashboardComponent,
    BillingComponent,
    BillingCommissionDetailsComponent,
    OnboardingComponent,
    PlanComponent,
    CheckoutComponent,
    SubscriptionReturnComponent,
    LoadingComponent,
    SnackbarComponent,
    UserWidgetComponent,
    TabDashboardComponent,
    CreateEventComponent,
    EventHistoryComponent,
    QrCodeComponent,
    RequestComponent,
    SelectedTrackComponent,
    PhoneNumberComponent,
    OtpComponent,
    ConsoleComponent,
    HomeComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    AboutUsComponent,
    RefundPolicyComponent,
    FooterComponent,
    LoadPaymentComponent,
    EbProcessPaymentComponent,
    UserProfileComponent,
    SecondaryNavItemsComponent,
    TransactionHistoryComponent,
    BasicCustomerDetailsComponent,
    UserDashboardComponent,
    PayOrderDetailsComponent,
    UserTransactionsListComponent,
    ConvertToPerformerComponent,
    AdminDashboardComponent,
    UsersListComponent,
    AuthStagePipe,
    NavlinkComponent,
    AdminJobsDashboardComponent,
    JobStatusPipe,
    CameraScanComponent,
    BaseTableComponent,
    AllEventsListComponent,
    SongCatalogComponent,
    CreateCatalogComponent,
    AddSongsToCatalogComponent,
    ViewEventComponent,
    ScanRedirectionComponent
    
  ],
  imports: [    
    MatSnackBarModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgOtpInputModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule, 
    LottieModule.forRoot({player: playerFactory}),
    BrowserAnimationsModule,
    TableModule,
    FontAwesomeModule,
    DialogModule,
    MatFormFieldModule,
    OverlayPanelModule,
    MatRippleModule
    ,CalendarModule
    ,SelectButtonModule,
    MatSlideToggleModule,
    ProgressSpinnerModule,
    AvatarModule,
    AvatarGroupModule,
    TooltipModule,
    DynamicDialogModule,
    FieldsetModule,
    RecaptchaV3Module,
    MatTabsModule,
    MatIconModule,
    TagModule,
    CarouselModule,
    InputTextModule,
    DropdownModule,
    NgxScannerQrcodeModule,
    ChipsModule,
    DividerModule,
    ScrollPanelModule,
    ButtonModule,
    FileUploadModule,
    DataViewModule,
    RadioButtonModule,
    MultiSelectModule,
    InputTextareaModule,
    SidebarModule,
    CheckboxModule,
    TabMenuModule,
    InputGroupModule,
    InputGroupAddonModule,
    ToolbarModule,
    InputOtpModule,
    PanelModule,
    ToggleButtonModule
         
  ],

  providers: [Title,{
    provide: HTTP_INTERCEPTORS,
    useClass:ApiInterceptor,
    multi:true
  },{provide: ErrorHandler,
    useClass:GlobalErrorHandler
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  SpotifyService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library : FaIconLibrary){
    library.addIcons(faHouse,faClockRotateLeft, faCameraRotate)
  }
 }
