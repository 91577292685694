import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';
import { Subscription, interval } from 'rxjs';
import { UserStateService } from '../../services/user-state.service';



@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit, OnDestroy {
@Input() phoneNumber:any
@Input() verificationId:any
@Output() OtpEntered =new EventEmitter<string>()
@Output() OtpValid =new EventEmitter<boolean>()
@Output() OtpProcessed =new EventEmitter()
otp:any
timerSubscription :Subscription
timer=120
constructor(private authService :AuthService, private userState:UserStateService){}
  ngOnInit(): void {
      this.startTimer(this.timer);
  }
  startTimer(timer: number) {
var minutes ;
var seconds;
 this.timerSubscription=interval(1000).subscribe(x=>{
  minutes=Math.floor(timer/60);
  seconds = Math.floor(timer%60);

  minutes=minutes<10? '0'+minutes:minutes;
  seconds=seconds<10? '0'+seconds:seconds;
  $("#timer").text(minutes+':'+seconds);
  --timer;
  if(timer<0){
this.timerSubscription.unsubscribe()
  }
})
  }
 
  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe()
  }
  // absolute?: boolean | undefined;
  // alpha?: number | null | undefined;
  // beta?: number | null | undefined;
  // gamma?: number | null | undefined;
  // bubbles?: boolean | undefined;
  // cancelable?: boolean | undefined;
  // composed?: boolean | undefined;
onOtpChange(otp :string){
  if(otp.length==6){
this.OtpValid.emit(true)
this.OtpEntered.emit(otp)
this.otp=otp
  }
  else{
    this.OtpValid.emit(false)
  }
    }
    verifyOtp(){
      this.authService.verifyOtp(this.otp, this.verificationId).subscribe((response: any) => {
          
        this.userState.saveUserState(response);
        this.OtpProcessed.emit(response)
      }
  
      ,(error) => {  });
  
    }

    ResendOtp(){
      
    }
    
}
