<div class="dashboard">
<div class="d-flex flex-row h-100">
    <div class="pr-3 w-100 flex-shrink-1 d-none d-md-flex" style="max-width: 250px;">
<div class="d-flex w-100 flex-column  h-100 db-nav">
    
        <ng-container *ngTemplateOutlet="navItems"></ng-container>
    
</div>
</div>
<div class=" w-100 d-block " style="max-width: 90rem;">

    <div class="d-flex me-2"> 
        <div class="d-flex">
        <i class="pi pi-bars white p-2 m-auto d-inline-block d-md-none" (click)="sideNavBarOpen= !sideNavBarOpen"></i> 
    </div>     
        <h4 class="my-auto ms-2">
{{selectedMenu}}
</h4>
<div *ngIf="selectedMenu=='Dashboard' || selectedMenu=='Manage Events'" class="ms-auto">
<button (click)="CreateEvent()" class="btn primary-btn d-inlineblock"><i style="font-size: 1rem; color: white; vertical-align: middle;" class="pi pi-plus my-auto"></i>
     <span class="d-lg-inline my-auto d-none mx-1" style="vertical-align: middle; align-items: center;">Create Event</span></button>
</div>
    </div>
    <div class="m-md-3 m-2 ">
    <app-create-event *ngIf="selectedMenu=='Create Event'" (CancelEvent)="OnCreateEventCanceled($event)" (SubmitEvent)="OnCreateEventSubmitCallback($event)" (CreateCatalogEvent)="selectedMenu='Song Catalog'" ></app-create-event>
    <app-tab-dashboard *ngIf="selectedMenu=='Dashboard'"></app-tab-dashboard>
    <app-billing *ngIf="selectedMenu=='Billing'"></app-billing>
  <app-event-history *ngIf="selectedMenu=='Manage Events'"></app-event-history>
  <app-qr-code *ngIf="selectedMenu=='My QR code'"></app-qr-code>
  <app-transaction-history *ngIf="selectedMenu=='Transaction History'"></app-transaction-history>
  <app-song-catalog *ngIf="selectedMenu == 'Song Catalog'"></app-song-catalog>
  <app-user-profile [isFromBashNav]="true" *ngIf="selectedMenu=='Profile'"></app-user-profile>
</div>
</div>
<!-- <div class="d-block" style="min-width: 250px;"></div> -->
</div>
</div>
<p-sidebar #sidebarRef [(visible)]="sideNavBarOpen" position="left">
    <ng-template pTemplate="headless">
      <span class="d-flex justify-content-end">
        <p-button type="button"  icon="pi pi-times" [text]="true" (onClick)="sideNavBarOpen=!sideNavBarOpen" [rounded]="true" styleClass="h-2rem w-2rem"></p-button>
    </span>
    <ng-container  *ngTemplateOutlet="navItems">

    </ng-container>
      </ng-template>
      </p-sidebar>
      <ng-template #navItems>
        <ul class="nav nav-pills flex-column py-3 px-2">
            <a class="d-flex mb-2" style="text-decoration: none; cursor: pointer;"  *ngFor="let item of menuItems" (click)="dashboardTabChanged($event); sideNavBarOpen=false"  id="{{item.name}}">
                <div class="w-100 p-1 d-flex nav-item" [ngClass]="{'nav-selected':selectedMenu==item.name}"  >
            <i class="my-auto nav-image "  [ngClass]="item.icon"></i>
            <span class="nav-link d-lg-inline-block "  href="javascript:void(0)">
                {{item.name}}
            </span>
        </div>
    </a>
    
        </ul>
        <ul class="nav nav-pills flex-column py-3 px-2 mt-auto">
            <a class="d-flex mb-2" style="text-decoration: none; cursor: pointer;"  *ngFor="let item of bottomMenuItems" (click)="dashboardTabChanged($event); sideNavBarOpen=false"  id="{{item.name}}">
                <div class="w-100 p-1 d-flex nav-item" [ngClass]="{'nav-selected':selectedMenu==item.name}"  >
            <i class="my-auto nav-image "   [ngClass]="item.icon"></i>
            <span class="nav-link d-lg-inline-block "  href="javascript:void(0)">
                {{item.name}}
            </span>
        </div>
    </a>
    
        </ul>
       

    </ng-template>