import { Component, Input, TemplateRef } from '@angular/core';
import { TableSelectAllChangeEvent } from 'primeng/table';
import { TableConfigurationModel } from 'src/app/models/TableConfigurationModel';

@Component({
  selector: 'app-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.css']
})
export class BaseTableComponent {
@Input() dataObj: any[];
@Input() selectionMode:'single'|'multiple'|undefined = undefined
 @Input() isCheckBoxEnabled:boolean = true
 @Input() tableHeaderTemplate:TemplateRef<any>|undefined = undefined
selectedItem:any

onSelectAllChange($event: TableSelectAllChangeEvent) {

}
@Input() tableConfiguration: TableConfigurationModel
@Input() totalRecords : number

constructor() {}

    ngOnInit() {
       
    }
    OnRowSelect(event:any){
      if(this.tableConfiguration.onRowSelect){
 this.tableConfiguration.onRowSelect(event)
      }
    }
    OnRowUnselect(event:any){
if(this.tableConfiguration.onRowDeselect){
  this.tableConfiguration.onRowDeselect(event)
}
    }
}
