<div style="max-width: 1150px; " 
class="d-flex mx-auto m-4">
<div class="m-4 w-100 rounded p-3" style=" background-color: var(--frame-color);">
<h4 *ngIf="!isFromBashNav">User Profile</h4>
<hr>
<!-- <p-tabMenu [model]="items"></p-tabMenu> -->
<div class="d-flex flex-row w-100 flex-wrap flex-lg-nowrap" [formGroup]="userProfileFG">
    <div class="order-lg-2 order-sm-1 w-100 align-items-center d-flex flex-column">
<div class="profile-picture-container mx-auto">
    <img id="profile-picture" style="width: inherit; height: inherit; border-radius: inherit; align-items: center; object-fit: cover;" src="{{imageUrl}}">
</div>
<a (click)="changeUserProfile()" class="fw-r mt-2" style="font-size: small; color: var(--secondary-color);">Change</a>
</div>
<div class="order-1 white mx-2 col-12 col-md-5" >
    <div class="mx-auto d-flex flex-column align-items-lg-start align-items-center">
    <div class="form-group mb-4 w-100">
        <p class="form-label">Display Name</p>
        <div class="w-100 justify-content-center">
           <input pInputText type="text" class="form-input" placeholder="" formControlName="name"  />
        </div>
    </div>
    <div class="form-group mb-4 w-100" >
        <p class="form-label">Email Address</p>
        <div class="w-100 justify-content-center">
           <input pInputText type="text" class="form-input" placeholder="" formControlName="emailId"  />
        </div>
    </div>
    <div class="form-group mb-4 w-100" >
        <p class="form-label">Phone Number</p>
        <!-- <div class="form-group gp-phone-number d-flex flex-row">
            <span class="country-code px-2 text-decorate-icon" id="country-code">+91</span>
            <input pInputText pKeyFilter="int" for="Phone" class=" py-0 px-2 text-decorate-input"  id="phone-number" maxlength="10"
                placeholder="eg. 9876543210" formControlName="phoneNumber"/>
    
        </div> -->
        <p-inputGroup [style]="{height: '34px'}">
            <p-inputGroupAddon>+91</p-inputGroupAddon>
            <input type="text" pKeyFilter="int" pInputText placeholder="eg. 9876543210" />
            
        </p-inputGroup>
        </div>
        <button matRipple class="btn primary-btn white" (click)="UpdateProfile()" type="submit" [disabled]="userProfileFG.invalid">Update</button>
</div>
</div>
</div>
</div>

</div>
<input type="file" id="upload-profile" capture style="visibility: hidden;" (change)="SelectProfilePicture($event)">