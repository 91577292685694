import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent {
  constructor(private activatedRoute:ActivatedRoute, private router:Router){}
navLinks=[
  // {location:'',label:'Home',icon:'pi pi-home',class:[]},
{location:'',label:'Request',icon:'bi bi-music-note-beamed',class:[]},
{location:'',label:'Transactions',icon:'pi pi-history',class:['user-nav-item-active']}]
activeTab = 'Transactions'

onUserTabClick(item:any){
 this.navLinks.forEach(element => {
  if(element==item){
    element.class.push('user-nav-item-active')
    this.activeTab=item.label
    this.activatedRoute.fragment
  }else{
    element.class=[]
  }
 });
 if(item.label == 'Request'){
  this.router.navigate(['party'])
 }

}
}

